import {CustomerState} from "../types";
import {Reducer} from "redux";
import * as Routines from "../routines";
import { SubUnitsActionTypes } from "../actionTypes";
import { createCustomerTree } from "@Safemate/SubUnits/utils";
import { getSuborgsWithRoles } from "@Safemate/Store/routines";
import uuid from "uuid";
import { defaultCustomer, defaultNewCustomer, ICustomerWithPolicy, IFullCustomerWithPolicy } from "@Safemate/Model/Customer";

const customerState: CustomerState = {
  selectedCustomer: {
    customerId: 0,
    firm: {
      custId: 0,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      status: 0,
      activatedDate: 0,
      prgrId: 0,
      invoiceCreditLimitExclVat: 0,
      invoiceBalanceExclVat: 0,
      allowAllPhoneNo: false,
      latestInvoiceDate: 0,
      orgNum: "",
      addrName: "",
      billingAddrName: "",
      addrReference: "",
      addressLine1: "",
      addressLine2: "",
      addrZipCode: "",
      addrCity: "",
      addrCounId: 0,
      country: "",
      parent: 0,
      defaultAhp: 0,
      isCompany: false,
      isPrivateUser: false,
      isPrioritySupport: false,
      forceTfa: false,
      forceCoverage: false,
      isBillingAccepted: false,
      individualBilling: false,
      integrationId: "",
      integrationRole: "",
      climaxConfig: false,
      allowDeviceDeletes: false,
      stock: false,
      whiteListPhoneNumbers: ""
    },
    numberOfDevices: 0,
    policies: {
      id: 0,
      custId: 0,
      roleName: "",
      global: false,
      policies: {},
      technicianRights: false
    },
    paymentProfile: {
      paprId: 0,
      siteIdOriginated: 0,
      custId: 0,
      paymentReference: "",
      paymentDetail: "",
      expiryDate: 0,
      expiryReminders: 0,
      startDate: 0,
      stopDate: 0,
      status: 0,
      pptyId: 0,
      invalidDate: 0,
      managedByCustId: 0,
      paymentProfileType: {
        pptyId: 0,
        name: "",
        impl: "",
        autoCharge: false,
        cpa: false,
        creditCard: false
      },
      currentSuspension: {
        ppsuId: 0,
        paprId: 0,
        start: 0,
        stop: 0,
        suspensionReminders: 0,
        comment: ""
      }
    },
    priceGroup: {
      prgrId: 0,
      siteIdOriginated: 0,
      name: "",
      defaultGroup: false,
      defaultCompanyGroup: false,
      invoiceCreditLimitExclVat: 0,
      couponCode: "",
      id: 0
    },
    zone: 0
  },
  customerTree: {},
  createForm: false,
  postCity: {
    postalCodeType: "",
    result: "",
    valid: false
  },
  billingPostCity: {
    postalCodeType: "",
    result: "",
    valid: false
  },
  customers: [],
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action: any) => {
  switch (action.type) {

    case Routines.alterTree.SUCCESS:
      return {
        ...state,
        customerTree: action.payload,
      }

    case Routines.saveCustomer.SUCCESS:
    case Routines.getCustomers.SUCCESS:
      return{
        ...state,
        customers: action.payload,
        customerTree: createCustomerTree(action.payload),
        createForm: false,
      }

    case Routines.selectCustomer.SUCCESS:
      const selectCustomersUpdate =  state.customers.filter(cust => !cust.unsaved);
      return {
        ...state,
        customers: selectCustomersUpdate,
        customerTree: createCustomerTree(selectCustomersUpdate),
        selectedCustomer: action.payload,
        createForm: false,
      };

    case Routines.getPostCity.SUCCESS:
      return {
        ...state,
        postCity: action.payload
      };

    case Routines.getBillingPostCity.SUCCESS:
      return {
        ...state,
        billingPostCity: action.payload
      };

    case Routines.cancelSettings.TRIGGER:
      return{
        ...state,
        customers: action.payload
      }
    
    case SubUnitsActionTypes.CREATE_CUSTOMER:

      const newCustomer: IFullCustomerWithPolicy = {
        customerId: 0,
        firm: {
          ...defaultNewCustomer,
          parent: state.selectedCustomer.firm.custId
        },
        numberOfDevices: 0,
        paymentProfile: {
          paprId: 0,
          siteIdOriginated: 0,
          custId: 0,
          paymentReference: "",
          paymentDetail: "",
          expiryDate: 0,
          expiryReminders: 0,
          startDate: 0,
          stopDate: 0,
          status: 0,
          pptyId: 3,
          invalidDate: 0,
          managedByCustId: 0,
          paymentProfileType: undefined,
          currentSuspension: undefined
        },
        policies: state.selectedCustomer.policies,
        zone: state.selectedCustomer.zone + 1,
        unsaved: true
      }

      const customerList = [...state.customers];
      customerList.push(newCustomer);

      return{
        ...state,
        selectedCustomer: newCustomer,
        customers: customerList,
        customerTree: createCustomerTree(customerList),
        createForm: action.payload
      }
    

    default:
        return state;
  }
  
};

export default customer;
