import {SensorState} from "../types";
import {Reducer} from "redux";
import { editSensorFunction, editSensorType, editSensorName, startLocalLearning, stopLocalLearning, addLocalLearning, addScannedSensor, addSensor, deleteSensor, fetchSensors, saveSensor, getFunctionsForType, saveEventConfig, startWalktest, stopWalktest, fetchWalktest, getTransactionsForSensor } from "../routines";
import { SettingsActionTypes } from "../actionTypes";
import { ISensorDTO } from "@Safemate/Settings/Sensor/types";

const sensorState: SensorState = {
    sensors: [],
    unsavedSensors: [],
    sensorFunctions: [],
    fetchingFunctions: false,
    updatingFunction: [],
    updatingType: [],
    updatingName: [],
    learningRemaining: 0,
    learningMode: false,
    walktestRemaining: 0,
    walktestRunning: false,
    startingLearningMode: false,
    endingLearningMode: false,
    startingWalktest: false,
    endingWalktest: false,
    loadingWalktest: false,
    savingSensor: false,
    savedSensorId: 0,
    walktest: [],
    addedLocalLearning: [],
    sensorTransactions: {},
    hoverRow: 0
};

const sensor: Reducer<SensorState> = (state: SensorState = sensorState, action: any) => {
  switch (action.type) {

    case SettingsActionTypes.CLEANUP:
      return {
        ...sensorState
      }


    case addScannedSensor.TRIGGER:
    case addSensor.TRIGGER:
      return {
        ...state,
        savingSensor: true
      }

    case deleteSensor.SUCCESS:
    case addScannedSensor.SUCCESS:
    case addSensor.SUCCESS:
    case fetchSensors.SUCCESS:
      const savedSensors = action.payload.filter((sensor: ISensorDTO) => sensor.sensor.saved);
      const unsavedSensors = action.payload.filter((sensor: ISensorDTO) => !sensor.sensor.saved);
      return {
          ...state,
          sensors: savedSensors,
          unsavedSensors,
      };

    case addScannedSensor.FULFILL:
    case addSensor.FULFILL:
      return {
        ...state,
        savingSensor: false
      }

    case editSensorFunction.SUCCESS:
    case editSensorType.SUCCESS:
    case editSensorName.SUCCESS:
      return{
        ...state,
        sensors: state.sensors.map(sensor => {
          if(sensor.sensor.deseId === action.payload.sensor.deseId){
            return action.payload;
          }
          return sensor;
        })
      }

    case deleteSensor.SUCCESS:
    case saveSensor.SUCCESS:
      return {
        ...state,
        sensors: action.payload
      }

    case saveEventConfig.SUCCESS:
      return {
        ...state,
        savedSensorId: action.payload.sensor.deseId,
        sensors: state.sensors.map(sensor => {
          if(sensor.sensor.deseId === action.payload.sensor.deseId){
            return action.payload;
          }
          else{
            return sensor;
          }
        })
      }

    case getFunctionsForType.TRIGGER:{
      return {
        ...state,
        fetchingFunctions: true
      }
    }
  
    case getFunctionsForType.SUCCESS:
      return {
        ...state,
        sensorFunctions: action.payload
      }

    case getFunctionsForType.FULFILL:
      return {
        ...state,
        fetchingFunctions: false
      }

    case editSensorName.TRIGGER:
      return {
        ...state,
        updatingName: [
          ...state.updatingName,
          action.payload.sensorId
        ]
      }

    case editSensorName.FULFILL:
      return {
        ...state,
        updatingName: state.updatingName.filter(id => {
          return id !== action.payload
        })
      }

    case editSensorType.TRIGGER:
      return {
        ...state,
        updatingType: [
          ...state.updatingType,
          action.payload.sensorId
        ]
      }

    case editSensorType.FULFILL:
      return {
        ...state,
        updatingType: state.updatingType.filter(id => {
          return id !== action.payload
        })
      }

    case editSensorFunction.TRIGGER:
      return {
        ...state,
        updatingFunction: [
          ...state.updatingFunction,
          action.payload.sensorId
        ]
      }

    case editSensorFunction.FULFILL:
      return {
        ...state,
        updatingFunction: state.updatingFunction.filter(id => {
          return id !== action.payload
        })
      }

    case getTransactionsForSensor.SUCCESS:

      const { sensorId, transactions } = action.payload;

      return {
        ...state,
        sensorTransactions: {
          [sensorId]: transactions
        }
      }

    case startLocalLearning.TRIGGER:
      return{
        ...state,
        startingLearningMode: true
      }

    case startLocalLearning.SUCCESS:
      return{
        ...state,
        learningRemaining: action.payload,
        learningMode: true,
        startingLearningMode: false
      }

    case startLocalLearning.FAILURE:
      return{
        ...state,
        learningMode: false,
        startingLearningMode: false
      }

    case stopLocalLearning.TRIGGER:
      return{
        ...state,
        endingLearningMode: true
      }

    case stopLocalLearning.SUCCESS:
      return{
        ...state,
        learningMode: false,
        endingLearningMode: false
      }

    case stopLocalLearning.FAILURE:
      return{
        ...state,
        endingLearningMode: false
      }

    case addLocalLearning.TRIGGER:
      return{
        ...state
      }

    
    case addLocalLearning.SUCCESS:

      const addedLocalLearningSensors = [...state.addedLocalLearning];

      if(action.payload){
        addedLocalLearningSensors.push(action.payload);
      }

      return{
        ...state,
        addedLocalLearning: addedLocalLearningSensors
      }

    case addLocalLearning.FULFILL:
      return{
        ...state
      }

    case startWalktest.TRIGGER:
      return{
        ...state,
        startingWalktest: true
      }

    case startWalktest.SUCCESS:
      return{
        ...state,
        walktestRemaining: action.payload,
        walktestRunning: true,
        startingWalktest: false
      }

    case startWalktest.FAILURE:
      return{
        ...state,
        walktestRunning: false,
        startingWalktest: false
      }

    case stopWalktest.TRIGGER:
      return{
        ...state,
        endingWalktest: true
      }

    case stopWalktest.SUCCESS:
      return{
        ...state,
        walktestRunning: false,
        endingWalktest: false
      }

    case stopWalktest.FAILURE:
      return{
        ...state,
        endingWalktest: false
      }

    case fetchWalktest.TRIGGER:
      return {
        ...state,
        loadingWalktest: true
      }

    case fetchWalktest.SUCCESS:
      return {
        ...state,
        walktest: action.payload
      }

    case fetchWalktest.FULFILL:
      return {
        ...state,
        loadingWalktest: false
      }

    case SettingsActionTypes.UPDATE_WALKTEST:

      const walktest = state.walktest;
      let isNew = true
      const mappedWalktest = walktest.map(w => {
        if(w.gatewayId === action.payload.gatewayId){
          isNew = false;
          return {
            ...w,
            ...action.payload
          }
        }
        else{
          return w;
        }
      })

      if(isNew){
        mappedWalktest.push(action.payload);
      }

      return{
        ...state,
        walktest: mappedWalktest
      }

    case SettingsActionTypes.SET_HOVER_ROW:
      return{
        ...state,
        hoverRow: action.payload
      }

    default:
      return state;
  }
};

export default sensor;