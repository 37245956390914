import {FilterState} from "../types";
import {Reducer} from "redux";
import {DeviceListActionTypes} from "../actionTypes";
import { searchSensors, clearFilters } from '../Routines';
import { saveFilters, loadFilters } from '../utils';
import { OrderFilterEnum } from "../../OrderFilterEnum";
import { AlarmFilterEnum } from "@Safemate/DeviceList/AlarmFilterEnum";


const filterState: FilterState = {
  deviceTypes: [],
  customers: [],
  alarmFilters: [],
  matchedGX8: [],
  search: "",
  maintainFilters: false,
  orderFilter: OrderFilterEnum.DEFAULT,
  userTags: []
};

const filter: Reducer<FilterState> = (state = {...filterState, ...loadFilters()}, action) => {
  switch (action.type) {
    case DeviceListActionTypes.FILTER_DEVICE_TYPE:
      const deviceTypeState = {
        ...state,
        deviceTypes: action.payload
      }
      save(deviceTypeState);
      return deviceTypeState
    case DeviceListActionTypes.FILTER_CUSTOMER:
      const customerState = {
        ...state,
        customers: action.payload
      }
      save(customerState);
      return customerState
    case DeviceListActionTypes.FILTER_ALARM:
      const alarmState = {
        ...state,
        alarmFilters: action.payload
      }
      save(alarmState);
      return alarmState
    case DeviceListActionTypes.FILTER_ORDER:
        const orderState = {
          ...state,
          orderFilter: action.payload
        }
      save(orderState);
      return orderState

    case DeviceListActionTypes.FILTER_USER_TAGS:
        const userTagsState = {
          ...state,
          userTags: action.payload
        }
      save(userTagsState);
      return userTagsState;
    case DeviceListActionTypes.FILTER_SEARCH:
      return{
        ...state,
        search: action.payload
      }

    case DeviceListActionTypes.TOGGLE_MAINTAIN_FILTER:
      return{
        ...state,
        maintainFilters: action.payload
      }
    
    case clearFilters.SUCCESS:
      const filterState = {
        ...state,
        deviceTypes: [] as number[],
        customers: [] as number[],
        alarmFilters: [] as AlarmFilterEnum[],
        matchedGX8: [] as string[],
      }
      return filterState;
    case searchSensors.SUCCESS:
      return{
        ...state,
        matchedGX8: action.payload
      }

    default:
      return state;
  }
}; 
const save = (state: FilterState) => {
  saveFilters({
    deviceTypes: state.deviceTypes,
    customers: state.customers,
    alarmFilters: state.alarmFilters,
    orderFilter: state.orderFilter,
    userTags: state.userTags
  })
}

export default filter;
