import * as React from "react";
import { connect } from "react-redux";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { LoaderWrap } from "@Safemate/Spinner";
import Loader from "@Elements/Loader";
import { ISensorDTO } from "@Safemate/Settings/Sensor/types";
import { AppState } from "@Safemate/Store/types";


const mapVoiceSatelliteStateToProps = ({ settings: {sensor: { sensors }, initialization: { deviId }}}: AppState) => {
  return{
    sensors,
    deviId
  }
}

interface VoiceSatelliteProps{
  sensors: ISensorDTO[];
  deviId: number;
}

export const VoiceSatellite = connect(mapVoiceSatelliteStateToProps)(({sensors, deviId}: VoiceSatelliteProps) => {

  const options = [
    {
      text: 'GX-8',
      value: null
    }
  ].concat(
    sensors
      .filter(
        sensor =>
        sensor.type && sensor.type.supportsDect
      )
      .map(sensor => ({
        text: `${sensor.sensor.name}(${sensor.sensor.gatewayId})`,
        value: sensor.sensor.deseId
      }))
  )

  const Drop = DropdownWrap(
    "voiceSatellite", 
    {
      title: {id: "dectDeviceLabel", defaultMessage: "Lydenhet"},
      dataOptions: {
        options: options,
        skipMap: true
      },
      prefix: "eventConfig",
      width: "250px"
    }
  );
  if(!deviId){
    <LoaderWrap><Loader/></LoaderWrap>
  }
  return <Drop/>
})
