import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import { useParams } from "react-router";
import {CallLog, EventLog} from "@Safemate/Model/EventLog";
import { AppState } from "@Safemate/Store/types";
import { LogParams } from "..";
import { getDevice, getDeviceAuditLog, getDeviceCallLog } from "../Store/routines";
import { Button, Grid, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { formatDate } from "@Safemate/utils";
import styled from "styled-components";
import { Option } from "@Elements/Dropdown/types";
import { Checkbox } from "@Elements/CheckboxFormik";
import { SetFilter, setFilter, ResetState, resetState } from "../Store/actions";
import { HeaderRow } from "../styles";

const UppercaseTd = styled.td`
  text-transform: uppercase;
`;

const PadTopDiv = styled.div`
  padding-top: 1em;
`;

interface DeviceAuditLogProps{
  filteredDeviceAuditLog: EventLog[];
  deviceAuditLog: EventLog[];
  getDevice: Routine<ActionFunctionAny<Action<any>>>;
  getDeviceAuditLog: Routine<ActionFunctionAny<Action<any>>>;
  filter: number[];
  setFilter: SetFilter;
  resetState: ResetState;
}

const mapStateToProps = ({ log: { initialization: { filter }, deviceAuditLog: { filteredDeviceAuditLog, deviceAuditLog } } }: AppState) => {
  return{
    filteredDeviceAuditLog  ,
    deviceAuditLog,
    filter
  }
}

const mapDispatchToProps = {
  getDevice,
  getDeviceAuditLog,
  setFilter,
  resetState
}

export const DeviceAuditLog = connect(mapStateToProps, mapDispatchToProps)(({filteredDeviceAuditLog, deviceAuditLog, getDevice, getDeviceAuditLog, resetState, setFilter, filter}: DeviceAuditLogProps) => {

  const { id } = useParams<LogParams>();
  const deviceId = parseInt(id);
  const [ evenId, setEvenId ] = React.useState<number>(0);
  const [ limit, setLimit ] = React.useState<number>(9);

  const { formatMessage } = useIntl();
  
  React.useEffect(() => {
    //getDevice(deviceId);
    return () => {
      resetState();
    }
  }, [])

  React.useEffect(() => {
    getDeviceAuditLog({id: deviceId, limit: 9, evenId})
  }, [evenId])

  const typeFilter = React.useMemo(() => {
    const filterList: number[] = [];
    const mappedFilterList: Option[] = [];
    mappedFilterList.push({
      text: "all",
      value: 0
    })
    deviceAuditLog.forEach(event => {
      if(!filterList.includes(event.evtyId)){
        filterList.push(event.evtyId);
        mappedFilterList.push({
          text: event.eventType.name,
          value: event.evtyId
        })
      }
    })
    return mappedFilterList;
  }, [deviceAuditLog])

  return(
    <Grid className="users deviceAuditLog" fluid>
      <HeaderRow>
        <Col md={12}>
          {typeFilter.map(item => {
            return(
              <Checkbox
                outerDiv={{marginRight: "7.5px"}}
                label={formatMessage({id: `et.${item.text}`, defaultMessage: item.text})}
                id={item.value}
                onChange={(e: React.BaseSyntheticEvent) => {
                  const value = parseInt(e.target.value);
                  if(!value){
                    setFilter([]);
                  }
                  else if(filter.includes(value)){
                    setFilter(filter.filter(item => item !== value));
                  }
                  else{
                    setFilter([...filter, value]);
                  }
                }}
                value={filter.includes(item.value) || (item.value === 0 && filter.length === 0)}

              />
            )
          })}
        </Col>
      </HeaderRow>
      <Row>
        <Col md={12}>
          <table className="customeOne-table table">
            <thead>
              <tr>
                <UppercaseTd>{formatMessage({id: "timestamp", defaultMessage: "Tid"})}</UppercaseTd>
                <UppercaseTd>{formatMessage({id: "action", defaultMessage: "Handling"})}</UppercaseTd>
                <UppercaseTd>{formatMessage({id: "user", defaultMessage: "Bruker"})}</UppercaseTd>
              </tr>
            </thead>
            <tbody>
              {filteredDeviceAuditLog.map(entry => {
                return(
                  <tr>
                    <td>{formatDate(entry.createdTime, true, false, false, false, false, formatMessage)}</td>
                    <td>
                      <span>{formatMessage({id: `et.${entry.eventType.name}`})}</span>
                      {entry.header && <span>{` - ${entry.header}`}</span>}
                      <br/>
                      {entry.description && <pre style={{whiteSpace: "normal"}}>{entry.description}</pre>}
                      {entry.remoteUser && <span>{entry.remoteUser}</span>}
                    </td>
                    <td>{entry.remoteUser ? entry.remoteUser : entry.createdByUser ? (entry.createdByType == 1 ? formatMessage({id: "customerService_plhold", defaultMessage: "Customer service"}) : entry.createdByUser.name) : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row style={{textAlign: "center"}}>
        <Button disabled={limit > deviceAuditLog.length} onClick={() => {
          setEvenId(deviceAuditLog[(deviceAuditLog.length-1)].evenId);
          setLimit( limit + 9);
        }}>
          {formatMessage({id: "loadMore", defaultMessage: "Hent mer"})}
        </Button>
      </Row>
    </Grid>
  )
})


interface DeviceCallLogProps{
  deviceCallLogs: CallLog[];
  getDeviceCallLog: Routine<ActionFunctionAny<Action<any>>>;
}

const callLogStateToProps = ({ log: { deviceAuditLog: { deviceCallLogs } } }: AppState) => {
  return{
    deviceCallLogs
  }
}

const callLogDispatchToProps = {
  getDeviceCallLog
}


export const DeviceCallLog = connect(callLogStateToProps, callLogDispatchToProps)(({ getDeviceCallLog, deviceCallLogs }: DeviceCallLogProps) => {

  const { id } = useParams<LogParams>();
  const deviceId = parseInt(id);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    getDeviceCallLog({id: deviceId, limit: 100});
  }, [])

  return(
    <Grid className="users deviceAuditLog" fluid>
      <div>
        {formatMessage({id: "directCallToDeviceMessage", defaultMessage: "Note: Direct call to device logs will not be captured!"})}
      </div>
      { deviceCallLogs.length < 1  ? <PadTopDiv>{formatMessage({id: "noCallLogsFound", defaultMessage: "No call logs found!"})}</PadTopDiv>
        : <Row>
        <Col md={12}>
          <table className="customeOne-table table">
            <thead>
            <tr>
              <UppercaseTd>{formatMessage({id: "timestamp", defaultMessage: "Tid"})}</UppercaseTd>
              <UppercaseTd>{formatMessage({id: "destination", defaultMessage: "Destination"})}</UppercaseTd>
              <UppercaseTd>{formatMessage({id: "duration", defaultMessage: "Duration"})}</UppercaseTd>
              <UppercaseTd>{formatMessage({id: "inboundOutbound", defaultMessage: "Inbound / Outbound"})}</UppercaseTd>
            </tr>
            </thead>
            <tbody>
            {deviceCallLogs.map(entry => {
              return(
                <tr key={entry.time}>
                  <td>{formatDate(entry.time, true, false, false, false, false, formatMessage)}</td>
                  <td>{ entry.destination }</td>
                  <td>{entry.duration} {formatMessage({id: "second", defaultMessage: "Seconds"})}</td>
                  <td>{entry.incoming ? formatMessage({id: "inbound", defaultMessage: "Inbound"}) : formatMessage({id: "outbound", defaultMessage: "Outbound"}) }</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </Col>
      </Row>}

    </Grid>
  )
})
