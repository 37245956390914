import * as React from "react";
import { useIntl } from "react-intl";
import { forgotPassword } from "../Store/routines";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { Button } from "react-bootstrap";

interface ForgotPasswordProps{
  forgotPassword: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  forgotPassword
}

const ForgotPassword = connect(null, mapDispatchToProps)(({forgotPassword}: ForgotPasswordProps) => {

  const { formatMessage}  = useIntl();

  const [ username, setUsername ] = React.useState("");

  const keyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      doForgotPassword();
    }
  }

  const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  }

  const doForgotPassword = () => {
    forgotPassword({username})
  }

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "forgotDesc", defaultMessage: "Glemt passord"})}</h4>
      <input value={username} style={{textTransform: "lowercase"}} className="focus" type="email"
        placeholder={formatMessage({id: "username", defaultMessage: "Username"})}
        onKeyUp={keyUpEvent}
        onChange={changeUsername}
      />
      <Button disabled={!username} onClick={doForgotPassword} type="button" className="btn btn-default">
        {formatMessage({id: "sendNewPasswordPro", defaultMessage: "Send nytt passord"})}
      </Button>
    </React.Fragment>
  )
})

export default ForgotPassword;
