import * as React from "react";
import {useIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import {GeofenceSettings} from "@Safemate/DefaultSettings/Settings/types";
import { GeofencesIndexProps } from "..";
import { ComponentWrapper, U } from "../styles";


const TimeToggle = withFormWrapper<GeofenceSettings, GeofencesIndexProps>(({ formik: { setFieldValue, values: { geofences } }, geofencesIndex, disabled }) => {
 
  const { formatMessage } = useIntl();

  if(geofences[geofencesIndex].polygon === undefined)
    return null;

  return(
    <ComponentWrapper>
      <U
        onClick={() => {
          setFieldValue(`geofences.${geofencesIndex}.timeToggled`, !geofences[geofencesIndex].timeToggled);
        }}
      >
        {
          !geofences[geofencesIndex].timeToggled
            ? formatMessage({id: "showTimeControls", defaultMessage: "Vis tidsstyring"})
            : formatMessage({id: "hideTimeControls", defaultMessage: "Skjul tidsstyring"})
        }
      </U>
    </ComponentWrapper>
  )
})

export default TimeToggle;
