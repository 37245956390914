import * as React from "react";
import {useIntl} from "react-intl";
import {Col, Row} from "react-bootstrap";
import {connect as formikConnect} from "formik";
import {connect as reduxConnect} from "react-redux";
import {Collapse} from "react-collapse";

import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {InnerFrame, SubHeaderWrapper, SwitchHeader} from "./styles";
import Switch from "../../../../Elements/Switch";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {DropdownField} from "./FormFields";
import {NoFlexContent} from "@Safemate/RoleEditor/WrapperStyles";
import {LabelEnum} from "../../LabelEnum";
import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import {Role} from "../../Models/Role";
import {mapCustomersForUser} from "../../utils";
import DateSelector from "./DateSelector";
import TreeDropdown from "@Elements/Dropdown/Tree";
import { TreeDropdownOption } from "@Elements/Dropdown/types";
import { AppState } from "@Safemate/Store/types";
import { Moment } from "moment";
import { CustomerWithChildren, ICustomer, ICustomerWithPolicy } from "@Safemate/Model/Customer";

interface TemporaryRoleProps{
  customerHierarchy: CustomerWithChildren | null;
  roles: Array<Option>;
  admin: Role;
  isNew: boolean;
  customers: ICustomerWithPolicy[];
  superfirm: ICustomer;
}

const mapStateToProps = ({ appData: { customersWithPolicy, customerHierarchy, superfirm}, users: {action: {edit: {isNew}}, role: {admin}}}: AppState) => {
  return {
    customerHierarchy,
    admin,
    isNew,
    superfirm,
    customers: customersWithPolicy
  }
}

const TemporaryRole = reduxConnect(mapStateToProps)(
    formikConnect<TemporaryRoleProps, UserWithCustomer>(
      ({superfirm, admin, roles, customers, customerHierarchy, isNew, formik: {setFieldValue, values: {from, to, temporary, temporarySubOrgs, temporaryRole, user: { userId }}}}) => {

        const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
        const isAllowed = permissions.has(PolicyNameEnum.PROMOTE_TEMP) && permissions.get(PolicyNameEnum.PROMOTE_TEMP).generalPermissions.all
        const {formatMessage} = useIntl();
        
        React.useEffect(() => {
          if (customerHierarchy) {
            setMappedCustomers(mapCustomersForUser(temporarySubOrgs.map(t => t.custId), customerHierarchy, permissions.get(PolicyNameEnum.PROMOTE_TEMP).customerPermissions,  ["all"]));
          }
        }, [customerHierarchy, userId])

        const [mappedCustomers, setMappedCustomers] = React.useState([] as Array<TreeDropdownOption>);

        if(!isAllowed || isNew) return null;


        return(
          <InnerFrame border>
            <SwitchHeader>
              {formatMessage({id: LabelEnum.TEMPORARY_ROLE, defaultMessage: "Midlertidig Rolle"})}
            </SwitchHeader>
            <Switch
              checked={temporary}
              onChange={(value: boolean) => {
                setFieldValue("temporary", value)
                if (value == false) {
                  // Collapsing the temporary role menu should clear any selected temporary role
                  setFieldValue("temporaryRole", 0)
                }
              }}
            />
              <Collapse  isOpened={temporary}>
                <Row>
                  <Col md={6}>
                    <DropdownField
                      header={{id: LabelEnum.ROLE ,defaultMessage:"Role"}}
                      field={["temporaryRole"]}
                      data={roles}
                      onChange={({value}: Option) => {
                        // Set the suborg field to the admin firm if admin is selected
                        if(value === admin.id)
                          setFieldValue("temporarySubOrgs", [{custId: superfirm.custId, firmName: superfirm.name, defaultAhp: superfirm.defaultAhp}])
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <NoFlexContent>
                      <SubHeaderWrapper>
                        {formatMessage({id: LabelEnum.SUB_UNITS, defaultMessage: "subUnits"})}
                      </SubHeaderWrapper>
                      {temporaryRole === admin.id
                        ? <div style={{padding: "10px"}}>{superfirm.name}</div>
                        : <TreeDropdown
                            multiSelect
                            onChange={(values) => {
                              const selectedSuborgs = customers.map(cust => {
                                if(values.includes(cust.customerId.toString())){
                                  return {
                                    custId: cust.customerId,
                                    firmName: cust.customerName,
                                    defaultAhp: cust.defaultAhp
                                  }
                                }
                              }).filter(c => c);
                              setFieldValue("temporarySubOrgs", selectedSuborgs);
                            }}
                            options={mappedCustomers}
                            title={formatMessage({id: LabelEnum.SELECT_CUSTOMER, defaultMessage: "Velg underavdeling"})}
                          />
                      }
                    </NoFlexContent>
                  </Col>
                </Row>
                <DateSelector
                  disable={!temporary}
                  values={{
                    to: to as Moment,
                    from: from as Moment,
                    toField: "to",
                    fromField: "from"
                  }}
                />
              </Collapse >
          </InnerFrame>
        )
      }
    )
  )

export default TemporaryRole;
