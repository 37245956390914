import _axios, {AxiosInstance} from 'axios';
import { OrgValues } from '../Organisation';
import { Geofence, GeofenceDistance } from '../Geofence/types';
import { GeofenceValues } from '../Geofence';
import {
  UpdateAutoTracking,
  StartTracking,
  UpdatePowerSaving,
  SaveTracking,
  UpdateDevicePositioning,
  UpdateAcceptLBS
} from './Saga/tracking';
import {
  SwitchSafemate,
  SaveAlarmSettings,
  SaveBattery,
  SaveGeneralSettings,
  SavePositioning,
  SaveManDownSettings,
  HeartRateBloodPresureSettings,
  SaveCommunication,
  SaveInactivity,
  Simulate
} from './Saga/general';
import { SaveImage, SendMessage, UpdateTraining } from './Saga/info';
import { SaveSoundLight } from './Saga/soundLight';
import { Initialize } from './Saga/initialization';
import { DeviceNote } from '../types';
import { GlobalSettings } from './Saga/geofence';
import {DeviceMac} from "../Indoor/types";
import { SaveSensor, ConnectBaseStation, AddSensor, EditSensorName, EditSensorFunction, AddLocalLearning, EditSensorType, SaveEventConfig } from './Saga/sensor';
import { ISensor } from '../Sensor/types';
import { EventConfigForm } from '../EventConfig/types';
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import { addRolesToRequest } from '@Safemate/utils';
import { SaveOrganisation } from './Saga/organisation';
import { UserTags } from '@Safemate/enums/UserTags';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getDevice = async ({deviceId}: Initialize) => {
  const url: string = `/deviceSettings/${deviceId}${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;  
};

export const getCapabilities = async ({deviceId}: Initialize) => {
  const url = `/capabilities/device/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getSupportDeviceMac = async () => {
  const url = `/indoorlocation/supportdevicemac`;
  const response = await axios.get(url);
  return response.data;
}

export const getUser= async () => {
  const url: string = `/user/me?_=${new Date().getMilliseconds()}`;
  const response = await axios.get(url);
  return response.data;
}

export const syncDevice = async (deviId: number) => {
  const url: string = `/deviceSettings/${deviId}/sync`;
  const response = await axios.get(url);
  return response.data;
} 
export const smsReboot = async (deviId: number) => {
  const url: string = `/deviceSettings/${deviId}/smsreboot`;
  const response = await axios.get(url);
  return response.data;
} 
export const smsReset = async (deviId: number) => {
  const url: string = `/deviceSettings/${deviId}/smsreset`;
  const response = await axios.get(url);
  return response.data;
} 
export const factoryReset = async (deviId: number) => {
  const url: string = `/deviceSettings/${deviId}/factoryreset`;
  const response = await axios.get(url);
  return response.data;
} 

//Sound and light
export const getSoundLight = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/soundLight`;
  const response = await axios.get(url);
  return response.data;  
};
export const saveSoundLight = async ({deviceId, settings}: SaveSoundLight) => {
  const url: string = `/deviceSettings/${deviceId}/soundLight`;
  const response = await axios.post(url, settings);
  return response.data;
}

//Sensor
export const getSensor = async (deviId: number) => {
  let url: string = `/deviceSensor/${deviId}/sensors`;
  const response = await axios.get(url);
  return response.data;  
};
export const saveSensor = async ({deviceId, sensors}: SaveSensor) => {
  const url: string = `/deviceSensor/${deviceId}/sensors`;
  const response = await axios.post(url, sensors);
  return response.data;
}
export const syncSensors = async (deviId: number) => {
  let url: string = `/deviceSensor/${deviId}/sensors/sync`;
  const response = await axios.get(url);
  return response.data;
}
export const connectBaseStation = async ({deviceId, mac, status}: ConnectBaseStation) => {
  const url: string = `/deviceSensor/${deviceId}/connectbasestation/${mac}/${status}`;
  const response = await axios.get(url);
  return response.data;
}
export const deleteSensor = async (sensor: ISensor) => {
  const url: string = `/deviceSensor/sensor/${sensor.deseId}`;
  const response = await axios.delete(url);
  return response.data;
}
export const addScannedSensor = async (sensor: ISensor) => {
  const url: string = `/deviceSensor/sensor`;
  const response = await axios.post(url, sensor);
  return response.data;
}
export const addSensor = async ({ deviId, mac }: AddSensor) => {
  const url: string = `/deviceSensor/${deviId}/newSensor/${mac}`;
  const response = await axios.post(url);
  return response.data;
}
export const getRefreshInterval = async () => {
  let url: string = `/deviceSettings/refreshInterval`;
  const response = await axios.get(url);
  return response.data;  
};
export const editSensorName = async ({ sensorId, name }: EditSensorName) => {
  let url: string = `/deviceSensor/${sensorId}/editname`;
  const response = await axios.put(url, {name});
  return response.data;
}
export const editSensorFunction = async ({sensorId, deviceFunction, funcType, eventConfig}: EditSensorFunction) => {
  let url: string = `/deviceSensor/${sensorId}/editfunction`;
  const data = {
    functionId: deviceFunction, 
    funcType,
    config: eventConfig
  }
  const response = await axios.put(url, data)
  return response.data;
}
export const editSensorType = async ({sensorId, type}: EditSensorType) => {
  let url: string = `/deviceSensor/${sensorId}/edittype/${type}`;
  const response = await axios.put(url);
  return response.data;
}
export const startLocalLearning = async (deviId: number) => {
  let url: string = `/deviceSensor/${deviId}/locallearning/start`;
  const response = await axios.get(url);
  return response.data;
}
export const stopLocalLearning = async (deviId: number) => {
  let url: string = `/deviceSensor/${deviId}/locallearning/stop`;
  const response = await axios.get(url);
  return response.data;
}
export const addLocalLearning = async ({deviId, gatewayId, rssi}: AddLocalLearning) => {
  let url: string = `/deviceSensor/${deviId}/locallearning/add/${gatewayId}/${rssi}`;
  const response = await axios.put(url, gatewayId);
  return response.data;
}
export const getFunctionsForType = async (type: string) => {
  let url: string = `/deviceSensor/functionsfortype/${type}`;
  const response = await axios.get(url);
  return response.data;
}
export const saveEventConfig = async ({sensorId, eventConfig}: EventConfigForm) => {
  let url: string = `/deviceSensor/${sensorId}/config`;
  const response = await axios.post(url, eventConfig);
  return response.data;
}
export const getSensorStatus = async (deviId: number) => {
  let url: string = `/deviceSensor/${deviId}/status`;
  const response = await axios.get(url);
  return response.data;
}
export const getTransactionsForSensor = async (sensorId: number) => {
  let url: string = `/deviceSensor/${sensorId}/tx`;
  const response = await axios.get(url);
  return response.data;
}


// Organisation
export const getOrganisation = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights/`;
  const response = await axios.get(url);
  return response.data;
}

export const saveOrganisation = async ({deviceId, settings}: SaveOrganisation) => {
  const url: string = `/device/firm/${deviceId}/changefirm/${settings.custId}/${settings.updateAhp}`
  const response = await axios.get(url);
  return response.data;
}

// Geofence
export const getGeofence = async (deviceId: number) => {
  const url: string = `/geofence/device/${deviceId}/`;
  const response = await axios.get(url);
  return response.data;
}
export const getGlobalGeofence = async () => {
  const url: string = `/geofence/existingGeofences`;
  const response = await axios.get(url);
  return response.data;
}
export const deleteGeofence = async (geofence: GeofenceValues) => {
  geofence.geofence.shape = null;
  const url: string = `/geofence`;
  const response = await axios({
    method: "delete",
    url,
    data: geofence
  });
  return response.data;
}

export const deleteSharedGeofence = async (geofenceId: number) => {
  const url: string = `/geofence/shared/${geofenceId}`;
  const response = await axios({
    method: "delete",
    url
  });
  return response.data;
}

export const saveGeofence = async (geofence: GeofenceValues) => {
  geofence.geofence.shape = null;
  const url: string = `/geofence`;
  const response = await axios({
    method: "post",
    url,
    data: geofence});
  return response.data;
}

export const saveGlobalSettings = async (settings: GlobalSettings) => {
  const url: string = `/geofence/globalsettings`;
  const response = await axios({
    method: "post",
    url,
    data: settings});
  return response.data;
}

export const getFenceDistance = async (geofence: GeofenceDistance) => {
  const url: string = `/geofence/getPolygonDistance`;
  const response = await axios({
    method: "post",
    url,
    data: geofence
  });
  return response.data;
}

export const getLatestPosition = async (deviceId: number) => {
  const url: string = `/device/followme/${deviceId}/1`;
  const response = await axios.get(url);
  return response.data;
}

export const getGeofenceIndoorLocations = async (deviId: number) => {
  const url: string = `/indoorlocation/devicewithcustomerparents/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomersGeofence = async (deviceId: number) => {
  const url: string = `/geofence/getcustomers/${deviceId}/`;
  const response = await axios.get(url);
  return response.data;
}

// Tracking
export const getTracking = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/tracking`;
  const response = await axios.get(url);
  return response.data;  
};

export const saveTracking = async ({deviceId, settings}: SaveTracking) => {
  const url: string = `/deviceSettings/${deviceId}/tracking`;
  const response = await axios.post(url, settings);
  return response.data;  
};

export const toggleAutoTracking = async ({deviceId, autoTracking}: UpdateAutoTracking) => {
  const url: string = `/deviceSettings/${deviceId}/autotracking/${autoTracking}`;
  const response = await axios.put(url);
  return response.data;  
};

export const toggleAcceptLBS = async ({deviceId, acceptLBS}: UpdateAcceptLBS) => {
  const url: string = `/deviceSettings/${deviceId}/acceptlbs/${acceptLBS}`;
  const response = await axios.put(url);
  return response.data;
};

export const updateDevicePositioning = async ({deviceId, disablePositioning}: UpdateDevicePositioning) => {
  const url: string = `/deviceSettings/${deviceId}/devicepositioning/${disablePositioning}`;
  const response = await axios.put(url);
  return response.data;  
};

export const startTracking = async ({deviceId, duration, interval}: StartTracking) => {
  const url: string = `/deviceSettings/${deviceId}/track/${duration}/${interval}`;
  const response = await axios.get(url);
  return response.data;  
};

export const stopTracking = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/track/stop`;
  const response = await axios.get(url);
  return response.data;  
};

export const togglePowerSaving = async ({deviceId, powerSaving}: UpdatePowerSaving) => {
  const url: string = `/deviceSettings/${deviceId}/powersaving/${powerSaving}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPosition = async (deviceId: number) => {
  const url: string = `/device/forcetrackingmessage/${deviceId}?_=${new Date().getTime()}`;
  const response = await axios.get(url);
  return response.data;
}

export const findMe = async (deviceId: number) => {
  const url: string = `/device/findme/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

// General
export const getGeneral = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/general`;
  const response = await axios.get(url);
  return response.data;
}

export const switchSafemate = async ({deviceId, serialNum}: SwitchSafemate) => {
  const url: string = `/device/switch/${deviceId}/${serialNum}`;
  const response = await axios.get(url);
  return response.data;
}
export const resetSafemate = async (deviceId: number) => {
  const url: string = `/device/default/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}
export const forgetSafemate = async (deviceId: number) => {
  const url: string = `/forget/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const clearWifiCache = async (deviceId: number) => {
  const url: string = `/device/clearwificache/${deviceId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const deleteSafemate = async (deviceId: number) => {
  const url: string = `/device/${deviceId}`;
  const response = await axios.delete(url);
  return response.data;
}
export const saveAlarmSettings = async ({deviceId, settings}: SaveAlarmSettings) => {
  const url: string = `/deviceSettings/${deviceId}/alarmsettings`;
  const response = await axios.post(url, settings);
  return response.data;
}
export const saveManDownSettings = async ({deviceId, settings}: SaveManDownSettings) => {
  const url: string = `/deviceSettings/${deviceId}/mandownsettings`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const saveHeartRateBloodPressureSettings = async ({deviceId, settings}: HeartRateBloodPresureSettings) => {
  const url: string = `/deviceSettings/${deviceId}/heartratebloodpressure`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const requestHeartRate = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/requestheartrate`;
  const response = await axios.get(url);
  return response.data;
}

export const requestBloodPressure = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/requestbloodpressure`;
  const response = await axios.get(url);
  return response.data;
}

export const getHeartRateBloodPressure = async (deviceId: number) => {
  const url: string = `/heartratebloodpressure/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const saveBattery = async ({deviceId, settings}: SaveBattery) => {
  const url: string = `/deviceSettings/${deviceId}/battery`;
  const response = await axios.post(url, settings);
  return response.data;
}
export const savePositioning = async ({deviceId, settings}: SavePositioning) => {
  const url: string = `/deviceSettings/${deviceId}/positioning`;
  const response = await axios.post(url, settings);
  return response.data;
}
export const saveGeneralSettings = async ({deviceId, settings}: SaveGeneralSettings) => {
  const url: string = `/deviceSettings/${deviceId}/generalsettings`;
  const response = await axios.post(url, settings);
  return response.data;
}
export const saveCommunication = async ({deviceId, settings}: SaveCommunication) => {
  const url: string = `/deviceSettings/${deviceId}/communication`;
  const response = await axios.post(url, settings);
  return response.data;
}
export const deleteTwin = async (deviceId: number) => {
  const url: string = `/device/deletetwin/${deviceId}${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const simulate = async ({deviId, data}: Simulate) => {
  const url: string = `/deviceSettings/${deviId}/simulate`;
  const response = await axios.post(url, data);
  return response.data;
}

export const getStepData = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/stepdata`;
  const response = await axios.get(url);
  return response.data;
}

export const toggleDevicePedometer = async (deviceId: number, value: boolean) => {
  const url: string = `/deviceSettings/${deviceId}/toggledevicepedometer/${value}`;
  const response = await axios.get(url);
  return response.data;
}

// Info
export const saveImage = async ({deviceId, file}: SaveImage) => {
  const url: string = `/file/userimage/${deviceId}`;

  const formData = new FormData();
  formData.append("file", file)
  formData.append("name", file.name);

  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'max-age=0, must-revalidate, no-store'
    }
  });
  return response.data;
}
export const removeImage = async (deviceId: number) => {
  const url: string = `/file/userimage/${deviceId}`;
  const response = await axios.delete(url);
  return response.data;
}
export const captureImage = async (gatewayId: string, deviId: number) => {
  const url: string = `/file/captureimage/${gatewayId}/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}
export const updateTraining = async ({deviceId, status}: UpdateTraining) => {
  const url: string = `/device/toggleTraining/${deviceId}/${status}`;
  const response = await axios.get(url);
  return response.data;
}
export const getNote = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/note`;
  const response = await axios.get(url);
  return response.data;
}
export const saveNote = async (deviceNote: DeviceNote) => {
  const url: string = `/deviceSettings/note`;
  const response = await axios.post(url, deviceNote);
  return response.data;
}
export const sendMessage = async (data: SendMessage) => {
  const url: string = '/deviceSettings/sendMessage';
  const response = await axios.post(url, data);
  return response.data;
}
export const getMessages = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/message`;
  const response = await axios.get(url);
  return response.data;
}

export const setUserTag = async (deviceId: number, userTag: UserTags) => {
  const url: string = `/deviceSettings/${deviceId}/userTag/${userTag}`;
  const response = await axios.put(url);
  return response.data;
}

//Connect urls

export const init = async (deviId: number, deviceType: string) => {
  const url: string = `device/initAndConnectDevice/${deviId}/${deviceType}`;
  const response = await axios.get(url);
  return response.data;
}


// Indoor
export const getLearnLink = async () => {
  const url: string = `/settings/indoorlearnlink`;
  const response = await axios.get(url);
  return response.data;
}

export const getDeviceMacs = async (input: number[]) => {
  const url: string = `/indoor/macs/${input[0]}/${input[1]}`;
  const response = await axios.get(url);
  return response.data;
}

export const getInitialPosition = async (deviceId: number) => {
  const url: string = `/indoor/position/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const addDeviceKnownMac = async (deviceMac: DeviceMac) => {
  const url: string = `/indoor/addknownmac`;
  const response = await axios.post(url, deviceMac);
  return response.data;
}

export const addDeviceKnownMacs = async (deviceMacs: DeviceMac[]) => {
  const url: string = `/indoor/addknownmacs`;
  const response = await axios.post(url, deviceMacs);
  return response.data;
}

export const deleteDeviceKnownMac = async (deviceMac: DeviceMac) => {
  const url: string = `/indoor/deleteknownmac/${deviceMac.demaId}/${deviceMac.deviceKnownMac.dekmId}/${deviceMac.deviId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const scanWifi = async (deviceId: number) => {
  const url: string = `/indoor/scanwifi/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const scanBLE = async (deviceId: number) => {
  const url: string = `/indoor/scanble/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

// Permissions
export const getPermissions = async ({deviceId}: Initialize) => {
  const url: string = `/device/${deviceId}/policies${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

// Inactivity
export const fetchInactivity = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/inactivity`;
  const response = await axios.get(url);
  return response.data;
}

export const saveInactivity = async ({ deviceId, settings }: SaveInactivity) => {
  const url: string = `/deviceSettings/${deviceId}/inactivity`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const setWandererEvent = async ( deviceId: number, active: boolean ) => {
  const url: string = `/deviceSettings/${deviceId}/setWandererEvent/${active}`;
  const response = await axios.put(url);
  return response;
}


// Walktest
export const startWalktest = async (deviceId: number) => {
  const url: string = `/deviceSensor/${deviceId}/walktest/start`;
  const response = await axios.get(url);
  return response.data;
}

export const stopWalktest = async (deviceId: number) => {
  const url: string = `/deviceSensor/${deviceId}/walktest/stop`;
  const response = await axios.get(url);
  return response.data;
}

export const fetchWalktest = async (deviceId: number) => {
  const url: string = `/deviceSensor/${deviceId}/walktest`;
  const response = await axios.get(url);
  return response.data;
}


// Indoor location
export const getIndoorLocationsForDevice = async (deviId: number) => {
  const url: string = `/indoorlocation/device/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getIndoorLocationsForCustomer = async (custId: number) => {
  const url: string = `/indoorlocation/customer/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const saveIndoorLocation = async (indoorLocation: IndoorLocation) => {
  const url: string = `/indoorlocation/save`;
  const response = await axios.post(url, indoorLocation);
  return response.data;
}

export const saveIndoorLocationName = async (indoorLocation: IndoorLocation) => {
  const url: string = `/indoorlocation/savename`;
  const response = await axios.post(url, indoorLocation);
  return response.data;
}

export const deleteIndoorLocation = async (inloId: number) => {
  const url: string = `/indoorlocation/delete/${inloId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const scanIndoorLocationsForDevice = async (deviId: number) => {
  const url: string = `/indoorlocation/scan/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const scanIndoorLocationsForDeviceFailure = async () => {
  const url: string = `/indoorlocation/timedout/scan`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomers = async (deviId: number) => {
  const url: string = `/indoorlocation/getcustomer/${deviId}`;
  const response = await axios.get(url);
  return response.data;  
};
