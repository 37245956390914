export enum Feedback{

  // Device
  SMS_REBOOT = "deviceReboot",
  SMS_RESET = "deviceResetViaSMS",
  FACTORY_RESET = "factorySettingsRestored",

  // Info
  USER_IMAGE_SAVED = "userImageSaved",
  USER_IMAGE_REMOVED = "userImageRemoved",
  USER_IMAGE_REQUESTED = "userImageRequested",
  SAVED_TRAINING = "trainingSaved",
  SAVED_NOTE = "noteSaved",
  DELETED_NOTE = "noteDeletedInfo",
  CONNECT_NOTE = "noteConnect",
  SEND_MESSAGE = "sendMessageFeedback",
  UPDATE_USER_TAG = "updateUserTag",
  
  // General
  SAVED_GENERAL_SETTINGS = "savedGeneralSettings",
  SAVED_COMMUNICATION = "savingComSettings",
  SAVED_POSITIONING = "savedPositioning",
  SAVED_BATTERY = "savedBattery",
  SAVED_ALARM_SETTINGS = "savedAlarmSettings",
  SAVED_MAN_DOWN_SETTINGS = "savedManDownSettings",
  SAVED_HEART_RATE_BLOOD_PRESSURE_SETTINGS = "savedHeartRateBloodPressureSettings",
  SAVED_INACTIVITY = "inactivityConfigSaved",
  REQUESTED_HEART_RATE = "requestedHeartRate",
  REQUESTED_BLOOD_PRESSURE = "requestedBloodPressure",
  DEVICE_RESET = "defaultedSettings",
  DEVICE_SWAPPED = "swappedDevice",
  DEVICE_FORGOT = "forgotDevice",
  CLEAR_WIFI_CACHE = "clearWifiCache",
  DELETED_TWIN = "deletedTwin",
  TOGGLE_DEVICE_PEDOMETER_SUCCESS = "toggleDevicePedometerSuccess",
  TOGGLE_DEVICE_PEDOMETER_FAILURE = "toggleDevicePedometerFailure",

  // Org
  SUBORG_CHANGED = "changedSubOrg",

  // Sound and Light
  SAVED_SETTINGS = "parametersSaved",

  // Geofence
  SAVED_GEOFENCE = "savedGeofences",
  SAVED_GLOBAL_SETTINGS = "savedGlobalSettings",
  DELETED_GEOFENCE = "deletedGeofence",
  DELETED_SHARED_GEOFENCE = "deletedSharedGeofence",
  SAVED_GEOFENCE_TRACKING = "savedGeofenceTracking",
  SAVED_GEOFENCE_POSITIONING_TRACKING = "savedGeofencePositioningTracking",


  // Tracking
  SAVED_TRACKING = "savedTracking",

  // Indoor
  ADD_DEVICE_KNOWN_MAC = "addDeviceKnownMac",
  DELETE_DEVICE_KNOWN_MAC = "deleteDeviceKnownMac",
  SCAN_WIFI_SUCCESS = "scanWifiSuccess",
  SCAN_BLE_SUCCESS = "scanBLESuccess",

  // Sensor
  SENSOR_NAME_UPDATED = "sensorNameUpdated",
  SENSOR_NAME_UPDATED_NO_CONTACT = "sensorNameUpdatedNoContact",
  SENSOR_TYPE_UPDATED = "sensorTypeUpdated",
  SENSOR_FUNCTION_UPDATED = "updateSensorFunction",
  SENSOR_FUNCTION_UPDATED_NO_CONTACT = "sensorFunctionUpdatedNoContact",
  SENSOR_CONFIG_SAVED = "sensorConfigSaved",
  SENSOR_CONFIG_SAVED_NO_CONTACT = "sensorConfigSavedNoContact",
  START_LOCAL_LEARN = "startLearn",
  STOP_LOCAL_LEARN = "stopLearn",
  ADD_LOCAL_LEARN = "addLearn",
  SENSOR_SYNC = "sensorSyncCompleted",
  SENSOR_IMAGE_REQUESTED = "sensorImageRequested",

  // Indoor location
  INDOOR_LOCATION_SAVE_SUCCESS = "indoorLocationSaveSuccess",
  INDOOR_LOCATION_NAME_SAVE_SUCCESS = "indoorLocationNameSaveSuccess",
  INDOOR_LOCATION_DELETE_SUCCESS = "indoorLocationDeleteSuccess",
  INDOOR_LOCATION_SCAN_SUCCESS = "indoorLocationScanWifiSuccess",
}
