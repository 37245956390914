import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Button, Grid } from "react-bootstrap";
import { Action, ActionFunctionAny } from "redux-actions";
import { Field, Formik, FormikProps, Form, useFormik, useFormikContext } from "formik";
import styled from "styled-components";

import { RadioGroup, Radio } from "../../../Elements/RadioFormik";
import { fetchOrganisation, saveOrganisation } from "../Store/routines";
import { Customer } from "../types";
import { VerticalRadioGroup } from "../SoundLight/Components/style";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { accessToCustomer, checkPermissionsForCustomer } from "../../Policy/rightsUtil";
import { GlobalRoles } from "../../GlobalRoles";
import { Checkbox } from "../../../Elements/CheckboxFormik";
import withFormWrapper from "@Elements/Form/formWrapper";
import Spinner from "../../Spinner";
import { AppState } from "@Safemate/Store/types";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import Loader, { WrappedLoader } from "@Elements/Loader";
import { OrganisationForm } from "../General/types";
import Icon from "@Elements/Icon";
import Box from "@Elements/Icon/Icons/Box";

interface OrganisationProps<T> extends WrappedComponentProps {
  fetchOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  saveOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  customers: Customer[];
  ownedByCustomer: number;
  ahpId: number;
  deviId: number;
  loaded: boolean;
  deviceSerialNumber: string;
  deviceName: string;
  deviceTypeName: string;
}

const IndentedField = styled.span<{ level: number, disabled: boolean }>`

    margin-left: ${props => props.level * 20}px;
    margin-top: 10px;

    ${({ disabled }) => disabled && "margin-top: 10px"};

`;

const CheckWrapper = styled.div`

    display: flex;
    align-items: center;
    margin: 40px 0 40px 0;

`;

const Wrapper = styled(Grid)`

    margin: 40px auto;
    max-width: 30em;

`;

const CurrentOrgWrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 25px;
    width: 100%;
    min-height: 30px;
    border-bottom: 2px solid #aaadb0;
    padding: 0 20px;

`;

const PreviewHeader = styled.div`

    margin: 20px 0;

`;

export const UpdateButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const Wrap = styled.div`
  position: relative;

  #spinner-div{
    position: unset;
    background-color: unset;
    padding: 15px;
  }
`;

const CustomerLabel = styled.span`
  display: flex;
  align-items: center;
  white-space: break-spaces;
`;

export interface OrgValues {
  custId: string;
  updatedAhp: boolean;
  deviId: number;
}

const Organisation = injectIntl(({ intl: { formatMessage }, fetchOrganisation, saveOrganisation, customers, loaded, ownedByCustomer, deviId, ahpId, deviceSerialNumber, deviceName, deviceTypeName }: OrganisationProps<OrgValues>) => {
  
  const { values, dirty } = useFormikContext<OrganisationForm>();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  
  React.useEffect(() => {
    fetchOrganisation();
  }, [])
 
  const customerInfo = React.useMemo(() => {
    const cust: { [index: string]: Customer } = {};
    customers.forEach((customer) => {
      cust[`${customer.customerId}`] = customer;
    })
    return cust;
  }, [customers])

  const [ selectedCustomerAhp, setSelectedCustomerAhp ] = React.useState<number>(ahpId);

  React.useEffect(() => {
    const foundCust = customers.find(cust => cust.customerId === values.custId);

    if(foundCust){
      setSelectedCustomerAhp(foundCust.defaultAhp);
    }
  }, [values.custId, customers])

  const options = React.useMemo(() => {
    return customers.map(customer => {

      const disabled = !checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_ORGANIZATION, customer.customerId);
      
      return (
        <IndentedField
          level={customer.level}
          disabled={disabled}
        >
          {!disabled
            ? <Field
              component={Radio}
              name="custId"
              radioValue={`${customer.customerId}`}
              id={`customer-${customer.customerId}`}
              label={<CustomerLabel title={customer.stock && formatMessage({id: "stockSuborg", defaultMessage: "Lagerunderavdeling"})}>{customer.customerName} {customer.stock && <Box/>}</CustomerLabel>}
            />
            : <CustomerLabel title={customer.stock && formatMessage({id: "stockSuborg", defaultMessage: "Lagerunderavdeling"})} style={{ opacity: "0.5", marginTop: "10px" }}>
              {customer.customerName} {customer.stock && <Box/>}
            </CustomerLabel>}
        </IndentedField>
      )
    })
  }, [customers])

  if (!loaded)
    return (
      <WrappedLoader/>
    )

  return (

    <Wrapper fluid>
      <UnsavedCheck dirtyOverride={() => {
        const cust = customers.find(c => c.customerId == values.custId);
        if(cust.stock) return false;
        return dirty;
      }}/>
        <div>
          <RadioGroup disabled={false}>
            <VerticalRadioGroup>
              {options}
            </VerticalRadioGroup>
          </RadioGroup>
          {(selectedCustomerAhp !== ahpId) && dirty &&
            <CheckWrapper>
              <Field
                value={values.updateAhp}
                name="updatedAhp"
                id="updatedAhp"
                label={formatMessage({ id: "ahpCheckBoxLabel", defaultMessage: "Overfør alarmsentral innstillinger til valgt organisasjon" })}
                component={Checkbox}
              />
            </CheckWrapper>}
          {dirty &&
            <div>
              <PreviewHeader>{formatMessage({ id: "moveOrgParagraphOne", defaultMessage: "Ønsker du å flytte" })} {deviceTypeName} '{deviceName}' ({deviceSerialNumber}) {formatMessage({ id: "moveOrgParagraphTwo", defaultMessage: "slik?" })} </PreviewHeader>
              <div>
                <h5>{formatMessage({ id: "fromOrgLabel", defaultMessage: "Fra" })}</h5>
              </div>
              <CurrentOrgWrapper>
                {customerInfo[ownedByCustomer] && customerInfo[ownedByCustomer].customerName}
              </CurrentOrgWrapper>
              <div>
                <h5>{formatMessage({ id: "toOrgLabel", defaultMessage: "Til" })}</h5>
              </div>
              <CurrentOrgWrapper>
                {customerInfo[ownedByCustomer] && customerInfo[values.custId].customerName}
              </CurrentOrgWrapper>
            </div>
          }
        </div>
    </Wrapper>

  )
})

const mapStateToProps = ({ settings: {organisation: { customers, loaded }, initialization: {deviId}, device: { device: { ownedByCustomer, ahpId, deviceSerialNumber, deviceTypeName, deviceName } } }}: AppState) => {
  return {
    ownedByCustomer,
    loaded,
    customers,
    ahpId,
    deviId,
    deviceSerialNumber, 
    deviceName,
    deviceTypeName
  }
}

const mapDispatchToProps = {
  fetchOrganisation,
  saveOrganisation
};


export default connect(mapStateToProps, mapDispatchToProps)(Organisation);