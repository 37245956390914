import {connect} from "react-redux";
import withFormWrapper from "@Elements/Form/formWrapper";
import {IndoorLocationSettings} from "@Safemate/DefaultSettings/Settings/types";
import {useIntl} from "react-intl";
import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {
  ListRow,
  ListColWrapperLeft,
  ListColWrapperRight,
  ListCol,
  ListColRight,
} from "@Safemate/Settings/IndoorLocation/indoorLocationComp";
import {AccentedText} from "@Safemate/Settings/IndoorLocation";
import {confirmModal, defaultIndoorLocationDevice} from "@Safemate/Settings/IndoorLocation/utils";
import Wifi from "@Icons/Wifi";
import Bluetooth from "@Icons/Bluetooth";
import Trash from "@Icons/Trash";
import {IndoorLocationDevice} from "@Safemate/Settings/IndoorLocation/types";
import {IndoorLocationMap} from "@Safemate/DefaultSettings/Settings/IndoorLocation/map";
import {reportsChanged, SetReportsChanged} from "@Safemate/DefaultSettings/Store/actions";
import styled from "styled-components";
import {SelectOption} from "@Safemate/DefaultSettings/types";
import {input} from "@Theme/common";
import {LabelEnum} from "@Safemate/DefaultSettings/LabelEnum";
import {Option} from "@Elements/Dropdown/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {Field} from "formik";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {deleteIndoorLocation} from "@Safemate/DefaultSettings/Store/routines";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import {AllPermissions, PolicyContext} from "@Safemate/Policy/Provider";
import {SettingToPolicyMap} from "@Safemate/DefaultSettings/Settings/tabDefinition";
import {SettingPrefixEnum} from "@Safemate/DefaultSettings/Settings/settingEnum";
import {AppState} from "@Safemate/Store/types";

const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
  margin-top: 15px;
  margin-right: 15px;
`

const DeleteButton = styled(Button)`
  background: #d60403;
  margin-top: 15px;
  margin-right: 15px;
`
const StyledField = styled(Field)`
  width: 140px;
`;

const StyledSpan = styled.span`
  padding-left: 1em;
`;

interface IndoorLocationsProps{
  reportsChanged: SetReportsChanged;
  deleteIndoorLocation: Routine<ActionFunctionAny<Action<any>>>;
  customerId: number;
}

const mapDispatchToProps = {
  reportsChanged,
  deleteIndoorLocation,
};

const mapStateToProps = ({defaultSettings: {settings, customer: { selectedCustomer: { customerId }}}}: AppState) => ({
  customerId
});

export const DefaultIndoorLocations = connect(mapStateToProps, mapDispatchToProps)(
  withFormWrapper<IndoorLocationSettings, IndoorLocationsProps>(({ formik: { values: { indoorLocations } },reportsChanged, deleteIndoorLocation, customerId }) => {

  const { formatMessage } = useIntl();
  const [ locationIndex, setLocationIndex ] = React.useState(0);

    const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const policy: PolicyNameEnum = SettingToPolicyMap.get(SettingPrefixEnum.INDOOR_LOCATION) as PolicyNameEnum;
    const currentPermission = permissions.get(policy).customerPermissions[customerId];
    const disabled = (!currentPermission.edit && !currentPermission.all ) && currentPermission.read;

  if(indoorLocations.length > 0){
    const options: SelectOption[] = indoorLocations.map((indoorLocation, index) => {
      return { "value": index, "text": indoorLocation.name }
    });
    return (
      <React.Fragment>
        <div style={{paddingBottom: "1em"}}>
          <Dropdown
            size={{width: input.width.medium}}
            title={formatMessage({id: LabelEnum.WIFI_LOCATION, defaultMessage: "Wifi location"})}
            options={options}
            initial={ locationIndex }
            onChange={(object: Option | Option[]) => {
              const value = (object as Option).value;
              setLocationIndex(value);
            }}
          />
        </div>
        <DefaultIndoorLocation indoorLocationIndex={locationIndex} deleteIndoorLocation={deleteIndoorLocation} reportsChanged={reportsChanged} setLocationIndex={setLocationIndex} disabled={disabled} />
      </React.Fragment>
    )
  } else {
    return <h4>{formatMessage({id: "addNewWifiLocationByScanningDevice", defaultMessage: "Wifi location"})}</h4>
  }
}))

interface IndoorLocationProps {
  indoorLocationIndex: number;
  deleteIndoorLocation: Function;
  setLocationIndex: Function;
  reportsChanged: SetReportsChanged;
  disabled: boolean;
}

const DefaultIndoorLocation = withFormWrapper<IndoorLocationSettings, IndoorLocationProps>(
  ({ formik: { values: { indoorLocations }, setFieldValue}, indoorLocationIndex, setLocationIndex, deleteIndoorLocation, disabled, reportsChanged }) => {

  const { formatMessage } = useIntl();
  const {Comp, func} = confirmModal();
  const [showMap, setShowMap] = React.useState(false);
  const [deleteIndoorLocationComp, setDeleteIndoorLocationComp] = React.useState(false);
  const [indoorLocationDevicesIndex, setIndoorLocationDevicesIndex] = React.useState(0);
  const [deleteInloId, setDeleteInloId] = React.useState(0);

  React.useEffect(() => {
    setShowMap(false);
  }, [indoorLocationIndex]);

  const spliceDevice = (index: number) => {
    indoorLocations[indoorLocationIndex].indoorLocationDevices.splice(index, 1);
    setFieldValue(`indoorLocations.${indoorLocationIndex}.indoorLocationDevices`, indoorLocations[indoorLocationIndex].indoorLocationDevices);
    reportsChanged(true);
  }
  const addDevice = () => {
    const indoorLocationDeviceNew: IndoorLocationDevice = defaultIndoorLocationDevice();
    indoorLocations[indoorLocationIndex].indoorLocationDevices.push(indoorLocationDeviceNew);
    setFieldValue(`indoorLocations.${indoorLocationIndex}.indoorLocationDevices`, indoorLocations[indoorLocationIndex].indoorLocationDevices);
    reportsChanged(true);
  }

  return(
    <React.Fragment>
      { !showMap &&
        <React.Fragment>
          <Row>
            <Col xs={12} sm={6} style={{paddingBottom: '10px'}}>
              <h4>{formatMessage({id: "indoorLocationNameHeading", defaultMessage: "Indoor location name"})}</h4>
              <StyledField style={{width: "100%"}} type="text" name={`indoorLocations.${indoorLocationIndex}.name`} disabled={disabled} />
            </Col>
          </Row>
          <h4 style={{marginTop: "1em"}}>{formatMessage({id:"indoorLocationDeviceHeading", defaultMessage:"Indoor location devices"})}</h4>
          {
            indoorLocations[indoorLocationIndex].indoorLocationDevices.map((indoorLocationDevice, index)=>{
              return(<ListRow key={index}>
                <Col xs={12} sm={1}>
                  <ListColWrapperLeft>
                    <ListCol>
                      { (indoorLocationDevice.type==="W") ? <Wifi size={`medium`} /> : <Bluetooth size={`medium`} />}
                    </ListCol>
                  </ListColWrapperLeft>
                </Col>
                <Col xs={12} sm={5}>
                  <ListColWrapperLeft>
                    <ListCol style={{width: "100%"}}>
                      { indoorLocationDevice.signalStrength > -119
                        ? <StyledSpan>{indoorLocationDevice.name}</StyledSpan> :
                          <StyledField maxLength={60} type="text" placeHolder={formatMessage({id:"indoorLocationDeviceSSIDLabel", defaultMessage:"SSID"})}  name={`indoorLocations.${indoorLocationIndex}.indoorLocationDevices.${index}.name`} disabled={disabled} /> }
                    </ListCol>
                  </ListColWrapperLeft>
                </Col>
                <Col xs={12} sm={5}><ListColWrapperLeft><ListCol style={{width: "100%"}}>
                  { indoorLocationDevice.signalStrength > -119 ?
                    <StyledSpan>{indoorLocationDevice.macAddress}</StyledSpan> :
                    <StyledField maxLength={17} type="text" placeHolder={formatMessage({id:"indoorLocationDeviceMacLabel", defaultMessage:"Mac Address"})} name={`indoorLocations.${indoorLocationIndex}.indoorLocationDevices.${index}.macAddress`} disabled={disabled} /> }
                </ListCol></ListColWrapperLeft></Col>
                <Col xs={12} sm={1}>
                  <ListColWrapperRight>
                    { !disabled && <ListColRight>
                      <Trash
                          tabIndex="0"
                          title={formatMessage({id: 'delete', defaultMessage: "Slett"})}
                          size="medium"
                          onClick={(event: any) => {

                            setDeleteIndoorLocationComp( false );
                            setIndoorLocationDevicesIndex(index);
                            func(true);
                          }}
                          inline
                          disabled={disabled}
                      />
                    </ListColRight> }

                  </ListColWrapperRight>
                </Col>
              </ListRow>)
            })
          }
          <Row>
            <Col xs={12}>
              <SaveButton disabled={disabled} onClick={() => {addDevice()}}>{formatMessage({id: "indoorLocationAddDevice", defaultMessage: "Add device"})}</SaveButton>
              <SaveButton disabled={disabled} onClick={() => {setShowMap(true)}}>{formatMessage({id: "editIndoorLocationMap", defaultMessage: "Pinpoint again"})}</SaveButton>
              { indoorLocations[indoorLocationIndex].inloId > 0 &&
                <DeleteButton disabled={indoorLocations[indoorLocationIndex].inloId < 1 || disabled} onClick={() => {
                  setDeleteIndoorLocationComp( true ); 
                  setDeleteInloId(indoorLocations[indoorLocationIndex].inloId); 
                  func(true);
                }} title={formatMessage({id: "deleteIndoorLocationButton", defaultMessage: "Delete"}) }
                >
                  {formatMessage({id: "deleteIndoorLocationButton", defaultMessage: "Delete"})}
                </DeleteButton>
              }
              <Comp
                title={`${formatMessage({id: "delete", defaultMessage: "Delete"})} ${formatMessage({id: "indoorLocation", defaultMessage: "indoor location"})}`}
                deleteButton={true}
                body={
                  deleteIndoorLocationComp ?
                    <React.Fragment>

                    <p>
                    <span>{formatMessage({id: "deleteIndoorLocationConfirmtext", defaultMessage: "Do you want to really delete indoor location"})}</span>
                    <AccentedText>{` ${indoorLocations[indoorLocationIndex].name}?`}</AccentedText>
                    </p>
                      { indoorLocations[indoorLocationIndex].hasGeofence &&
                        <React.Fragment>
                        <p>
                          <span>{`${formatMessage({id: LabelEnum.WIFI_DELETE_HAS_GEOFENCE_NAMES, defaultMessage: "WIFI location used in geofences"}) + ": " + indoorLocations[indoorLocationIndex].geofenceNames  }`}</span>
                        </p>
                        <p>
                          <span>{`${formatMessage({id: LabelEnum.WIFI_DELETE_HAS_GEOFENCE_DEVICE_COUNT, defaultMessage: "WIFI location used in devices"}) + ": " + indoorLocations[indoorLocationIndex].geofenceDeviceCount  }`}</span>
                        </p>
                        </React.Fragment>
                      }
                    </React.Fragment> : <p>
                    <span>{formatMessage({id: "deleteIndoorLocationDeviceConfirmtext", defaultMessage: "Do you want to really delete indoor device"})}</span>
                    {indoorLocations[indoorLocationIndex].indoorLocationDevices && indoorLocations[indoorLocationIndex].indoorLocationDevices[indoorLocationDevicesIndex] && 
                      <AccentedText>
                        {`${indoorLocations[indoorLocationIndex].indoorLocationDevices[indoorLocationDevicesIndex].name} (${indoorLocations[indoorLocationIndex].indoorLocationDevices[indoorLocationDevicesIndex].macAddress})?`}
                      </AccentedText> }
                  </p>
                }
                confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
                confirmFunc={() => {
                  if(deleteIndoorLocationComp){
                    setLocationIndex( indoorLocationIndex === 0 ? 0 : indoorLocationIndex - 1 );
                    deleteIndoorLocation(deleteInloId);
                    setDeleteInloId(0);
                  } else {
                    setDeleteIndoorLocationComp(true);
                    spliceDevice(indoorLocationDevicesIndex);
                  }
                  func(false);
                }}
              />
            </Col>
          </Row>
        </React.Fragment>
      }
      { showMap &&
      <React.Fragment>
        <h3>{formatMessage({id:"indoorLocationMapHeading", defaultMessage:"Locate the indoor location"})}</h3>
        <Row>
          <Col xs={12}>
            <IndoorLocationMap indoorLocationIndex={indoorLocationIndex} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{paddingTop: "1em"}}>
            <SaveButton disabled={disabled} onClick={() => {setShowMap(false)}}>{formatMessage({id:"indoorLocationDeviceHeading", defaultMessage:"Indoor location devices"})}</SaveButton>
          </Col>
        </Row>
      </React.Fragment>
    }
    </React.Fragment>
  )
})
