import {call, put, takeLatest, takeLeading} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { AhpReport } from '../types';

function* fetchAlarms(action: AppAction<null>){
  try{
    const alarms = yield call(Api.getAlarms);
    yield put(Routines.fetchAlarms.success(alarms));
  }
  catch(e){
      yield put(Routines.fetchAlarms.failure());
  }
}

function* fetchReport(action: AppAction<null>){
  try{
      const report = yield call(Api.getReport);
      yield put(Routines.fetchReport.success(report));
  }
  catch(e){
      yield put(Routines.fetchReport.failure());
  }
}

function* fetchAlarmHandleReport(action: AppAction<null>){
  try{
    const report = yield call(Api.getAlarmHandleReport);
    yield put(Routines.fetchAlarmHandleReport.success(report));
  }
  catch(e){
    yield put(Routines.fetchAlarmHandleReport.failure());
  }
}

function* fetchAhpReport(action: AppAction<null>){
  try{
    const ahpReport: AhpReport = yield call(Api.getAhpReport);
    yield put(Routines.fetchAhpReport.success(ahpReport));
  }
  catch(e){
    yield put(Routines.fetchAhpReport.failure());
  }
}

function* reportSaga(){
    yield takeLeading(Routines.fetchAlarms.TRIGGER, fetchAlarms);
    yield takeLeading(Routines.fetchReport.TRIGGER, fetchReport);
    yield takeLeading(Routines.fetchAlarmHandleReport.TRIGGER, fetchAlarmHandleReport);
    yield takeLeading(Routines.fetchAhpReport.TRIGGER, fetchAhpReport);
}

export default reportSaga;
