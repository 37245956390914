import * as React from "react";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { Button } from "react-bootstrap";

import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { TrackingSettings } from "../../types";
import {LabelWrapper, RowLabel, RowWrapper, ToggleWrap, PaddedCol } from ".";
import { Info } from "..";
import {DeviceHardwareEnum} from "../../../DeviceHardwareEnum";
import { TIME_OFFSET, mapOptions } from "@Elements/Form/utils";
import Tooltip from "@Elements/Tooltip";


interface TrackingIntervalProps extends WrappedComponentProps{
  dehaId?: number;
}

const trackingIntervalOpts = [1,2,3,4,5,10,20,30,45,60];

const TrackingInterval = injectIntl(
  withFormWrapper<TrackingSettings, TrackingIntervalProps>(
    ({ intl: {formatMessage}, formik: {values, setFieldValue, dirty, initialValues}, dehaId }) => {
      let intervalOptions: Option[] = [];
      intervalOptions = intervalOptions.concat(mapOptions(trackingIntervalOpts, formatMessage, "", TIME_OFFSET.SECONDS_IN_MINUTE, true));
      return(
        <RowWrapper>
          <LabelWrapper xs={12} sm={5} md={5}>
            <RowLabel>      
              {formatMessage({id: "trackingInterval", defaultMessage: "Lokaliseringsintervall"})}
            </RowLabel>
            <Tooltip
              popoverId="tracking-interval-popover"
              labelId="trackingIntervalDesc"
              defaultMessage="Intervallet mellom lokaliseringsmeldingene enheten sender til server."
            >
              <InformationIcon size="medium" />
            </Tooltip>
          </LabelWrapper>
          <PaddedCol sm={5} md={5}>
            <ToggleWrap>
              <Dropdown
                options={intervalOptions}
                initial={values.s03InSeconds}
                onChange={(opt) => {
                  const { value } = opt as Option;
                  setFieldValue("s03InSeconds", value);
                }}/>
            </ToggleWrap>
          </PaddedCol>
        </RowWrapper>
      )
    }
  )
)



export const NoTrackingInterval = () => {
  
  const { formatMessage } = useIntl();

  return(
    <RowWrapper>
      <LabelWrapper xs={12} sm={5} md={5}>
        {formatMessage({id: "trackingInterval", defaultMessage: "Lokaliseringsintervall"})}
      </LabelWrapper>
      <PaddedCol sm={7} md={7}>
        <Info>
          {formatMessage({id: "noTrackingInterval", defaultMessage: "Lokaliseringsinterval kan ikke endres for Watch One"})}
        </Info>
      </PaddedCol>
    </RowWrapper>
  )
}

export default TrackingInterval;
