import FieldWrapper, {GenericCheckbox, GenericDropdown, GenericTextField} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {trackingInterval} from "./options";
import {LabelEnum} from "../../LabelEnum";
import {AppState} from "@Safemate/Store/types";
import {connect} from "react-redux";
import * as React from "react";
import {distance} from "@Elements/Form/utils";

// Dropdowns
export const TrackingInterval = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: {
        id: 'dropdownMinutes',
        defaultMessage: 'Minutter'
      }
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.TRACKING_INTERVAL,
  title: LabelEnum.TRACKING_INTERVAL,
  dataOptions: {
    options: trackingInterval,
    skipMap: true
  }
});


// Checkboxes
export const AutoTracking = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.AUTO_TRACKING, defaultMessage: "Automatisk lokalisering"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.AUTO_TRACKING
});

interface GeolocationProps{
  beta: boolean;
}

const GeolocationMapStateToProps = ({appData:{ user: {beta}}}: AppState) => {
  return{
    beta
  }
};

export const GEOLOCATION_GOOGLE = connect(GeolocationMapStateToProps)(({ beta }: GeolocationProps) => {
  if(!beta){
    return null;
  }
  return React.createElement(FieldWrapper({
    componentOptions: {
      Component: GenericCheckbox,
      props: {
        checkboxString: true,
        label: {id: LabelEnum.GEOLOCATION_WIFI_LOOKUP_GOOGLE, defaultMessage: "Google geolocation lookup based on WIFI"}
      }
    },
    prefix: SettingPrefixEnum.DEVICE_SETTING,
    policyPrefix: SettingPrefixEnum.TRACKING,
    field: SettingEnum.GEOLOCATION_WIFI_LOOKUP_GOOGLE
  }))
});

export const GEOLOCATION_COMBAIN = connect(GeolocationMapStateToProps)(({ beta }: GeolocationProps) => {
  if(!beta){
    return null;
  }
  return React.createElement(FieldWrapper({
    componentOptions: {
      Component: GenericCheckbox,
      props: {
        checkboxString: true,
        label: {id: LabelEnum.GEOLOCATION_WIFI_LOOKUP_COMBAIN, defaultMessage: "Combain geolocation lookup based on WIFI"}
      }
    },
    prefix: SettingPrefixEnum.DEVICE_SETTING,
    policyPrefix: SettingPrefixEnum.TRACKING,
    field: SettingEnum.GEOLOCATION_WIFI_LOOKUP_COMBAIN
  }))
});

export const ACCEPT_LBS = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.ACCEPT_LBS, defaultMessage: "Geolocation lookup based on LBS"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.ACCEPT_LBS
});

export const WifiGeolocationAccuracyMeters = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {
      width: "250px",
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.TRACKING,
  field: SettingEnum.WIFI_GEOLOCATION_ACCURACY_METERS,
  title: LabelEnum.WIFI_GEOLOCATION_ACCURACY_METERS,
  tooltip: LabelEnum.WIFI_GEOLOCATION_ACCURACY_METERS_TOOLTIP,
  postfix: distance.meters
});
