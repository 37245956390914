import * as React from "react";
import styled from "styled-components";

export const Container = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  padding-bottom: 60px;
  text-align: center;
  cursor: pointer;
`;

export const Header = styled.h2`
    display: block;
    font-size: 24px;
    color: ${props => props.theme.colors.textPrimary};
    line-height: 33px;
    text-align: center;
    padding-top: 30px;
`;

const Count = styled.span<{ color?: string }>`

    font-size: 150px;
    line-height: 205px;
    font-weight: 700;
    color: ${({ color }) => color};

`;

const AlarmHandlingCount = styled.div`
    font-size: 60px;
    line-height: 100px;
    font-weight: 400;
`;

const IconWrapper = styled.div`

    display: inline;
    user-select: none;

    svg{
        width: 73px;
        height: 111px;
        display: inline;
        path{
            fill: ${({ color }) => color};
        }
    }

`;

interface CardProps {
    title: string | JSX.Element;
    count: number;
    icon: JSX.Element;
    color: string;
    onClick?: () => void;
}

const Card = ({ title, count, icon, color, onClick }: CardProps) => {
  return (
        <Container onClick={() => {
            if (onClick)
                onClick();
        }}>
            <Header>{title}</Header>
            <Count color={color} style={{ marginRight: "10px" }}>{count}</Count>
            <Count><IconWrapper color={color}>{icon}</IconWrapper></Count>
        </Container>
    )
}

export default Card;

interface AlarmHandlingCardProps {
  title: string | JSX.Element;
  recipientPercentage: number;
  recipientPercentageContent: string;
  alarmHandledPercentage: number;
  alarmHandledPercentageContent: string;
}

export const AlarmHandlingCard = ({ title, recipientPercentage, alarmHandledPercentage, recipientPercentageContent, alarmHandledPercentageContent }: AlarmHandlingCardProps) => {
  return (
    <Container>
      <Header>{title}</Header>
      <AlarmHandlingCount  style={{ marginRight: "10px" }}>{recipientPercentage}%</AlarmHandlingCount>
      <div>{recipientPercentageContent}</div>
      <AlarmHandlingCount>{alarmHandledPercentage}%</AlarmHandlingCount>
      <div>{alarmHandledPercentageContent}</div>
    </Container>
  )
}
