import {UserAdminActionTypes} from "./actionTypes";
import {UserWithCustomer} from "../Models/UserWithCustomer";
import {FilterAccountStatus} from "./types";
import { AppAction } from "@Safemate/Store/types";
import { OrderFilterEnum } from "../OrderFilterEnum";
import { DeletedUser } from "../Models/User";

export type SetDeleteModal = (userToDelete: UserWithCustomer | null) => AppAction<UserWithCustomer | null>;
export type SetRestoreModal = (userToDelete: DeletedUser | null) => AppAction<DeletedUser | null>;
export type SetEdit = (userToEdit: UserWithCustomer) => AppAction<UserWithCustomer>;
export type SetNew = (defaultValues: UserWithCustomer) => AppAction<UserWithCustomer>;
export type SelectLdap = (selected: boolean) => AppAction<boolean>;
export type FormReset = (key: string) => AppAction<string>;
export type SetSendNewPasswordModal = (sendNewPassword: UserWithCustomer | null) => AppAction<UserWithCustomer | null>;

export type FilterSearch = (search: string) => AppAction<string>;
export type FilterRole = (role: number[]) => AppAction<number[]>;
export type FilterCustomer = (customer: number[]) => AppAction<number[]>;
export type FilterStatus = (status: FilterAccountStatus) => AppAction<FilterAccountStatus>;
export type FilterOrder = (order: OrderFilterEnum) => AppAction<OrderFilterEnum>;

export const setDeleteModal: SetDeleteModal = (userToDelete) => ({
  type: UserAdminActionTypes.SET_DELETE_MODAL,
  payload: userToDelete
});

export const setRestoreModal: SetRestoreModal = (userToRestore) => ({
  type: UserAdminActionTypes.SET_RESTORE_MODAL,
  payload: userToRestore
});

export const setEdit: SetEdit = (userToEdit) => ({
  type: UserAdminActionTypes.SET_EDIT,
  payload: userToEdit
})

export const setNew: SetNew = (defaultValues) => ({
  type: UserAdminActionTypes.SET_NEW,
  payload: defaultValues
})

export const selectLdap: SelectLdap = (selected) => ({
  type: UserAdminActionTypes.SELECT_LDAP,
  payload: selected
})

export const formReset: FormReset = (key) => ({
  type: UserAdminActionTypes.FORM_RESET,
  payload: key
});

export const setSendNewPasswordModal: SetSendNewPasswordModal = (sendNewPassword) => ({
  type: UserAdminActionTypes.SEND_NEW_PASSWORD,
  payload: sendNewPassword
});

export const filterSearch: FilterSearch = (search) => ({
  type: UserAdminActionTypes.FILTER_SEARCH,
  payload: search
})

export const filterRole: FilterRole = (role) => ({
  type: UserAdminActionTypes.FILTER_ROLE,
  payload: role
})

export const filterCustomer: FilterCustomer = (customer) => ({
  type: UserAdminActionTypes.FILTER_CUSTOMER,
  payload: customer
})

export const filterStatus: FilterStatus = (status) => ({
  type: UserAdminActionTypes.FILTER_STATUS,
  payload: status
})

export const filterOrder: FilterOrder = (order) => ({
  type: UserAdminActionTypes.FILTER_ORDER,
  payload: order
})
