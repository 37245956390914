import {UserWithCustomer} from "../Models/UserWithCustomer";
import {Role} from "../Models/Role";
import {Customer} from "../Models/Customer";
import {LdapUser} from "../Models/LdapUser";
import {Option} from "../../DefaultSettings/Settings/FieldWrapper";
import {DeletedUser} from "../Models/User";
import { SiteTypeEnum } from "../../SiteTypeEnum";
import { OrderFilterEnum } from '../OrderFilterEnum';

export interface RootState{
  initialization: InitializationState;
  user: UserState;
  action: ActionState;
  role: RoleState;
  filter: FilterState;
}

export interface InitializationState{
  userInitialized: boolean;
  deletedUsersInitialized: boolean;
  ldapInitialized: boolean;
  rolesInitialized: boolean;
  customersInitialized: boolean;
  adminRoleInitialized: boolean;
  initializedAll: boolean;
}

export interface UserState{
  users: Array<UserWithCustomer>;
  deletedUsers: Array<DeletedUser>;
  ldap: Ldap;
}

export interface Ldap{
  ldapUsers: Array<Option>;
  loading: boolean;
  selected: boolean;
}

export interface ActionState{
  deleteModal: UserWithCustomer | null;
  restoreModal: DeletedUser | null;
  sendNewPasswordModal: UserWithCustomer | null;
  edit: Edit;
}

export interface Edit{
  user: UserWithCustomer;
  formKey: string;
  isNew: boolean;
  saving: boolean;
}

export interface RoleState{
  roles: Array<Role>;
  admin: Role;
}

export interface AhpRoles{
  AHP: boolean,
  SUPERAHP: boolean,
  TECHNICIAN: boolean
}

export interface FilterState{
  search: string;
  role: number[];
  customer: number[];
  status: FilterAccountStatus;
  orderFilter: OrderFilterEnum;
}

export enum FilterAccountStatus{
  ACTIVE,
  INACTIVE,
  DELETED
}
