import { defineMessages, MessageDescriptor } from 'react-intl';
import { Option } from "@Elements/Dropdown/types";

export const time = defineMessages({
  second: { id: 'dropdownSecond', defaultMessage: 'sekund' },
  seconds: { id: 'dropdownSeconds', defaultMessage: 'sekunder' },
  minute: { id: 'dropdownMinute', defaultMessage: 'minutt' },
  minutes: { id: 'dropdownMinutes', defaultMessage: 'minutter' },
  hour: { id: 'dropdownHour', defaultMessage: 'time' },
  hours: { id: 'dropdownHours', defaultMessage: 'timer' },
  day: { id: 'dropdownDay', defaultMessage: 'dag' },
  days: { id: 'dropdownDays', defaultMessage: 'dager' },
  week: { id: 'dropdownWeek', defaultMessage: 'uke' },
  weeks: { id: 'dropdownWeeks', defaultMessage: 'uker' }
});

export const status = defineMessages({
  enable: { id: 'statusEnable', defaultMessage: 'Aktiver' },
  disable: { id: 'statusDisable', defaultMessage: 'Deaktiver' }
});

export const distance = defineMessages({
  meters: { id: 'distanceMeters', defaultMessage: 'meters' },
  miles: { id: 'distanceMiles', defaultMessage: 'miles' },
  kiloMeters: { id: 'distanceKiloMiles', defaultMessage: 'kilometers' },
});

export enum TIME_OFFSET{
  SECONDS_IN_SECOND = 1,
  SECONDS_IN_MINUTE = 60,
  SECONDS_IN_HOUR = 3600,
  SECONDS_IN_DAY = 86400,
  SECONDS_IN_WEEK = 604800,
}

export type IsSingle = (time: number, offset: number) => boolean;
export type TimeCheck = (time: number) => boolean;


export const isSingle: IsSingle = (number, offset) => number / offset === 1;

export const isSeconds: TimeCheck = number => number < TIME_OFFSET.SECONDS_IN_MINUTE;

export const isMinutes: TimeCheck = number =>
  number >= TIME_OFFSET.SECONDS_IN_MINUTE && number < TIME_OFFSET.SECONDS_IN_HOUR;

export const isHours: TimeCheck = number =>
  number >= TIME_OFFSET.SECONDS_IN_HOUR && number < TIME_OFFSET.SECONDS_IN_DAY;

export const isDays: TimeCheck = number =>
  number >= TIME_OFFSET.SECONDS_IN_DAY && number < TIME_OFFSET.SECONDS_IN_WEEK;

export const isWeeks: TimeCheck = number => number >= TIME_OFFSET.SECONDS_IN_WEEK;

export type MapOptions = (options: number[], intl: (msg: MessageDescriptor) => string, zeroText: string, offset?: number, inSeconds?: boolean, roundDown?: boolean) => Option[];

// Base is in seconds
export const mapOptions: MapOptions = (options, intl, zeroText, offset, inSeconds, roundDown) => {
  const checkFunctions = [
    {
      check: isSeconds,
      offset: 1,
      single: intl(time.second),
      multiple: intl(time.seconds)
    },
    {
      check: isMinutes,
      offset: TIME_OFFSET.SECONDS_IN_MINUTE,
      single: intl(time.minute),
      multiple: intl(time.minutes)
    },
    {
      check: isHours,
      offset: TIME_OFFSET.SECONDS_IN_HOUR,
      single: intl(time.hour),
      multiple: intl(time.hours)
    },
    {
      check: isDays,
      offset: TIME_OFFSET.SECONDS_IN_DAY,
      single: intl(time.day),
      multiple: intl(time.days)
    },
    {
      check: isWeeks,
      offset: TIME_OFFSET.SECONDS_IN_WEEK,
      single: intl(time.week),
      multiple: intl(time.weeks)
    }
  ];

  return options.map(value => {
    const itemWithOffset = offset ? value * offset : value;
    if (itemWithOffset === 0)
      return {
        text: zeroText,
        value
      };
    for (let i = 0; i < checkFunctions.length; i++) {
      const item = checkFunctions[i];
      if (item.check(itemWithOffset)) {
        if(roundDown && !Number.isInteger(itemWithOffset / item.offset) && i-1 >= 0){
          const roundedItem = checkFunctions[i-1];
          return {
            text: `${itemWithOffset / roundedItem.offset} ${
              isSingle(itemWithOffset, roundedItem.offset) ? roundedItem.single : roundedItem.multiple
            }`,
            value: inSeconds ? itemWithOffset : value
          }
        }
        return {
          text: `${itemWithOffset / item.offset} ${
            isSingle(itemWithOffset, item.offset) ? item.single : item.multiple
          }`,
          value: inSeconds ? itemWithOffset : value
        };
      }
    }
  }) as Option[];
};

export type TranslateOptions = (options: Option[], intl: (msg: MessageDescriptor) => string) => Option[];

export const translateOptions: TranslateOptions = (options, intl) => {

  return options.map(opt => {
    return {
      value: opt.value,
      text: intl({id: opt.text, defaultMessage: opt.text})
    }
  })
}
