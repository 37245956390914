
import {  DeviceFunctionEnum } from './SensorFunctionEnum';
import { DeviceType } from './SensorTypeEnum';
import { Option } from "@Elements/Dropdown/types";

// Very specific for Trondheim, basically a freetext but they wanted a dropdown with a bunch of options to make it easier.
// No need to translate

export enum SensorFunctionTypeEnum{
  MOBILE = 'Mobil',
  STATIONARY = 'Stasjonær',
  FINGER_TRIGGER = 'Fingerbryter',
  HEAD_TRIGGER = 'Hodebryter',
  SWAN_NECK = 'Kule/svanehals',
  LIGHT_TRIGGER = 'Lett-utløser',
  PILLOW_TRIGGER = 'Putebryter',
  RESPIRATOR = 'Respirator',
  VOICE_CONTROLLED = 'Stemmestyrt',
  SUCK_AND_BLOW = 'Sug-og-blås',
  STEP_MATTE = 'Tråkkematte',

  // Custom events
  USER_ALARM = "userAlarm",
  LOW_BATTERY = "lowBatteryAlarm",
  FIRE = "fireAlarm",
  FRIDGE = "fridgeAlarm",
  GEOFENCE = "geofenceAlarm",
  MAN_DOWN = "fallAlarm",
  OFFLINE = "deviceOfflineAlarm",
  WANDERER = "wandererAlarm"
}

export const TypesForEpilepsy = [SensorFunctionTypeEnum.MOBILE, SensorFunctionTypeEnum.STATIONARY];

export const TypesForCustom = [
  {
    label: SensorFunctionTypeEnum.USER_ALARM,
    code: "1"
  },
  {
    label: SensorFunctionTypeEnum.LOW_BATTERY,
    code: "111"
  },
  {
    label: SensorFunctionTypeEnum.FIRE,
    code: "27"
  },
  {
    label: SensorFunctionTypeEnum.FRIDGE,
    code: "21"
  },
  {
    label: SensorFunctionTypeEnum.GEOFENCE,
    code: "102"
  },
  {
    label: SensorFunctionTypeEnum.MAN_DOWN,
    code: "3"
  },
  {
    label: SensorFunctionTypeEnum.OFFLINE,
    code: "7"
  },
  {
    label: SensorFunctionTypeEnum.WANDERER,
    code: "22"
  }
]

export const TypesForDefault = [
  SensorFunctionTypeEnum.FINGER_TRIGGER,
  SensorFunctionTypeEnum.HEAD_TRIGGER,
  SensorFunctionTypeEnum.SWAN_NECK,
  SensorFunctionTypeEnum.LIGHT_TRIGGER,
  SensorFunctionTypeEnum.PILLOW_TRIGGER,
  SensorFunctionTypeEnum.RESPIRATOR,
  SensorFunctionTypeEnum.VOICE_CONTROLLED,
  SensorFunctionTypeEnum.SUCK_AND_BLOW,
  SensorFunctionTypeEnum.STEP_MATTE
];

type DisplayFunctionType = (type: DeviceType | string, func: string) => boolean;

export const displayFunctionType: DisplayFunctionType = (type, func) => {
  return (
    (type === DeviceType.UNIVERSAL_SENSOR ||
      type === DeviceType.UNIVERSAL_SENSOR_SPT_1 ||
      type === DeviceType.PANIC_BUTTON) &&
    (func === DeviceFunctionEnum.EPILEPSY_FUNCTION || func === DeviceFunctionEnum.MEDICAL_ALARM)
  );
};

type GetForFunction = (func: string, translate: Function) => Option[];

export const getForFunction: GetForFunction = (func, translate) => {
  switch (func) {
    case DeviceFunctionEnum.EPILEPSY_FUNCTION:
      return TypesForEpilepsy.map(option => ({ text: option, value: option }));
    case DeviceFunctionEnum.MEDICAL_ALARM:
      return TypesForDefault.map(option => ({ text: option, value: option }));
    case DeviceFunctionEnum.CUSTOM_SENSOR_FUNCTION:
      return TypesForCustom.map(option => ({ text: translate({id: option.label, defaultMessage: option.label}), value: option.code }));
    default:
      return [];
  }
};
