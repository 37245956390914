import withFormWrapper from "@Elements/Form/formWrapper";
import { ModalBody, ModalDiv, ModalHeader } from "@Safemate/EventSetupNew/Body/ModalStyles";
import * as React from "react";
import { EventSetupSettings } from "../types";
import { useIntl } from "react-intl";
import { CheckBoxWrapper, DaysTh, Table } from "@Safemate/EventSetupNew/Body/TableElementStyles";
import { Checkbox } from "@Elements/CheckboxFormik";
import { Field } from "formik";
import { SettingEnum } from "../settingEnum";
import { ButtonDiv } from "@Safemate/EventSetupNew/Body/WrapperStyles";
import { EventSetup } from "./types";
import { connect } from "react-redux";
import { StyledButton } from "@Safemate/EventSetupNew/Body/FormElementStyles";
import { Day } from "./styles";
import { AppState } from "@Safemate/Store/types";
import {InformationProps} from "@Safemate/DefaultSettings/Settings/EventSetup/index";

interface DaysProps{
  index: number;
  close: (display: boolean) => void;
}

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
]


export const Days = withFormWrapper<EventSetupSettings, DaysProps>(
  ({formik: { setFieldValue, initialValues: { deviceEventSetups: initial }, values: { deviceEventSetups } }, index, close}) => {

  const { formatMessage } = useIntl();
  
  const prefix = `${SettingEnum.EVENT_SETUP}.${index}`;

  return(
    <ModalDiv>
      <ModalHeader>
        {formatMessage({ id: 'days', defaultMessage: 'Days' })}
      </ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>{days.map(day => <DaysTh key={`header${day}`}>{formatMessage({id: day, defaultMessage: day})}</DaysTh>)}</tr>
          </thead>
          <tbody>
            <tr>
              <DayCheck name={`${prefix}.warnOnMon`} value={deviceEventSetups[index].warnOnMon}/>
              <DayCheck name={`${prefix}.warnOnTue`} value={deviceEventSetups[index].warnOnTue}/>
              <DayCheck name={`${prefix}.warnOnWed`} value={deviceEventSetups[index].warnOnWed}/>
              <DayCheck name={`${prefix}.warnOnThu`} value={deviceEventSetups[index].warnOnThu}/>
              <DayCheck name={`${prefix}.warnOnFri`} value={deviceEventSetups[index].warnOnFri}/>
              <DayCheck name={`${prefix}.warnOnSat`} value={deviceEventSetups[index].warnOnSat}/>
              <DayCheck name={`${prefix}.warnOnSun`} value={deviceEventSetups[index].warnOnSun}/>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ButtonDiv>
        <StyledButton id="confirm-days" onClick={() => close(false)}>
          {formatMessage({id: "ok", defaultMessage: "ok"})}
        </StyledButton>
        <StyledButton id="cancel-days" onClick={() => {
          setFieldValue(`${prefix}.warnOnMon`, initial[index].warnOnMon);
          setFieldValue(`${prefix}.warnOnTue`, initial[index].warnOnTue);
          setFieldValue(`${prefix}.warnOnWed`, initial[index].warnOnWed);
          setFieldValue(`${prefix}.warnOnThu`, initial[index].warnOnThu);
          setFieldValue(`${prefix}.warnOnFri`, initial[index].warnOnFri);
          setFieldValue(`${prefix}.warnOnSat`, initial[index].warnOnSat);
          setFieldValue(`${prefix}.warnOnSun`, initial[index].warnOnSun);
          close(false)
        }}>
          {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
        </StyledButton>
      </ButtonDiv>
    </ModalDiv>
  )
})

interface DayCheckProps{
  value: boolean;
  name: string;
}

export const DayCheck = ({ value, name }: DayCheckProps) => {
  return (
    <CheckBoxWrapper>
      <Field
        value={value}
        name={name}
        id={name}
        outerDiv={{float: "unset", justifyContent: "center"}}
        component={Checkbox}
      />
    </CheckBoxWrapper>
  );
};

export const DaysView = withFormWrapper<EventSetupSettings, InformationProps>(({ formik: { values:  { deviceEventSetups }}, index, disabled}) => {

  const { formatMessage } = useIntl();

  return (
    <div>
      <Day selected={deviceEventSetups[index].warnOnMon}>M</Day>
      <Day selected={deviceEventSetups[index].warnOnTue}>T</Day>
      <Day selected={deviceEventSetups[index].warnOnWed}>
        {formatMessage({ id: 'letterWednesday', defaultMessage: 'O' })}
      </Day>
      <Day selected={deviceEventSetups[index].warnOnThu}>T</Day>
      <Day selected={deviceEventSetups[index].warnOnFri}>F</Day>
      <Day selected={deviceEventSetups[index].warnOnSat}>
        {formatMessage({ id: 'letterSaturday', defaultMessage: 'L' })}
      </Day>
      <Day last selected={deviceEventSetups[index].warnOnSun}>S</Day>
    </div>
  );
})
