import * as React from "react";
import { Formik, Form, Field } from "formik";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";

import { Checkbox } from "../../../../Elements/CheckboxFormik";
import { SettingEnum } from "../../../DefaultSettings/Settings/settingEnum";
import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeneralSettingsForm } from "../types";
import { DeviceHardwareEnum, isGx8 } from "../../../DeviceHardwareEnum";
import { saveGeneralSettings } from "../../Store/routines";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import {confirmModal} from "./deleteData";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import SettingTab from "@Safemate/Settings/Components/settingTab";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

interface GeneralSettingsProps{
  dehaId: number;
  deviId: number;
  generalSettings: GeneralSettingsForm;
  saveGeneralSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const GeneralSettings = ({ dehaId, deviId, generalSettings, saveGeneralSettings }: GeneralSettingsProps) => {

  return (
    <SettingTab
      dehaId={dehaId}
      deviId={deviId}
      settings={generalSettings}
      save={saveGeneralSettings}
      settingEnum={isGx8(dehaId) ? ComponentSettingEnum.GX8_GENERAL : ComponentSettingEnum.GENERAL_SETTINGS}
      header={{
        title: "generalSettings",
        tooltip: isGx8(dehaId) 
          ? "generalHeadlineToolTipText"
          : undefined,
        tooltipTitle: isGx8(dehaId)
          ? "generalSettingsTab"
          : undefined
        }}
    />
  )
}


const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {generalSettings}}}: AppState) => {
  return {
    dehaId,
    deviId,
    generalSettings,
  }
}

const mapDispatchToProps = {
  saveGeneralSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);

export const EnableOffButton = CheckWrap<{}>(
  SettingEnum.ENABLE_OFF_BUTTON, 
  {
    label: {
      id: "enableOffButton", 
      defaultMessage: "Av-knapp aktivert"
    },
    contents: true,
    tooltip: "enableOffButtonToolTipText",
  }
);

const StaticTooLongHeader = styled.h3`
  font-size: 14pt;
  margin-top: 1em;
`;

const StaticTooLongContainer = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  * {
    float: none;
  }
  span {
    padding-left: 5px;
  }
`;

interface StaticTooLongProps extends WrappedComponentProps{
  staticTooLongDefault: string;
}

const mapStaticTooLongStateToProps = ({ settings: {general: { staticTooLongDefault }}} : AppState) => {
  return {
    staticTooLongDefault
  }
}

export const StaticTooLong = connect(mapStaticTooLongStateToProps)( 
  injectIntl(
    withFormWrapper<GeneralSettingsForm, StaticTooLongProps>(
      ({formik: {values: {staticTooLong}, setFieldValue}, intl: {formatMessage}, staticTooLongDefault}) => {

        const [ overrideStaticTooLong, setOverrideStaticTooLong ] = React.useState(staticTooLong !== staticTooLongDefault);

        return(
          <StaticTooLongContainer>
            <div>
              <Checkbox
                style={{float:"none"}}
                value={overrideStaticTooLong}
                id={"overrideStaticTooLong"}
                label={formatMessage({id: "overrideStaticTooLong", defaultMessage: "Overskriv ikke i aktiv bruk-terskel"})}
                onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                  const value = event.currentTarget.checked;
                  setOverrideStaticTooLong(value);
                  if(!value){
                    setFieldValue("staticTooLong", staticTooLongDefault);
                  }
                }}
            /></div>
            <Collapse isOpened={overrideStaticTooLong}>
              <div>
                <StaticTooLongHeader>{formatMessage({id: "staticTooLongInterval", defaultMessage: "Ikke i bevegelse"})}</StaticTooLongHeader>
                <div>
                  <Field 
                    name="staticTooLong"
                    type="text"
                  /> 
                  <span>{formatMessage({id: "hours", defaultMessage: "timer"})}</span>
                </div>
              </div>
            </Collapse>
          </StaticTooLongContainer>
        )
      }
    )
  )
)

const VibrationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface VibrationProps{
  silentAlarm: String;
  fiwaNum: number;
  dehaId: number;
}

const mapVibrationStateToProps = ({ settings: {general: { alarmSettings: { silentAlarm } }, device:{device: {fiwaNum, dehaId}}}}: AppState) => {
  return {
    silentAlarm,
    fiwaNum,
    dehaId,
  }
}

export const Vibration2 = CheckWrap<{}>(
  SettingEnum.VIBRATION, 
  {
    label: {
      id: "vibration", 
      defaultMessage: "Vibration"
    },
    tooltip: "vibractionDesc",
  }
);
  
export const Vibration = 
  connect(mapVibrationStateToProps)(withFormWrapper<GeneralSettingsForm, VibrationProps>(({ formik: { setFieldValue, values: { vibration } }, silentAlarm, dehaId, fiwaNum }) => {
    const { formatMessage } = useIntl();

    return(
      <React.Fragment>
        { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
        <VibrationWrapper>
        {React.createElement(CheckWrap("vibration", {label: {id: "vibration", defaultMessage: "Vibration"}, contents: true}))}
        &nbsp;
        <Tooltip
          popoverId="vibration-popover"
          labelId="vibractionDesc"
          defaultMessage="Når lydløs alarm er på vil ikke denne innstillingen ha en effekt på enheten. Lydløs alarm er nå satt til "
          postfix={silentAlarm === "1" ? formatMessage({id: "on", defaultMessage: "på"}) : formatMessage({id: "off", defaultMessage: "av"})}
          >
          <InformationIcon size="medium" />
        </Tooltip>
          </VibrationWrapper>
        </React.Fragment>}
      </React.Fragment>
    )
  }
))

interface HealthHeartRateBloodPressureProps{
  healthHeartRateBloodPressureState: boolean;
  fiwaNum: number;
  dehaId: number;
}

const mapHealthHeartRateBloodPressureStateToProps = ({ settings: {general: { generalSettings: { healthHeartRateBloodPressure } }, device:{device: {fiwaNum, dehaId}}}}: AppState) => {
  return {
    healthHeartRateBloodPressureState: healthHeartRateBloodPressure,
    fiwaNum,
    dehaId,
  }
}
export const HealthHeartRateBloodPressure =
  connect(mapHealthHeartRateBloodPressureStateToProps)(withFormWrapper<GeneralSettingsForm, HealthHeartRateBloodPressureProps>(({ formik: { setFieldValue, values: { healthHeartRateBloodPressure } }, healthHeartRateBloodPressureState, fiwaNum, dehaId }) => {

      const { formatMessage } = useIntl();

      // https://safemate.atlassian.net/browse/SS-7005
      const disabled = dehaId === DeviceHardwareEnum.WATCH_FIVE;

      const labelId = dehaId === DeviceHardwareEnum.WATCH_FIVE ? "functionalityWillBeReadySoon" : "stepCounterDesc";
      const description = dehaId === DeviceHardwareEnum.WATCH_FIVE ? "Functionality will be ready soon!" : "Show health menu for measuring heart rate and blood pressure";

      return(
        <React.Fragment>
          { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
          <VibrationWrapper>
          {React.createElement(CheckWrap("healthHeartRateBloodPressure", {disabled, label: {id: "healthHeartRateBloodPressure", defaultMessage: "Health menu"}, contents: true}))}
            &nbsp;
            <Tooltip
              popoverId="healthHeartRateBloodPressure-popover"
              labelId={labelId}
              defaultMessage={description}
            >
              <InformationIcon size="medium" />
            </Tooltip>
          </VibrationWrapper>
          </React.Fragment>}
        </React.Fragment>
      )
    }
  ))

interface WatchRemovalProps{
  watchRemovalState: boolean;
  fiwaNum: number;
  dehaId: number;
}

const mapWatchRemovalStateToProps = ({ settings: {general: { generalSettings: { watchRemoval } } , device:{device: {fiwaNum, dehaId}}}}: AppState) => {
  return {
    watchRemovalState: watchRemoval,
    fiwaNum,
    dehaId,
  }
}
export const WatchRemoval =
  connect(mapWatchRemovalStateToProps)(withFormWrapper<GeneralSettingsForm, WatchRemovalProps>(({ formik: { setFieldValue, values: { watchRemoval } }, watchRemovalState, fiwaNum, dehaId }) => {

      const { formatMessage } = useIntl();

      // https://safemate.atlassian.net/browse/SS-7005
      const disabled = dehaId === DeviceHardwareEnum.WATCH_FIVE;

      const labelId = dehaId === DeviceHardwareEnum.WATCH_FIVE ? "functionalityWillBeReadySoon" : "watchRemovalDesc";
      const description = dehaId === DeviceHardwareEnum.WATCH_FIVE ? "Functionality will be ready soon!" : "Alert when watch removed";


      return(
        <React.Fragment>
          { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
          <VibrationWrapper>
          {React.createElement(CheckWrap("watchRemoval", {disabled, label: {id: "watchRemoval", defaultMessage: "Watch removal alert"}, contents: true}))}
            &nbsp;
            <Tooltip
              popoverId="watchRemoval-popover"
              labelId={labelId}
              defaultMessage={description}
            >
              <InformationIcon size="medium" />
            </Tooltip>
          </VibrationWrapper>
          </React.Fragment>}
        </React.Fragment>
      )
    }
  ))

interface AclCheckProps{
  aclCheckState: boolean;
  deviceSerialNumber: string;
  deviceName: string;
}

const mapAclCheckStateToProps = ({ settings: {general: { generalSettings: { aclCheck } }, device:{device:{deviceSerialNumber, deviceName}}}}: AppState) => {
  return {
    aclCheckState: aclCheck,
    deviceSerialNumber: deviceSerialNumber,
    deviceName: deviceName,
  }
}
export const AclCheck =
  connect(mapAclCheckStateToProps)(withFormWrapper<GeneralSettingsForm, AclCheckProps>(({ formik: { setFieldValue, values: { aclCheck } }, aclCheckState, deviceName, deviceSerialNumber }) => {

      const { formatMessage } = useIntl();
      const { Comp, func } = confirmModal();

      return(
        <React.Fragment>
          <VibrationWrapper>
            <Checkbox
              value={aclCheck}
              label={formatMessage({id: "aclCheckLabel", defaultMessage: "Call filter"})}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const checked = e.target.checked;
                if(checked){
                  setFieldValue("aclCheck", checked);
                }
                else{
                  func(true);
                }
              }}
            />
            &nbsp;
            <Tooltip
              popoverId="aclCheck-popover"
              labelId="aclCheckLabelInfo"
              defaultMessage="Call filter"
            >
              <InformationIcon size="medium" />
            </Tooltip>
            <Comp
              title={`${formatMessage({id: "callFilterDisableModalHeader", defaultMessage: "Confirm action"})}`}
              body={
                <p>
                  <span>{`${formatMessage({id: "callFilterDisableModalMessage1", defaultMessage: "Switching off the call filter means that everyone who knows the phone number of the device may enter a two-way call with device"})} ${deviceName} (${deviceSerialNumber}). ${formatMessage({id: "callFilterDisableModalMessage2", defaultMessage: "Are you sure you want to continue?"})}`}</span>
                </p>
              }
              confirmText={formatMessage({id: "callFilterDisableModalOk", defaultMessage: "I understand the risk, proceed"})}
              cancelText={formatMessage({id: "callFilterDisableModalCancel", defaultMessage: "Abort"})}
              cancelFunc={() => {
                setFieldValue("aclCheck", true);
              }}
              confirmFunc={() => {
                setFieldValue("aclCheck", false);
                func(false);
              }}
            />
          </VibrationWrapper>
        </React.Fragment>
      )
    }
  ))
