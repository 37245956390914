import * as React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { initialize, addDevice, getTwin, checkTwinSerialNumber } from "./Store/routines";
import Step, { ButtonWrapper, LoaderWrap, NextButton } from "./Step";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import {Option, TreeDropdownOption} from "../../Elements/Dropdown/types";

import init from "../Initialization";
import { Customer, TwinDevice, CheckedTwin } from "./Store/types";
import { Grid, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import Plus from "../../Elements/Icon/Icons/Safemate/solid/PlusCircle";
import SafemateHeader from "../Header";
import { Checkbox } from "../../Elements/CheckboxFormik";
import UnsavedCheck from "../SaveNeeded/unsavedModal";
import { AppState } from "@Safemate/Store/types";
import { useParams } from "react-router";
import { parseUrlParams } from "@Safemate/utils";
import Loader from "@Elements/Loader";
import { CustomerWithChildren } from "@Safemate/Model/Customer";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { mapCustomersForUser } from "@Safemate/UserAdmin/utils";
import TreeDropdown from "@Elements/Dropdown/Tree";

const StyledInput = styled.input`
  && {
    width: 100%;
    height: 60px;
    font-size: 24px;
  }
`;

const SerialNumberInput = styled(StyledInput)`
  text-transform: uppercase;
  font-weight: bold;
  @media (min-width: 500px){
    letter-spacing: 16px;
    font-size: 24px;
  }
`;

const DropdownWrap = styled.div`
  margin-bottom: 15px;
  font-size: 24px;
  ul *{
    padding-top: 1px!important;
    padding-bottom: 1px!important;
  }
`;


interface AddSafemateProps{
  customerHierarchy: CustomerWithChildren;
  twinDevice: TwinDevice;
  processing: boolean;
  checkedTwin: CheckedTwin;
  addDevice: Routine<ActionFunctionAny<Action<any>>>;
  getTwin: Routine<ActionFunctionAny<Action<any>>>;
  checkTwinSerialNumber: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean
}

const mapStateToProps = ({appData: { isPrivate, customerHierarchy }, addSafemate: { initialization: { initialized, processing }, device: { twinDevice, checkedTwin }}}: AppState) => {
  return {
    customerHierarchy,
    initialized,
    processing,
    twinDevice,
    checkedTwin,
    isPrivate
  }
}

const mapDispatchToState = {
  initialize,
  getTwin,
  addDevice,
  checkTwinSerialNumber
}

const SERIAL_NUMBER_LENGTH = 8;

interface Params{
  params?: string;
}

export default connect(mapStateToProps, mapDispatchToState)(
  init<AddSafemateProps>(({customerHierarchy, processing, addDevice, twinDevice, checkedTwin, getTwin, isPrivate, checkTwinSerialNumber}) => {

    const { params } = useParams<Params>();

    let twin = 0;

    if(params){
      const parsedParams = parseUrlParams(params);
      twin = parsedParams.twin;
    }

    const isTwin = !!twin;

    const [loop, setLoop] = React.useState(false);
    const [twinSerialToCheck, setTwinSerialToCheck] = React.useState("");

    const serialRef = React.useRef<HTMLInputElement>(null);
    const nameRef = React.useRef<HTMLInputElement>(null);

    const [mappedCustomers, setMappedCustomers] = React.useState([] as TreeDropdownOption[]);
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>

    React.useEffect(() => {
      if (customerHierarchy) {
        setMappedCustomers(mapCustomersForUser([customerHierarchy.customer.custId], customerHierarchy, permissions.get(PolicyNameEnum.ADD_DEVICE_MENU).customerPermissions, ["all"], {singleSelect: true, includeTop: true}));
      }
    }, [customerHierarchy ])

    const { formatMessage } = useIntl();

    React.useEffect(() => {
      if(isTwin){
        getTwin(twin);
      }
    }, [])


    React.useEffect(() => {
      if(checkedTwin.match && checkedTwin.serialNumber === twinSerialToCheck){
        if(!processing){
          addDevice({
            values: {
              serialNumber: checkedTwin.serialNumber,
              unitName: `${twinDevice.deviceName} - Twin`,
              firmId: twinDevice.ownedByCustId,
              twin
            },
            callback: () => {
              location.hash = "safemates";
            },
            device: formatMessage({ id: "deviceAdded", defaultMessage: "ble lagt til" })
          });
        }
      }
    }, [checkedTwin.match, checkedTwin.serialNumber, checkedTwin.twinCheckUUID])


    return (
      <Formik
        onSubmit={(values, {resetForm, setValues}) => {
          addDevice({
            values: {
              ...values,
              firmId: isTwin ? twinDevice.ownedByCustId : values.firmId,
              unitName: isTwin ? `${twinDevice.deviceName} - Twin` : values.unitName,
              twin
            },
            callback: () => {
              if(loop){
                resetForm({
                  values: {
                    serialNumber: "",
                    unitName: "",
                    firmId: values.firmId
                  }
                });
                
              }
              else{
                resetForm();
                location.hash = "safemates";
              }
            },
            deviceCSID: formatMessage({ id: "deviceAddedCSID", defaultMessage: "ble lagt til med CSID" }),
            device: formatMessage({ id: "deviceAdded", defaultMessage: "ble lagt til" })
          })
        }}
        initialValues={{serialNumber: "", unitName: "", firmId: customerHierarchy.customer.custId}}
      >
        {({setFieldValue, values, dirty}) => 
        <Form>
          <Grid fluid>
            <Row>
              <Col lg={12}>
                <SafemateHeader
                  icon={<Plus size="large" inline/>}
                  title={
                    isTwin
                      ? `${formatMessage({id: "addTwinFor", defaultMessage: "Legg til tvilling av"})} ${twinDevice.deviceSNNum}`
                      : formatMessage({id: "addSafemate", defaultMessage: "Legg til en Safemate i"})
                  }
                />
              </Col>
            </Row>
            <Row>
              <Step
                title={formatMessage({id: "addSafeHeader1", defaultMessage: "Serienummer"})}
                desc={formatMessage({id: "addSafeInfo1", defaultMessage: "Legg til serienummer"})}
                twin={isTwin}
              >
                <>
                  <SerialNumberInput 
                    ref={serialRef}
                    onChange={(event) => {
                      const { value } = event.currentTarget;
                      if(value.length <= SERIAL_NUMBER_LENGTH) 
                        setFieldValue("serialNumber", value);
                    }} 
                    onBlur={(event) => {
                      if(isTwin){
                        setTwinSerialToCheck(values.serialNumber);
                        if(values.serialNumber.length === 8){
                          checkTwinSerialNumber({
                            serialNumber: values.serialNumber,
                            twinDeviId: twin
                          })
                        }
                      }
                    }}
                    value={values.serialNumber}
                    placeholder="NAAA1234" 
                    type="text"
                  />
  
                  {isTwin &&
                    <ButtonWrapper>
                      {processing ? <LoaderWrap><Loader/></LoaderWrap> : <NextButton type="submit">{formatMessage({id: "add", defaultMessage: "Add"})}</NextButton>}
                  </ButtonWrapper>}
                </>
              </Step>
              {!isTwin &&
              <Step
                title={formatMessage({id: "addSafeHeader2", defaultMessage: "Navn"})}
                desc={formatMessage({id: "addSafeInfo2", defaultMessage: "Velg navn du ønsker å gi enheten"})}
              >
                <>
                <StyledInput 
                  ref={nameRef}
                  onChange={(event) => setFieldValue("unitName", event.currentTarget.value)}
                  value={values.unitName} 
                  placeholder={formatMessage({id: "unitName", defaultMessage: "Enhetsnavn"})}
                  type="text"
                />
                {isPrivate &&
                    <ButtonWrapper>
                    {processing ? <LoaderWrap><Loader/></LoaderWrap> : <NextButton type="submit">{formatMessage({id: "add", defaultMessage: "Add"})}</NextButton>}
                  </ButtonWrapper>}
                </>
              </Step>}
              {!isPrivate && !isTwin &&
              <Step
                title={formatMessage({id: "addSafeHeader3", defaultMessage: "Tilhørighet"})}
                desc={formatMessage({id: "addSafeInfo3", defaultMessage: "Velg underavdeling enheten skal tilhøre"})}
              >
                <>
                <div>
                  <div style={{paddingBottom: "10px"}}>
                  <TreeDropdown
                      onChange={(values) => {
                        if(values.length > 0){
                          setFieldValue("firmId", parseInt(values[0]));
                        }
                      }}
                      options={mappedCustomers}
                    />
                  </div>
                  <Checkbox
                    value={loop}
                    id={"loop"}
                    label={formatMessage({id: "addSafeLoopInfo", defaultMessage: "Legg til flere Safemate etter denne"})}
                    onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                      const value = event.currentTarget.checked;
                      setLoop(value);
                    }}
                  />
                </div>
                <ButtonWrapper>
                  {processing ? <LoaderWrap><Loader/></LoaderWrap> : <NextButton type="submit">{formatMessage({id: "add", defaultMessage: "Add"})}</NextButton>}
                </ButtonWrapper>
                </>
              </Step>}
            </Row>
          </Grid>
          <UnsavedCheck dirtyOverride={() => {
            if(isTwin){
              return false;
            }
            else{
              return dirty;
            }
          }}/>
        </Form>}
      </Formik>
    ) 
  })
)

enum Steps{
  SERIAL_NUMBER = 1,
  NAME = 2,
  BELONGING = 3
}
