import { IndoorLocationSettings } from "@Safemate/DefaultSettings/Settings/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import {Form, Formik} from "formik";
import {Frame} from "@Safemate/styles";
import {Col, Row} from "react-bootstrap";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import {AppState} from "@Safemate/Store/types";
import {saveIndoorLocationSettings,} from "@Safemate/DefaultSettings/Store/routines";
import {connect} from "react-redux";
import {DefaultIndoorLocations} from "@Safemate/DefaultSettings/Settings/IndoorLocation";
import {FooterComp, FooterContext} from "@Safemate/DefaultSettings/Settings/footer";

interface IndoorLocationSettingsProps{
  indoorLocationSettings: IndoorLocationSettings;
  saveIndoorLocationSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const IndoorLocationSettingsComp = ({ indoorLocationSettings, saveIndoorLocationSettings }: IndoorLocationSettingsProps) => {

  return(
    <Formik
      initialValues={indoorLocationSettings}
      enableReinitialize
      onSubmit={(values: IndoorLocationSettings) => {
        saveIndoorLocationSettings(values)
      }}
    >
      <Form>
        <Frame>
          <Row>
            <Col style={{paddingBottom: "1em"}}>
              <DefaultIndoorLocations />
            </Col>
            <FooterComp context={FooterContext.INDOOR_LOCATION}/>
          </Row>
        </Frame>
        <UnsavedCheck />
      </Form>
    </Formik>
  )
}

const mapStateToProps = ({ defaultSettings: {settings: { indoorLocationSettings }}}: AppState) => {
  return{
    indoorLocationSettings,
  }
};

const mapDispatchToProps = {
  saveIndoorLocationSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(IndoorLocationSettingsComp);
