export enum DeviceListActionTypes {
  FILTER_CUSTOMER = "@@DeviceList/FILTER_CUSTOMER",
  FILTER_DEVICE_TYPE = "@@DeviceList/FILTER_DEVICE_TYPE",
  FILTER_ALARM = "@@DeviceList/FILTER_ALARM",
  FILTER_SEARCH = "@@DeviceList/FILTER_SEARCH",
  TOGGLE_INFO = "@@DeviceList/TOGGLE_INFO",
  TOGGLE_MAINTAIN_FILTER = "@@DeviceList/TOGGLE_MAINTAIN_FILTER",
  FILTER_ORDER = "@@DeviceList/FILTER_ORDER",
  FILTER_USER_TAGS = "@@DeviceList/FILTER_USER_TAGS"
}
