import * as React from "react";
import { ISensorDTO, ISensorType } from "./types";
import styled from "styled-components";
import { SensorStatusEnum } from "./sensorStatus";
import { DeleteAction } from "./Actions/delete";
import { EditFunction } from "./Actions/editFunction";
import { DeviceFunctionEnum, UNKNOWN } from "./SensorFunctionEnum";
import { ProcessingRequired } from "./Actions/processingRequired";
import { DeviceType, SensorTypeEnum, specialTypeForFunction } from "./SensorTypeEnum";
import { CaptureImage } from "./Actions/captureImage";
import { InfoAction } from "./Actions/Info";

const IconsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const mapActions = (sensor: ISensorDTO) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.BASE_STATION:
    case SensorTypeEnum.WRIST_BAND:
      return getActionIcon(sensor.sensor.connected ? SensorStatusEnum.CONNECTED : SensorStatusEnum.DISCONNECTED, sensor);

    case SensorTypeEnum.SAFEMATE_HOME:
      return getActionIcon(sensor.status.icon, sensor);

    default: return null;
  }
}

const getActionIcon = (status: SensorStatusEnum | string, sensor: ISensorDTO) => {

  let displayFunctionDialog = true;
  let displayCaptureImageDialog = false;

  if(sensor.sensor.type === SensorTypeEnum.SAFEMATE_HOME){
    displayFunctionDialog = displayFunctionSelection(sensor.type, sensor.function.code);
    displayCaptureImageDialog = displayCaptureImage(sensor.type);
  }

  switch(status){
    case SensorStatusEnum.PENDING_CREATE:
      return (
        <IconsWrap>
          <DeleteAction sensor={sensor}/>
        </IconsWrap>
      )
    case SensorStatusEnum.PENDING_UPDATE:
      return (
        <IconsWrap>
          <InfoAction sensorId={sensor.sensor.deseId}/>
          {displayFunctionDialog && <EditFunction sensor={sensor}/>}
          {displayCaptureImageDialog && <CaptureImage sensor={sensor}/>}
          <DeleteAction sensor={sensor}/>
        </IconsWrap>
      )

    case SensorStatusEnum.PROCESSING_REQUIRED:
      return (
        <IconsWrap>
          <InfoAction sensorId={sensor.sensor.deseId}/>
          <ProcessingRequired sensor={sensor}/>
          <DeleteAction sensor={sensor}/>
        </IconsWrap>
      )
    default:
      if(sensor.sensor.type === SensorTypeEnum.SAFEMATE_HOME){
        return(
        <IconsWrap>
          <InfoAction sensorId={sensor.sensor.deseId}/>
          {displayFunctionDialog && <EditFunction sensor={sensor}/>}
          {displayCaptureImageDialog && <CaptureImage sensor={sensor}/>}
          <DeleteAction sensor={sensor}/>
        </IconsWrap>
        )
      }
      else{
        return (
          <IconsWrap>
            <DeleteAction sensor={sensor}/>
          </IconsWrap>
        )
      }
  }
}

const hasMultipleSupportedFunctions = (type: ISensorType) => {

  if(!type) return false;

  // All sensor types will have unknown as a supported function, but we're not interested in displaying an unknown function.
  return type.supportedFunctions.length > 2;

}

const displayFunctionSelection = (type: ISensorType, currentFunction: string) => {

  if(!type) return false;

  let canChangeFunction = false;

  // If the sensor has a supported type that isn't a generic function or unknown and doesn't match the current function we allow the user to change function in the GUI.

  if(specialTypeForFunction.includes(type.code)){
    return true;
  }

  type.supportedFunctions.forEach(func => {
    if(func.code !== DeviceFunctionEnum.GENERIC_SENSOR_FUNCTION && func.code !== UNKNOWN && func.code !== currentFunction){
      canChangeFunction = true;
    }
  })

  return canChangeFunction;
}

const displayCaptureImage= (type: ISensorType) => {

  if(!type) return false;

  return type.code  === DeviceType.PIR_SENSOR_CAMERA;
}