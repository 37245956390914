import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import {
  micAndSpeakerOptions,
  micVolumeOptions,
  dectSpeakerOptions,
  voicePromptVolumeOptions,
  micAndSpeakerOptionsSTCB
} from "./options";
import { ToggleWrap } from "@Elements/Form/ToggleWrap";
import { ComponentWrapper, StyledForm, ButtonContainer, Footer } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import { Formik } from "formik";
import SettingsHeader from "../../header";
import { CancelButton, SaveButton } from "../../../../DefaultSettings/Settings/styled";
import { saveSoundLight } from "../../../Store/routines";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { SoundLightSettings } from "@Safemate/Settings/types";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { SettingEnum } from "@Safemate/DefaultSettings/Settings/settingEnum";
import { AppState } from "@Safemate/Store/types";

interface LightSoundSettingsProps{
  dehaId: number;
  deviId: number;
  soundLight: SoundLightSettings;
  saveSoundLight: Routine<ActionFunctionAny<Action<any>>>;
}

const LightSoundSettings = ({ dehaId, deviId, soundLight, saveSoundLight }: LightSoundSettingsProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <Formik
      onSubmit={(values) => {
        saveSoundLight({
          deviceId: deviId,
          settings: values
        })
      }}
      initialValues={soundLight}
      enableReinitialize
    >
    {({ dirty, resetForm}) => 
      <StyledForm>
        <SettingsHeader 
          title={formatMessage({id: "sound", defaultMessage: "Lyd"})}
          tooltip="soundSettingsTabToolTipText"
          tooltipTitle={{
            id: "soundSettingsTab",
            defaultMessage: "Lydinnstillinger"
          }}
        />
        <div>
          {components.has(ComponentSettingEnum.GX8_SOUND) 
            && components.get(ComponentSettingEnum.GX8_SOUND)?.map(comp => {
              if(isGx8(dehaId)){
                return comp
              }
              return <ComponentWrapper>{comp}</ComponentWrapper>
          })}
        </div>
        <Footer>
          <ButtonContainer disabled={!dirty}>
            <CancelButton disabled={!dirty} onClick={() => resetForm(soundLight)}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <SaveButton disabled={!dirty} type="submit">
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </SaveButton>
          </ButtonContainer>
        </Footer>
      </StyledForm>}
    </Formik>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: { soundLight }}}: AppState) => {
  return {
    dehaId,
    deviId,
    soundLight
  }
}

const mapDispatchToProps = {
  saveSoundLight
}

export default connect(mapStateToProps, mapDispatchToProps)(LightSoundSettings);

const VolumeLevel = DropdownWrap(
  "volumeLevel",
  {
    title: {id: "soundVolumeParagraphTitle", defaultMessage: "Høytallervolum"},
    tooltip: "soundVolumeToolTipText",
    tooltipTitle: {
      id: "soundVolumeToolTipTitle",
      defaultMessage: "Høyttalervolum"
    },
    dataOptions: {
      options: micAndSpeakerOptions,
      skipMap: true
    },
    width: "250px"
  }
)

const TwoWayMic = DropdownWrap(
  "twoWayMic",
  {
    title: {id: "soundMicVolume", defaultMessage: "Mikrofonsensitivitet"},
    tooltip: "microphoneSensitivityToolTipText",
    tooltipTitle: {
      id: "microphoneSensitivityToolTipTitle",
      defaultMessage: "Mikrofonsensitivitet"
    },
    dataOptions: {
      options: micVolumeOptions,
      skipMap: true
    },
    width: "250px"
  }
)

const VolumeLevelSTCB = DropdownWrap(
  "volumeLevel",
  {
    title: {id: "soundVolumeParagraphTitle", defaultMessage: "Høytallervolum"},
    tooltip: "soundVolumeToolTipText",
    tooltipTitle: {
      id: "soundVolumeToolTipTitle",
      defaultMessage: "Høyttalervolum"
    },
    dataOptions: {
      options: micAndSpeakerOptionsSTCB,
      skipMap: true
    },
    width: "250px"
  }
)

const TwoWayMicSTCB = DropdownWrap(
  "twoWayMic",
  {
    title: {id: "soundMicVolume", defaultMessage: "Mikrofonsensitivitet"},
    tooltip: "microphoneSensitivityToolTipText",
    tooltipTitle: {
      id: "microphoneSensitivityToolTipTitle",
      defaultMessage: "Mikrofonsensitivitet"
    },
    dataOptions: {
      options: micAndSpeakerOptionsSTCB,
      skipMap: true
    },
    width: "250px"
  }
)

const DectVolume = DropdownWrap(
  "dectVolumeLevel",
  {
    title: {id: "soundDectVolumeToolTipTitle", defaultMessage: "DECT Høyttalervolum"},
    tooltip: "soundDectVolumeToolTipText",
    tooltipTitle: {
      id: "soundDectVolumeToolTipTitle",
      defaultMessage: "DECT Høyttalervolum"
    },
    dataOptions: {
      options: dectSpeakerOptions,
      skipMap: true
    },
    width: "250px"
  }
)

const VoiceVolume = DropdownWrap(
  "promptVol",
  {
    title: {id: "voicePromptVolumeToolTipTitle", defaultMessage: "Talemeldingvolum"},
    tooltip: "voicePromptVolumeToolTipText",
    tooltipTitle: {
      id: "voicePromptVolume",
      defaultMessage: "Talemeldingvolum"
    },
    dataOptions: {
      options: voicePromptVolumeOptions,
      skipMap: true
    },
    width: "250px"
  }
  )

export const ConfirmBeep =
  CheckWrap<SoundLightSettings>(
    "confirmBeep",
    {
      label: {
        id: "soundConfirmParagraphTitle",
        defaultMessage: "Kø lyd"
      },
      contents: true,
      tooltip: "soundConfirmToolTipText",
      tooltipTitle: {id: "soundConfirmToolTipTitle", defaultMessage: "Kø lyd"}
    }
  )

export const WandererBeep =
  CheckWrap<SoundLightSettings>(
    "wandererBeep",
    {
      label: {
        id: "wandererBeep",
        defaultMessage: "Vandre pling"
      },
      contents: true,
      tooltip: "wandererBeepTooltip",
      tooltipTitle: {id: "wandererBeep", defaultMessage: "Vandre pling"}
    }
  )

/*
const WandererBeep = ToggleWrap(
  "wandererBeep",
  {
    title: {id: "wandererBeep", defaultMessage: "Vandre pling"},
    tooltip: "wandererBeepTooltip",
    tooltipTitle: {id: "wandererBeep", defaultMessage: "Vandre pling"}
  }
)
*/


export const SynchronizeSound = 
    CheckWrap<SoundLightSettings>(
      SettingEnum.SYNCHRONIZE_SOUND,
      {
        label: {
          id: "synchronizeSound",
          defaultMessage: "Synkronisere lyd mellom server og enhet"
        },
        contents: true,
        tooltip: "synchronizeSoundTooltip",
        tooltipTitle: {id: "synchronizeSound", defaultMessage: "Synkronisere lyd mellom server og enhet"}
      }
    )

export const GX8LightSoundSettingsSectionOne = () => {
  return(
    <Col md={5}>
      <ComponentWrapper>
        <VolumeLevel/>
      </ComponentWrapper>
      <ComponentWrapper>
        <TwoWayMic/>
      </ComponentWrapper>
      <ComponentWrapper>
        <DectVolume/>
      </ComponentWrapper>
      <ComponentWrapper>
        <VoiceVolume/>
      </ComponentWrapper>
      <ComponentWrapper>
        <SynchronizeSound/>
      </ComponentWrapper>
    </Col>
  )
}

export const GX8LightSoundSettingsSectionOneSTCB = () => {
  return(
    <Col md={5}>
      <ComponentWrapper>
        <VolumeLevelSTCB/>
      </ComponentWrapper>
      <ComponentWrapper>
        <TwoWayMicSTCB/>
      </ComponentWrapper>
    </Col>
  )
}

const NoPad = styled(Col)`
  padding: 0;
`;

export const GX8LightSoundSettingsSectionTwo = () => {

  return(
    <Col md={7}>
      <Row>
        <Col md={12}>
          <ComponentWrapper>
            <ConfirmBeep/>
          </ComponentWrapper>
          <ComponentWrapper>
            <WandererBeep/>
          </ComponentWrapper>
        </Col>
      </Row>
    </Col>
  )
}
