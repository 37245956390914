import _axios, {AxiosInstance} from 'axios';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getReport = async () => {
  const url: string = `/device/firm/statusreport`;
  const response = await axios.get(url);
  return response.data;
};

export const getAlarms = async () => {
  const url: string = `/device/firm/alarmreport`;
  const response = await axios.get(url);
  return response.data;
};

export const getAlarmHandleReport = async () => {
  const url: string = `/device/firm/alarmhandlereport`;
  const response = await axios.get(url);
  return response.data;
};

export const getAhpReport = async () => {
  const url: string = `/device/ahp/dashboardreport`;
  const response = await axios.get(url);
  return response.data;
}

export const getGrafanaPanels = async () => {
  const url: string = `/grafana/panels`;
  const response = await axios.get(url);
  return response.data;
}
