import * as React from "react";
import { TextLabelWithText } from "./Store/types";
import { getTextLabels } from "./Store/routines";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Table } from "../TranslateOverlay/styles";
import TextListItem from "./TextListItem";
import { DatabaseCountry } from "@Safemate/MyPage/Store/types";
import { useIntl } from "react-intl";
import { Tr, Th, Wrap, Span, Caret, StyledRow } from "./styles";
import EditModal from "../TranslateOverlay/EditModal";
import { SortTable, sortTable, SetSearch, setSearch, setSelectedLanguage, setLanguageOption, SetLanguageOption, SetSelectedLanguage } from "./Store/actions";
import { SortEnum } from "./Store/SortEnum";
import { Col, Grid, Row } from "react-bootstrap";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { IOption } from "@Elements/Dropdown/types";
import Search from "@Elements/Search";
import { Radio } from "@Elements/RadioFormik";
import { LanguageOptionEnum } from "./Store/LanguageOptionEnum";
import { AppState } from "@Safemate/Store/types";
import { WrappedLoader } from "@Elements/Loader";

interface TranslationsProps{
  filteredTextLabels: TextLabelWithText[];
  getTextLabels: Routine<ActionFunctionAny<Action<any>>>;
  countries: DatabaseCountry[];
  sortTable: SortTable;
  setSearch: SetSearch;
  setLanguageOption: SetLanguageOption;
  setSelectedLanguage: SetSelectedLanguage;
  sort: SortEnum;
  search: string;
  loading: boolean;
  selectedLanguage: string[];
  languageOption: LanguageOptionEnum;
}

const mapStateToProps = ({ translationPage: {textLabel: { filteredTextLabels, loading }, country: { countries }, filter: { sort, search, selectedLanguage, languageOption }}}: AppState) => {
  return {
    filteredTextLabels,
    countries,
    sort,
    search,
    loading,
    selectedLanguage,
    languageOption
  }
}

const mapDispatchToProps = {
  getTextLabels,
  sortTable,
  setSearch,
  setSelectedLanguage,
  setLanguageOption
}

const Translations = connect(mapStateToProps, mapDispatchToProps)(({sort, loading, search, languageOption, selectedLanguage, filteredTextLabels, setSelectedLanguage, setSearch, sortTable, setLanguageOption, countries, getTextLabels}: TranslationsProps) => {
  
  React.useEffect(() => {
    getTextLabels();
  }, [])

  const { formatMessage } = useIntl();

  const [ searchValue, setSearchValue ] = React.useState<string>(search);

  const mappedLanguages = React.useMemo(() => {
    return countries.map(country => {
      return {
        text: formatMessage({id: country.languageCode, defaultMessage: country.languageName}),
        value: country.languageCode
      }
    })
  }, [countries])

  const setSort = (key: SortEnum) => {
    sortTable(key);
  }

  const setRadio = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setLanguageOption(event.currentTarget.value);
  }


  if(loading){
    return(
      <WrappedLoader/>
    )
  }

  return(
    <React.Fragment>
      <EditModal/>
      <Grid fluid>
        <StyledRow>
          <Col lg={3} md={3} sm={3} xs={12}>
            <Search
              id="search"
              value={searchValue}
              autoComplete={"new-password"}
              placeholder={formatMessage({id: "userSearch", defaultMessage: "Search"})}
              handleChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                setSearchValue(event.currentTarget.value || "");
              }}
              onBlur={(event: React.SyntheticEvent<HTMLInputElement>) => {
                setSearch(searchValue);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if(event.key === "Enter"){
                  setSearch(searchValue)}
                }
              }
            />
          </Col>
          <Col lg={3} md={3} sm={3} xs={12}>
            <Dropdown
              multi
              initial={selectedLanguage}
              options={mappedLanguages}
              title={formatMessage({id: "languageFilter", defaultMessage: "Language filter"})}
              onChange={(option) => {
                const selected = (option as IOption<string>[]).map(item => item.value);
                setSelectedLanguage(selected);
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={12}>
            <Radio label={formatMessage({id: "all", defaultMessage: "Alle"})} onChange={setRadio} value={languageOption} id="all" radioValue={LanguageOptionEnum.ALL} name="languageAdded"/>
            <Radio label={formatMessage({id: "added", defaultMessage: "Added"})} onChange={setRadio} value={languageOption} id="added" radioValue={LanguageOptionEnum.ADDED} name="languageAdded"/>
            <Radio label={formatMessage({id: "missing", defaultMessage: "Missing"})} onChange={setRadio} value={languageOption} id="missing" radioValue={LanguageOptionEnum.MISSING} name="languageAdded"/>
          </Col>
        </StyledRow>
        <Table>
          <thead>
            <Tr header={true} exist={true}>
              <Th onClick={() => {
                setSort(sort === SortEnum.COUNT_ASC ? SortEnum.COUNT_DESC : sort === SortEnum.COUNT_DESC ? SortEnum.COUNT_ASC : SortEnum.COUNT_DESC);
              }}>
                <Span>
                  {formatMessage({id: "count", defaultMessage: "Count"})}
                  {sort === SortEnum.COUNT_ASC && <Caret up/>}
                  {sort === SortEnum.COUNT_DESC && <Caret down/>}
                </Span>
              </Th>
            <Th onClick={() => {
              setSort(sort === SortEnum.SOURCE_ASC ? SortEnum.SOURCE_DESC : sort === SortEnum.SOURCE_DESC ? SortEnum.SOURCE_ASC : SortEnum.SOURCE_DESC);
            }}>
              <Span>
                {formatMessage({id: "source", defaultMessage: "Source"})}
                {sort === SortEnum.SOURCE_ASC && <Caret up/>}
                {sort === SortEnum.SOURCE_DESC && <Caret down/>}
              </Span>
            </Th>
            <Th onClick={() => {
              setSort(sort === SortEnum.TEXT_ASC ? SortEnum.TEXT_DESC : sort === SortEnum.TEXT_DESC ? SortEnum.TEXT_ASC : SortEnum.TEXT_DESC);
            }}>
              <Span>
                {formatMessage({id: "currentText", defaultMessage: "Current text"})}
                {sort === SortEnum.TEXT_ASC && <Caret up/>}
                {sort === SortEnum.TEXT_DESC && <Caret down/>}
              </Span>
            </Th>
            <Th onClick={() => {
              setSort(sort === SortEnum.LABEL_ASC ? SortEnum.LABEL_DESC : sort === SortEnum.LABEL_DESC ? SortEnum.LABEL_ASC : SortEnum.LABEL_DESC);
            }}>
              <Span>
                {formatMessage({id: "label", defaultMessage: "Label"})}
                {sort === SortEnum.LABEL_ASC && <Caret up/>}
                {sort === SortEnum.LABEL_DESC && <Caret down/>}
              </Span>
            </Th>
              {countries.map(country => {
                return <Th>{formatMessage({id: country.languageCode, defaultMessage: country.languageName})}</Th>
              })}
            </Tr>
          </thead>
          <tbody>
            {filteredTextLabels.map(text => {
              return <TextListItem text={text}/>
            })}
          </tbody>
        </Table>
      </Grid>
    </React.Fragment>
  );
})

export default Translations;