import * as React from "react";
import Chart from "react-apexcharts";
import styled, { withTheme, ThemeProps } from "styled-components";
import { ITheme } from "../../../themes/types";
import { AlarmFilterEnum } from "@Safemate/DeviceList/AlarmFilterEnum";
import { connect } from "react-redux";
import { FilterAlarm, filterAlarm, FilterDeviceType, filterDeviceType, FilterUserTags, filterUserTags } from "@Safemate/DeviceList/Store/actions";
import { UserTags } from "@Safemate/enums/UserTags";

const Container = styled.div`
  margin: 0 auto;
  background: ${props => props.theme.colors.backgroundPrimary};
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius}; 
  padding-bottom: 1em;
  color: ${props => props.theme.colors.textPrimary};
  min-height: 325px;
  @media only screen and (max-width: 767px) {
    width: 75vw;
  }

  .apex-legend{
    min-height:200px;
  }

  .apexcharts-legend{
    overflow-x:hidden!important;
    top: 50px;
  }
  @media only screen and (max-width: 650px) {
    .apexcharts-canvas{
      margin: 0 auto;
    }
    .apexcharts-legend{
      margin-bottom: -45px;
    }

  }

  @media only screen and (max-width: 1199px) and (min-width: 992px){
    .apexcharts-legend{
      top: 70px!important;
    }
  }
  
  
  @media only screen and (min-width: 1200px) {
    .apexcharts-legend-series{
      min-width:110px;
    }
    .apexcharts-legend{
      overflow-y: scroll;
      top: 25px!important;
    }
  
  }

  svg:not(:root) {
    overflow: visible;
}

  svg:not(:root) *{
    overflow: visible;
}

  .apex-tool {
    padding: 5px;
    background: ${props => props.theme.colors.backgroundPrimary};
    border: ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.colors.borderRadius};
    color: ${props => props.theme.colors.textPrimary};
  }
`;

const Header = styled.h2`
    display: block;
    font-size: 24px;
    color: ${props => props.theme.colors.textPrimary};
    line-height: 33px;
    text-align: center;
    padding-top: 30px;
    @media only screen and (max-width: 479px) {
      padding-top: 15px;
      margin: 0 0 -5px 0!important;
    }
`;

interface DonutProps extends ThemeProps<ITheme> {
  values: any[];
  labels: string[];
  title: string;
  type: number[];
  filter: AlarmFilterEnum[]
  userTag?: UserTags[];
  heightMobile: string;
  customScale: number;
  filterAlarm: FilterAlarm;
  filterDeviceType: FilterDeviceType;
  filterUserTags: FilterUserTags;
}

const mapDispatchToProps = {
  filterAlarm,
  filterDeviceType,
  filterUserTags
}

const Donut = connect(null, mapDispatchToProps)(withTheme(({ filterAlarm, filterDeviceType, filterUserTags, values, labels, title, type, filter, userTag, theme, heightMobile }: DonutProps) => {

  const onClick = (event: any, chartContext: any, config: any) => {
    filterAlarm([filter[config.dataPointIndex]]);
    filterDeviceType([type[config.dataPointIndex]]);
    if(userTag){
      filterUserTags([userTag[config.dataPointIndex]]);
    }
    location.hash = "safemates";
  }

  const legendColor = values.map(d => theme.colors.textPrimary);

  const options = {
    plotOptions: {
      pie: {
        customScale: 1.2,
      }
    },
    chart: {
      events: {
        dataPointSelection: onClick,
        markerClick: onClick,
        legendClick: onClick
      },
      offsetX: 0,
      offsetY: 20,
      type: 'donut',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 80,
        animateGradually: {
            enabled: true,
            delay: 1500
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      },
    },
    stroke:{
      colors: [theme.colors.backgroundPrimary]
    },
    theme: {
      mode: 'light', 
      monochrome: {
          enabled: true,
          color: '#B9B9B9',
          shadeTo: 'dark',
          shadeIntensity: 0.9
      },
    },
    dataLabels: {
      enabled: false,
      foreColor: '#fff',
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: '#fff',
      opacity: 0.5
    },
    legend: {
      offsetX: -25,
      offsetY: 20,
      floating: false,
      position: "right",
      show: true,
      labels: {
          colors: legendColor,
          useSeriesColors: false
      }
    },
    tooltip: {
      custom: (stuff: any) => {
        const index = stuff.seriesIndex;
        return (
          `<div class="apex-tool">
          <span>${labels[index]}</span>
          </div>`
        );
      }
    },
    labels: labels,
    responsive: [{
      breakpoint: 650,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.6,
          }
        },
        chart: {
          offsetX: 0,
          offsetY: -35,
          height: '230px',
          events: {
            dataPointSelection: onClick,
            markerClick: onClick,
            legendClick: onClick
          },
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 80,
            animateGradually: {
              enabled: true,
              delay: 1500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          colors: [theme.colors.backgroundPrimary]
        },
        theme: {
          mode: 'light',
          monochrome: {
            enabled: true,
            color: '#B9B9B9',
            shadeTo: 'dark',
            shadeIntensity: 0.9
          },
        }, 
        dataLabels: {
          enabled: false,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.5
        },
        legend: {
          position: 'bottom',
          height: '100px',
          floating: true,
          labels: {
              colors: legendColor,
              useSeriesColors: false
          }
        },
      }
    }, {
      breakpoint: 1200,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.6,
          }
        },
        chart: {
          offsetX: -65,
          offsetY: -20,
          height: 250,
          width: '100%',
          events: {
            dataPointSelection: onClick,
            markerClick: onClick,
            legendClick: onClick
          },
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 80,
            animateGradually: {
              enabled: true,
              delay: 1500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          colors: [theme.colors.textPrimary]
        },
        theme: {
          mode: 'light',
          monochrome: {
            enabled: true,
            color: '#B9B9B9',
            shadeTo: 'dark',
            shadeIntensity: 0.9
          },
        },
        dataLabels: {
          enabled: false,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.5
        },
        legend: {
          height: 200,
          width: '100%', 
          offsetX: -95,
          offsetY: 90,
          floating: true,
          position: 'right',
          show: true,
          labels: {
              colors: legendColor,
              useSeriesColors: false
          }
        },
        tooltip: {
          custom: (stuff: any) => {
            const index = stuff.seriesIndex;
            return (
              `<div class="apex-tool">
                <span>${labels[index]}</span>
                </div>`
            );
          }
        },
        labels: labels,
      }
    }, {
      breakpoint: 992,
      options: {
        plotOptions: {
          pie: {
            customScale: 1.0,
          }
        },
        chart: {
          offsetX: 65,
          offsetY: 0,
          height: '150px',
          events: {
            dataPointSelection: onClick,
            markerClick: onClick,
            legendClick: onClick
          },
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 80,
            animateGradually: {
              enabled: true,
              delay: 1500
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        stroke: {
          colors: [theme.colors.backgroundPrimary]
        },
        theme: {
          mode: 'light',
          monochrome: {
            enabled: true,
            color: '#B9B9B9',
            shadeTo: 'dark',
            shadeIntensity: 0.9
          },
        }, 
        dataLabels: {
          enabled: false,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.5
        },
        legend: {
          position: "right",
          height:230,
          offsetX: 65,
          offsetY: 0,
          show: true,
          labels: {
              colors: legendColor,
              useSeriesColors: false
          }
        },
        tooltip: {
          custom: (stuff: any) => {
            const index = stuff.seriesIndex;
            return (
              `<div class="apex-tool">
                <span>${labels[index]}</span>
                </div>`
            );
          }
        },
        labels: labels,
      }
    }, {
      breakpoint: 991,
      options: {
        chart: {
          offsetX: 0,
          height: "210px",
          events: {
            dataPointSelection: onClick,
            markerClick: onClick,
            legendClick: onClick
          },
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 80,
            animateGradually: {
              enabled: true,
              delay: 1500          },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
            },
          },
          stroke:{
            colors: [theme.colors.backgroundPrimary]
          },
          theme: {
            mode: 'light', 
            monochrome: {
                enabled: true,
                color: '#B9B9B9',
                shadeTo: 'dark',
                shadeIntensity: 0.9
            },      
          },
            dataLabels: {
              enabled: false,
              foreColor: '#fff',
              padding: 4,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: '#fff',
              opacity: 0.5
            },
            legend: {
              position: "right",
              show: true,
              offsetX: 0,
              offsetY: 0,
              labels: {
                  colors: legendColor,
                  useSeriesColors: false
              }
          },
          tooltip: {
            custom: (stuff: any) => {
              const index = stuff.seriesIndex;
              return (
                `<div class="apex-tool">
                <span>${labels[index]}</span>
                </div>`
              );
            }
          },
          labels: labels,
            }
            
    }],
  }

  return (
    <Container id="chart">
      <Header>{title}</Header>
      <Chart height={heightMobile} options={options} type="donut" series={values} />
    </Container> 
  )
}))

export default Donut;