
export class MapWrapper{
  private map: any;
  private maps: any;
  private geocoder: any;
  private readonly setFieldValue: Function;
  private mapInitialized: boolean = false;
  private position: string = "";
  private marker: any = null;
  private infoBoxContent: string = null;
  private infoWindow: any = null;
  private indoorLocationIndex: number;
  private autoCompleteInput: any;
  private autoComplete: any;

  constructor(setFieldValue: Function, initialPosition: string, infoBoxContent: string, indoorLocationIndex: number){
    this.setFieldValue = setFieldValue;
    this.position = initialPosition;
    this.infoBoxContent = infoBoxContent;
    this.indoorLocationIndex = indoorLocationIndex;
  }

  public init = (map:any, maps: any) => {
    this.map = map;
    this.maps = maps;
    this.geocoder = new maps.Geocoder();
    this.mapInitialized = true;
    this.createMarker();
  }

  public createMarker = () =>{
    const self =this;
    const marker = new self.maps.Marker();
    marker.setMap(self.map);
    marker.setDraggable(true);
    marker.setPosition(self.getPositionFromString(self.position));
    marker.setIcon('/proffweb/images/logo-map.png');
    marker.setZIndex(0);
    this.maps.event.addListener(marker, 'dragend', (event:any) =>{
      const position = event.latLng.lat().toFixed(5) + "," + event.latLng.lng().toFixed(5);
      marker.setPosition(event.latLng);
      self.setPosition(position);
      self.marker = marker;
      self.fitBounds();
      this.geocoder.geocode(
        { location: event.latLng },
        ( results: any, status: any ) => {
          if (status === "OK") {
            if (results[0]) {
              this.setFieldValue(`indoorLocations.${self.indoorLocationIndex}.address`, results[0].formatted_address);
            }
          }
        }
      );
    });
    self.marker = marker;
    self.fitBounds();
    self.createInfoWindow();
    self.infoWindow.open({
      anchor: marker,
    });
    self.autoCompleteAddress();
  }

  public createInfoWindow = () =>{
    const self =this;
    const contentString = `<div id="content"><div id="siteNotice"></div><div id="mapInfowindowContent">${self.infoBoxContent}</div></div>`;
    const infoWindow = new self.maps.InfoWindow({
      content: contentString,
    });
    self.infoWindow = infoWindow;
  }

  private setPosition = ( position: string ) => {
    const self =this;
    this.setFieldValue(`indoorLocations.${self.indoorLocationIndex}.latLng`, position);
  }

  public getPositionFromString( position: any ){
    const latAndLng = position.split(",");
    return {
      lat: parseFloat(latAndLng[0]),
      lng: parseFloat(latAndLng[1])
    };
  }

  private fitBounds = () => {
    const self = this;
    if( self.marker != null ){
      const bounds = new self.maps.LatLngBounds();
      bounds.extend(self.marker.getPosition());
      self.map.fitBounds(bounds);
    }
  }

  private autoCompleteAddress = () => {
    const self = this;
    self.autoCompleteInput = document.getElementById(`mapAddress-${self.indoorLocationIndex}`) as HTMLInputElement;
    if(self.autoCompleteInput && self.maps.places){
      self.autoComplete = new self.maps.places.Autocomplete(self.autoCompleteInput, { types: ["address"] });
      self.autoComplete.setFields(["formatted_address", "geometry"]);
      self.autoComplete.addListener("place_changed", ()=>{
        const place = self.autoComplete.getPlace();
        if(place.geometry && place.geometry.location){
          self.setFieldValue(`indoorLocations.${self.indoorLocationIndex}.address`, place.formatted_address);
          const position = place.geometry.location.lat().toFixed(5) + "," + place.geometry.location.lng().toFixed(5);
          self.setPosition(position);
          self.marker.setPosition(place.geometry.location);
          self.fitBounds();
        }
      });
    }
  }

}
