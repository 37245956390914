import {call, put, race, select, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import * as Routines from '../routines';
import {AppAction, PostCity} from "../types";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {GlobalRoles} from "../../../GlobalRoles";
import {accessToCustomer} from "../../../Policy/rightsUtil";
import { feedbackFailure } from '../../../Feedback/reducer';
import { IFullCustomerWithPolicy } from '@Safemate/Model/Customer';


export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}


function* initialize(action: AppAction<null>){
  try {

    yield put(Routines.getCustomers());
    const {success, failure}: IRace<IFullCustomerWithPolicy[]> = yield race({
      success: take(Routines.getCustomers.SUCCESS),
      failure: take(Routines.getCustomers.FAILURE)
    });

    if(success){

      const customers = success.payload;
      const selectedFirm: IFullCustomerWithPolicy = customers.find(customer =>
        accessToCustomer(customer.policies, PolicyNameEnum.SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])) as IFullCustomerWithPolicy;
        
      // Fetch alarm help information from the top level customer which will always be loaded as the first element in the list
      yield put(Routines.initializeAlarmHelper.trigger(customers[0].customerId));
      yield put(Routines.selectCustomer.trigger(selectedFirm));
      yield put(Routines.initialize.success());
    }
    else{
      yield put(Routines.initialize.failure());
    }
  }
  catch(e){
    console.log(e)
    yield put(Routines.initialize.failure());
  }
}

function* initializeAlarmHelper(action: AppAction<number>){
  try{
    const alarmOptions: PostCity = yield call(Api.getAlarmOptions, action.payload);
    yield put(Routines.initializeAlarmHelper.success(alarmOptions));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.initializeAlarmHelper.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeAlarmHelper.TRIGGER, initializeAlarmHelper);
}

export default initializationSaga;



