import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { delayOptions } from "../options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";


export const FridgeAllowTimeOpen = 
  DropdownWrap(
    "allowedOpenTime", 
    {
      title: {id: "fridgeAllowParagraph", defaultMessage: "Tid tillatt åpen"},
      dataOptions: {
        options: delayOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      prefix: "eventConfig",
      width: "250px"
    }
  );
