import * as React from "react";
import { useIntl } from "react-intl";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { delayOptions, delayOptionsWanderer } from "./options";
import { StyledForm } from "../General/Tabs/styled";
import SettingsHeader from "../General/header";
import styled from "styled-components";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventConfigForm } from "./types";
import Toggle from "@Elements/Switch/Toggle";

export const Delay = 
  DropdownWrap(
    "delay", 
    {
      title: {id: "notBackInBedDelayToolTipTitle", defaultMessage: "Forsinkelse"},
      tooltip: "notBackInBedDelayToolTipText",
      tooltipTitle: {id: "notBackInBedDelayToolTipTitle", defaultMessage: "Forsinkelse"},
      dataOptions: {
        options: delayOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px",
      prefix: "eventConfig"
    }
  );

export const DelayWanderer = 
  DropdownWrap(
    "delay", 
    {
      title: {id: "wandererDelayTabToolTipTitle", defaultMessage: "Forsinkelse"},
      tooltip: "wandererDelayTabToolTipText",
      tooltipTitle: {id: "wandererDelayTabToolTipTitle", defaultMessage: "Forsinkelse"},
      dataOptions: {
        options: delayOptionsWanderer,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px",
      prefix: "eventConfig"
    }
  );

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
`

interface NoValidSensorsProps{
  title: string;
}

export const NoValidSensors = ({ title }: NoValidSensorsProps) => {

  const { formatMessage } = useIntl();

  return(
    <Wrapper>
      <SettingsHeader 
        title={formatMessage({id: title, defaultMessage: title})}
      />
      <p>{formatMessage({id: "noValidSensors", defaultMessage: "Det er ingen sensorer med denne funksjonen."})}</p>
    </Wrapper>
  )
}

export const Active = withFormWrapper<EventConfigForm, {}>(({ formik: { values: { eventConfig: { active } }, setFieldValue }}) => {
  return(
    <Toggle
      checked={active}
      onChange={(value: boolean) => setFieldValue("eventConfig.active", value)}
      />
  )
})