import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import withFormWrapper from "@Elements/Form/formWrapper";
import { useIntl } from "react-intl";
import { Col, Row, } from "react-bootstrap";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { alterTree, getPaymentForNew } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";
import { IFullCustomerWithPolicy } from "@Safemate/Model/Customer";
import { CustomerForm } from "./types";
import { ChildMap, createChildMap } from "../utils";
import { MappedSubOrgsForList } from "../Store/types";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

interface ParentProps{
  createForm: boolean;
  customers: IFullCustomerWithPolicy[];
  selectedCustomer: IFullCustomerWithPolicy;
  getPaymentForNew: Routine<ActionFunctionAny<Action<any>>>;
  alterTree: Routine<ActionFunctionAny<Action<any>>>;
}


const mapStateTooProps = ({ subUnits: {customer: { customers, selectedCustomer, createForm }}} : AppState) => {
  return {
    createForm,
    customers,
    selectedCustomer
  }
}

const mapDispatchToProps = {
  getPaymentForNew,
  alterTree
}

export const ParentFirm = connect(mapStateTooProps, mapDispatchToProps)(withFormWrapper<CustomerForm, ParentProps>(
  ({formik: {setFieldValue, values: { customer: {custId, parent}, customer }}, createForm, customers, selectedCustomer, alterTree, getPaymentForNew}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const { formatMessage } = useIntl();

    let parentOptions = React.useMemo(() => {
        return customers.map((customer) => {
          return {
            value: customer.customerId,
            text: customer.firm.lastName
          }
        }).filter(cust => cust);
    }, [customers, selectedCustomer, custId])

    return (
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "parentFirm", defaultMessage: "Parent Firm"})}</Label>
        </Col>
        <Col md={12}>
        <Dropdown 
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && !createForm}
          options={parentOptions}
          onChange={(option) => {
              const { value } = option as Option;
              getPaymentForNew(value);
              alterTree({
                ...customer,
                parent: value
              });
              setFieldValue("customer.parent", value);
          }}
          initial={parent}
          size={{width: "100%"}}
        />  
        </Col>
      </StyledRow>
    )
  }
))

interface MovingSuborgProps{
  customers: IFullCustomerWithPolicy[];
  customerTree: MappedSubOrgsForList;
  selectedCustomer: IFullCustomerWithPolicy;
  alterTree: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToMovingSuborgProps = ({ subUnits: {customer: { customers, customerTree, selectedCustomer }}} : AppState) => {
  return {
    customers,
    customerTree,
    selectedCustomer
  }
}

const mapDispatchToMovingProps = {
  alterTree
}

export const MovingSuborg = connect(mapStateToMovingSuborgProps, mapDispatchToMovingProps)(withFormWrapper<CustomerForm, MovingSuborgProps>(
  ({formik: {setFieldValue, values: { customer }}, customers, customerTree, alterTree, selectedCustomer}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const { formatMessage } = useIntl();

    let parentOptions = React.useMemo(() => {

      const childMap: ChildMap = {};

      customers.forEach(cust => {
        createChildMap(cust.customerId, cust, customerTree, childMap);
      })

      return customers.map((customer) => {
        if(customer.customerId === selectedCustomer.customerId) return null; 
        if(childMap[selectedCustomer.customerId] && childMap[selectedCustomer.customerId].includes(customer.customerId)) return null;
        return {
          value: customer.customerId,
          text: customer.firm.lastName
        }
      }).filter(cust => cust);
    }, [customers, selectedCustomer])

    return (
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "parentFirm", defaultMessage: "Parent Firm"})}</Label>
        </Col>
        <Col md={12}>
        
        <Dropdown 
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, selectedCustomer.customerId)}
          options={parentOptions}
          onChange={(option) => {
              const { value } = option as Option;
              alterTree({
                ...customer,
                parent: value
              });
              setFieldValue("customer.parent", value);
          }}
          initial={selectedCustomer.firm.parent}
          size={{width: "100%"}}
        />  
        </Col>
      </StyledRow>
    )
  }
))
  
