import * as React from "react";
import Modal from "react-modal";
import { connect } from 'react-redux';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { Routine } from 'redux-saga-routines';
import { ActionFunctionAny, Action } from 'redux-actions';
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import styled, { ThemeProps, withTheme, ThemeProviderProps } from 'styled-components';
import moment from 'moment';


import { getStatus, getIcon, getBatteryIcon, TwinType } from './utils';
import { DeviceStatus, StatusColor, StatusText, StatusType } from './deviceStatus';
import FeetWalk from "../../../Elements/Icon/Icons/Old/FeetWalk";
import FeetStop from "../../../Elements/Icon/Icons/Old/FeetStop";
import PhoneIcon from "@Elements/Icon/Icons/EventSetup/Phone";
import Share from "../../../Elements/Icon/Icons/Safemate/solid/Share";
import { CancelButton } from '../../DefaultSettings/Settings/styled';
import { shareAlarm, resetAlarm, updateNotes } from '../Store/Routines';
import InfoCircle from "../../../Elements/Icon/Icons/Safemate/outline/InfoCircle";
import InfoCircleWhiteNote from "../../../Elements/Icon/Icons/DeviceList/InfoCircleWhiteNew";
import InfoCircleBlackNote from "../../../Elements/Icon/Icons/DeviceList/InfoCircleBlackNew";
import SignalBars from "./signalBars";
import { supportGsmStrength } from "../../Settings/tabDefinition";


import { Timeline } from '../../Timeline/index';
import { ITheme } from "themes/types";
import { isGx8 } from '../../DeviceHardwareEnum';
import { LabelEnum } from '../LabelEnum';
import { ToggleInfo, toggleInfo } from "../Store/actions";
import Note from "../../Settings/Info/note";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {faBatteryQuarter, faExclamation} from "@fortawesome/pro-solid-svg-icons";
import { IDevice } from "@Safemate/Model/Device";
import { AppState } from "@Safemate/Store/types";
import {AlarmAnalysisState} from "@Safemate/DeviceList/Store/types";
import CheckCircle from "@Elements/Icon/Icons/Safemate/solid/CheckCircle";
import { NoteLight, NoteSolid } from "@Elements/Icon/Icons/FontAwesome/Note";
import { Pen } from "@Elements/Icon/Icons/FontAwesome";
import { CheckWrapper, PhoneInput, Wrapper } from "@Safemate/DeviceHeader";
import Check from "@Elements/Icon/Icons/Check";
import { updateName } from "@Safemate/EventSetupNew/Store/routines";

export const modalStyles = {
  content: {
    width: "80%",
    maxWidth: "500px",
    height: "fit-content",
    position: "unset",
    background: "",
    color: ""
  },
  overlay:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}

interface StyledModalProps extends ThemeProps<ITheme>{
  open: boolean;
  setOpen: (open: boolean) => void;
  style?: any;
}


export const StyledModal = withTheme(({children, open, setOpen, theme, style = {content: {}, overlay: {}}}: React.PropsWithChildren<StyledModalProps>) => {

  const styles = modalStyles;
  styles.content.background = theme.colors.backgroundPrimary;
  styles.content.color = theme.colors.textPrimary;
  console.log(style);
  console.log({...styles, ...style})
  Modal.setAppElement("#react");
  return(
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={{
        content: {
          ...styles.content, 
          ...style.content
        },
        overlay: {
          ...styles.overlay,
          ...style.overlay
        }
      }}
    >
      {children}
    </Modal>
  )
})

const WrapDiv = styled.div<{color?: string}>`
  display: inline;
  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
  }

  .noGsmSvg {
    height: 20px!important;
  }
  img {
    width: 30px;
    height: 30px;
  }
`

const WrapDivNote = styled.div<{color?: string}>`
  display: inline;

  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
    @media (max-width: 550px) {
      margin-left:8px;
    }
  }

  #infoCircleNote{
    width: 34px;
    height: 34px;
  }

  #infoCircleNoteWhite{
    width: 38px;
    height: 38px;
  }

  img {
    width: 30px;
    height: 30px;
  }
`

const WrapDivStatus = styled.div<{color?: string}>`
  position: relative;
  display: inline;
  @media (max-width: 420px) {
    //margin-left:-16px;
  }
  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px !important;
    height: 30px !important;
  }

`

const WrapDivText = styled.div<{color?: string}>`
  display: inline;

  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 550px) {
		display: none;
	}
`

const WrapDivSNM = styled.div<{color?: string}>`
  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 550px) {
    top: 13px;
  }
`

const MobileDiv = styled.div`
  display: block;
  font-size: 12px;
  @media (min-width: 551px) {
    display: none;
  }
`;

const VisibleDesktopDiv = styled.div`
  display: inline;
  min-width:80px;
  @media (max-width: 550px) {
    display: none;
  }
`;

const PhoneDiv = styled(WrapDiv)`
  display: flex;
  align-items: center;

  svg {
    fill: ${props => props.theme.colors.textPrimary};
  }
`;

interface MunicialityImageProps{
  customerId: number;
}

export const MunicialityImage = React.memo(({customerId}: MunicialityImageProps) => {

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      event.currentTarget.style.display = 'none'
  }

  return(
    <WrapDiv>
      <img src={`/ajax/file/municipalityimage/top/${customerId}`} onError={handleError}/>
    </WrapDiv>
  )
});

const StyledSensorWarnIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  right: -5px;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid ${props => props.theme.colors.textPrimary};
  background: ${props => props.theme.colors.backgroundPrimary};
  border-radius: 7px;
  color: ${props => props.theme.colors.textPrimary};
`;

const StyledBatteryWarnIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  right: -5px;
  width: 20px !important;
  height: 15px !important;
  border-radius: 20px;
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
`

interface StatusProps extends ThemeProviderProps<ITheme>{
  device: IDevice;
  beta: boolean;
  isAhp: boolean;
}

export const Status = React.memo(withTheme(({device, beta, isAhp, theme}: StatusProps) => {

  const { formatMessage } = useIntl();
  
  const deviceStatus: DeviceStatus = getStatus(device, beta);
  
  const colorType = isAhp && !device.escalated && device[StatusType.ALARM_WARNING] ? "nonEscalatedAlarm" : deviceStatus.color;

  const color = (theme as ITheme).deviceStatus[colorType];

  const statusActionNeeded = device.generalWarning && deviceStatus.text === StatusText.DEFAULT;

  return(
    <WrapDivStatus color={colorType} className={deviceStatus.text ? deviceStatus.text : ""}
      title={
        statusActionNeeded
          ? formatMessage({id: "deviceActionNeeded", defaultMessage: "Handling kreves"})
          : deviceStatus.text
            ? formatMessage({id: deviceStatus.text, defaultMessage: deviceStatus.text})
            : ""}>
      {
        device.generalWarning 
          ? <StyledSensorWarnIcon icon={faExclamation} />
          : device.sensorLowBattery
            ? <StyledBatteryWarnIcon icon={faBatteryQuarter} />
            : null
      }
      {React.cloneElement(deviceStatus.icon, {color: color})}
    </WrapDivStatus>
  )
}))

interface LastMessageTimeProps{
  lastMsgIn: number;
}

export const LastMessageTime = React.memo(({lastMsgIn}: LastMessageTimeProps) => {
  if(!lastMsgIn) return null;
  const { formatMessage } = useIntl();
  return(
    <WrapDiv title={formatMessage({id: LabelEnum.LAST_MESSAGE_FROM_DEVICE, defaultMessage: "Siste beskjed fra enhet"})}>
      {moment(new Date(lastMsgIn)).format("HH:mm:ss")}
    </WrapDiv>
  )
})

interface LastMessageDateProps{
  lastMsgIn: number;
}

export const LastMessagedate = React.memo(({lastMsgIn}: LastMessageDateProps) => {
  if(!lastMsgIn) return null;
  const { formatMessage } = useIntl();
  return(
    <WrapDiv title={formatMessage({id: LabelEnum.LAST_MESSAGE_FROM_DEVICE, defaultMessage: "Siste beskjed fra enhet"})}>
      {moment(new Date(lastMsgIn)).format("DD.MM.YYYY")}
    </WrapDiv>
  )
})

interface DeviceNameProps{
  name: string;
}

export const DeviceName = ({name}: DeviceNameProps) => {

  const { formatMessage } = useIntl();
  return(
    <WrapDiv style={{display: "flex", alignItems: "center"}} title={formatMessage({id: LabelEnum.UNIT_NAME, defaultMessage: "Enhetsnavn"})}>
      {name}
    </WrapDiv>
  )
}

interface SerialNumberProps{
  serialNumber: string;
}

export const SerialNumber = React.memo(({serialNumber}: SerialNumberProps) => {
  const { formatMessage } = useIntl();
  return(
    <React.Fragment>

    <WrapDivSNM title={formatMessage({id: LabelEnum.SERIAL_NUMBER, defaultMessage: "Serienr"})}>
      <MobileDiv>
        {serialNumber.substr(0,4)}
      </MobileDiv>
      <MobileDiv>
        {serialNumber.substr(4)}
      </MobileDiv>
      <VisibleDesktopDiv> 
        {serialNumber}
      </VisibleDesktopDiv>
    </WrapDivSNM>
    </React.Fragment>

  )
})

interface DeviceIconProps{
  deviceHardware: number;
  deviceTypeName: string;
}

export const DeviceIcon = React.memo(({deviceHardware, deviceTypeName}: DeviceIconProps) => {

  const { formatMessage } = useIntl();

  const deviceIcon: React.ReactNode | undefined = getIcon(deviceHardware);

  return(
    <WrapDiv className="deviceIcons" title={formatMessage({id: deviceTypeName.replace(" ", ""), defaultMessage: deviceTypeName})}>
      {deviceIcon}
    </WrapDiv>
  )
})


interface GsmIconProps{
  dehaId: number;
  gsm: number;
  isOnline: boolean;
}

export const convertScale = (value: number) => {
  if (value < 0) value = 0;
  if (value > 100) value = 100;

  if(value <= 20) return 1;
  if(value <= 40) return 2;
  if(value <= 60) return 3;
  if(value <= 80) return 4;
  if(value <= 100) return 5;
}

export const GsmIcon = React.memo(({dehaId, gsm, isOnline}: GsmIconProps) => {

  const gsmStrength = supportGsmStrength.get(dehaId);

  if(!gsmStrength || !isOnline) return null;

  const offset = gsmStrength.low === 0 ? 0 : -(gsmStrength.low);
  const scale =  100 / (gsmStrength.high + offset);
  const strength = (gsm + offset) * scale;

  return(
    <WrapDiv title={`${isOnline ? `${convertScale(strength)}/5` : 0}`}>
      {(gsm > gsmStrength.low) && isOnline && <SignalBars dehaId={dehaId} size={30} strength={gsm} />} 
    </WrapDiv>
  )
})


interface BatteryIconProps{
  battery: number;
  dehaId: number;
  charging: boolean;
}

export const BatteryIcon = React.memo(({battery, dehaId, charging}: BatteryIconProps) => {

  const { formatMessage } = useIntl();

  if(!battery) return null;

  const batteryIcon: React.ReactNode | undefined = getBatteryIcon(battery, charging);

  const title = isGx8(dehaId) ? `${formatMessage({id: "internalBattery", defaultMessage: "Internt backup batteri"})}: ${battery}%` : `${battery}%`;

  return(
    <WrapDiv title={title}>
      {batteryIcon}
    </WrapDiv>
  )
})

interface TrackingIconProps{
  tracking: boolean;
  deviceHardware: number;
}

export const TrackingIcon = React.memo(({ tracking, deviceHardware}: TrackingIconProps) => {

  // GX8 (Safemate home) does not support tracking
  if(isGx8(deviceHardware)) return null;

  const { formatMessage } = useIntl();

  return(
    <WrapDiv 
      title={
        tracking 
          ? formatMessage({id: LabelEnum.IN_TRACKING, defaultMessage: "I lokaliseringsmodus"}) 
          : formatMessage({id: LabelEnum.NOT_IN_TRACKING, defaultMessage: "Ikke i lokaliseringsmodus"})
      }
    >
      {tracking 
        ? <FeetWalk size="medium"/> 
        : <FeetStop size="medium"/>}
    </WrapDiv>
  )
})

interface PhoneProps{
  phone: string;
}

export const Phone = React.memo(({phone}: PhoneProps) => {
  const { formatMessage } = useIntl();
  return(
    <PhoneDiv title={formatMessage({id: LabelEnum.PHONE_NUMBER, defaultMessage: "Telefonnummer"})}>
      <PhoneIcon/> {phone}
    </PhoneDiv>
  )
})


const mapStateToProps = ({ deviceList: { deviceInfo: { open }, device: {devices}}  }: AppState) => {
  return{
    open,
    devices
  }
}

const mapRowInfoDispatchToProps = {
  toggleInfo,
  updateNotes
}


interface RowInfoProps extends ThemeProps<ITheme>{
  device: IDevice;
  toggleInfo: ToggleInfo;
  isPrivate: boolean;
}



const InfoRow = styled.div<{top?: boolean}>`
  padding: ${props => props.top ? " 0px 0px 10px 0px" : "10px 0"};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    margin-bottom: 0;
  }
`;

const Close = styled.span`
  float: right;
  font-size: 28px;
  min-height: 40px;
  margin-bottom: -60px;
  margin-top: -30px;
  margin-right: -15px;
  cursor: pointer;
`

export const RowInfo = React.memo(withTheme(connect(mapStateToProps, mapRowInfoDispatchToProps)(
  ({device, isPrivate, toggleInfo, theme}: RowInfoProps) => {

    const { formatMessage } = useIntl();

    if(isPrivate) return null;

    const openInfo = () => {
      toggleInfo(device.deviId);
    }
    
    return(
      <WrapDivNote onClick={openInfo} style={{cursor: "pointer"}} title={formatMessage({id: LabelEnum.MORE_INFO, defaultMessage: "Mer informasjon"})}>
        {device.hasNote && theme.colors.accentContrast == "black"
          ? <InfoCircleBlackNote 
            tabIndex="0"
            onClick={openInfo} 
            size="medium"
          /> 
          : device.hasNote && theme.colors.accentContrast == "white" ?
          <InfoCircleWhiteNote 
          tabIndex="0"
          onClick={openInfo}  
          size="medium"
        /> : 
          <InfoCircle 
            tabIndex="0"
            onClick={openInfo}  
            size="medium"
          />  }
      </WrapDivNote>
    )
  })
));

/*
  {device.hasNote && theme.colors.accentContrast == "black"
          ? <InfoCircleBlackNote 
            tabIndex="0"
            onClick={openInfo} 
            size="medium"
          /> 
          : device.hasNote && theme.colors.accentContrast == "white" ?
          <InfoCircleWhiteNote 
          tabIndex="0"
          onClick={openInfo}  
          size="medium"
        /> : 
          <InfoCircle 
            tabIndex="0"
            onClick={openInfo}  
            size="medium"
          />  }
          */

interface InfoModalProps{
  devices: IDevice[];
  open: number;
  toggleInfo: ToggleInfo;
  updateNotes: Routine<ActionFunctionAny<Action<any>>>;
}

export const InfoModal = connect(mapStateToProps, mapRowInfoDispatchToProps)(({open, devices, toggleInfo, updateNotes}: InfoModalProps) => {

  if(!open) return null;

  const device = React.useMemo(() => {
    return devices.find(device => device.deviId === open);
  }, [open])

  const { formatMessage } = useIntl();

  const closeInfo = () => {
    toggleInfo(0);
  }

  function noteSave(note:String){
    if(note === undefined || note === null || note === ""){
      const noteExists = false;
      if(device.hasNote){
        updateNotes({device, devices, noteExists});
      }
    }else if(note !== ""){
      const noteExists = true;
      if(!device.hasNote){
        updateNotes({device, devices, noteExists});
      }
    }
    toggleInfo(0);
  } 


  if(!device) return null;

  return(
    <StyledModal
      open={!!open}
      setOpen={closeInfo}
    >
      <React.Fragment>
        <Close onClick={closeInfo}>
          &times;
        </Close>
        <Note updateNote={noteSave} device={device} custId={device.ownedByCustId}/>
      </React.Fragment>
    </StyledModal>
  )
})

interface ShareAlarmProps{
  deviceId: number;
  shareAlarm: Routine<ActionFunctionAny<Action<any>>>;
  resetAlarm: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  shareAlarm,
  resetAlarm
}

export interface DeviceListTimeline{
  id: number;
  renderCallback: () => void;
  canvasProperties: {
    margin: number;
    notes: boolean;
    textSize: number;
  }
  alarmAnalysis: AlarmAnalysisState;
}

export const DeviceListTimeline = (props: DeviceListTimeline) => {
  return(
    <React.Fragment>
      <Timeline {...props}/>
      <ShareAlarm deviceId={props.id}/>
    </React.Fragment>
  )
}

const ShareAlarm = connect(null, mapDispatchToProps)(({ deviceId, shareAlarm, resetAlarm }: ShareAlarmProps) => {

  const {formatMessage} = useIntl();

  const [open, setOpen] = React.useState(false);
  const [recipients, setRecipients] = React.useState("");


  return(
    <React.Fragment>
      <ButtonWrap style={{padding: 0}}>
        <CancelButton onClick={() => setOpen(true)}>
          <ShareButtonWrap>
            <Share size="medium"/>
            {formatMessage({id: LabelEnum.SHARE, defaultMessage: "Del"})}
          </ShareButtonWrap>
        </CancelButton>
        <Button onClick={() => resetAlarm({deviceId})} bsStyle="danger">
          {formatMessage({id: LabelEnum.RESET, defaultMessage: "Reset"})}
        </Button>
      </ButtonWrap>

      <StyledModal
        open={open}
        setOpen={setOpen}
      >
        <div>
          <h2>
            {`${formatMessage({id: LabelEnum.SHARE, defaultMessage: "Del"})} ${formatMessage({id: LabelEnum.ALARM, defaultMessage: "alarm"})}`}
          </h2>
        </div>
        <div>
          <p>
            {formatMessage({id: LabelEnum.SHARE_ALARM_DESC, defaultMessage: "Du kan dele posisjon med epost-adresser og mobilnumre. Separér med komma."})}
          </p>
          <ShareInput type="text" onChange={(event) => setRecipients(event.currentTarget.value)}/>
        </div>
        <ButtonWrap>
          <CancelButton 
            onClick={() => setOpen(false)}
          >
            {formatMessage({id: LabelEnum.CANCEL, defaultMessage: "Avbryt"})}
          </CancelButton>
          <Button 
            onClick={() => {
              shareAlarm({deviceId, recipients});
              setOpen(false);
            }}
          >
            {formatMessage({id: LabelEnum.SEND, defaultMessage: "Send"})}
          </Button>
        </ButtonWrap>
      </StyledModal>
    </React.Fragment>
  )
})

const ShareInput = styled.input`
  border: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.textPrimary};
  border-radius: ${props => props.theme.colors.borderRadius};
  width: 100%;
  height: 40px;
  padding: 0 15px;
`

const ShareButtonWrap = styled.div`
  display: flex;
  color: ${props => props.theme.colors.textPrimary};
`;

export const ButtonWrap = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`

export const Twin = styled.div<{twin?: TwinType, alarm?: boolean}>`

  ${props => props.twin === TwinType.MASTER ? "margin-top: 3px;" : ""}
  ${props => props.twin === TwinType.CLONE 
    ? !props.alarm
      ? "height: calc(100% - 3px);"
      : "height: 100%;"
    : "height: 100%;"}

  background: ${props => props.theme.colors.accent};
  margin-right: 0px;
`;
