import * as React from "react";
import Tooltip from "@Elements/Tooltip";
import { ISensorDTO } from "./types";

import FridgeIcon from '@Icons/Fridge';
import NotstoodupIcon from '@Icons/Notstoodup';
import NotinbedIcon from '@Icons/Notinbed';
import MovementIcon from '@Icons/Movement';
import EpilepsyIcon from '@Icons/Epilepsysensor';
import InactiveIcon from '@Icons/Inactive';
import Custom from "../../../Elements/Icon/Icons/FontAwesome/Custom";
import { SensorTypeEnum } from "./SensorTypeEnum";
import { DeviceFunctionEnum } from "./SensorFunctionEnum";
import { FridgeAllowTimeOpen } from "../EventConfig/Fridge";
import Schedule from "../EventConfig/Schedule";
import { VoiceSatellite } from "../EventConfig/Wanderer";
import { Active, Delay, DelayWanderer } from "../EventConfig/components";

export const mapFunction = (sensor: ISensorDTO) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.SAFEMATE_HOME:
      return getFuncIcon(sensor.function ? sensor.function.icon : null);
    
    default: return null;
  }
}

const getFuncIcon = function(icon: string) {
  switch (icon) {
    case 'fridge': // fridge
      return (
        <Tooltip
          popoverId="sensor_fridge_popover"
          labelId="sensorFridge"
          defaultMessage="Kjøleskapsensor"
        >
          <FridgeIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'wanderer':
      return (
        <Tooltip
          popoverId="wanderer_alarm_popover"
          labelId="wandererAlarm"
          defaultMessage="Døralarm"
        >
          <MovementIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'nbib':
      return (
        <Tooltip
          popoverId="sensor_nbib_popover"
          labelId="sensorNotBackInBed"
          defaultMessage="Ikke tilbake i seng sensor"
        >
          <NotinbedIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'nsu':
      return (
        <Tooltip
          popoverId="sensor_nsu_popover"
          labelId="sensorNotStoodUp"
          defaultMessage="Ikke stått opp-sensor"
        >
          <NotstoodupIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'out_of_bed':
    case 'out_of_bedroom':
    case 'notinbed': // notinbed
      return (
        <Tooltip
          popoverId="sensor_not_in_bed_popover"
          labelId="sensorNotInBed"
          defaultMessage="Ikke i seng sensor"
        >
          <NotinbedIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'epilepsy': // epilepsy
      return (
        <Tooltip
          popoverId="sensor_epilepsy_popover"
          labelId="sensorEpilepsy"
          defaultMessage="Epilepsysensor"
        >
          <EpilepsyIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'inactive': // inactive
      return (
        <Tooltip
          popoverId="sensor_inactive_popover"
          labelId="sensorInactive"
          defaultMessage="Inaktivitet sensor"
        >
          <InactiveIcon color="textPrimary" size="large" />
        </Tooltip>
      );
    case 'custom':
      return (
        <Tooltip
          popoverId="custom_popover"
          labelId="sensorCustom"
          defaultMessage="Tilpasset"
        >
          <Custom color="textPrimary" medium/>
        </Tooltip>
      )
    default:
      return null;
  }
};

export const SENSOR_FUNCTION_CONFIG : Map<DeviceFunctionEnum, JSX.Element[]> = new Map<DeviceFunctionEnum, JSX.Element[]>([
  [DeviceFunctionEnum.FRIDGE_FUNCTION,
    [<Active/>, <FridgeAllowTimeOpen/>]],
  [DeviceFunctionEnum.WANDERER_FUNCTION,
    [<Active/>, <Schedule from to/>, <VoiceSatellite/>, <DelayWanderer/>]],
  [DeviceFunctionEnum.NOT_BACK_IN_BED_FUNCTION,
    [<Active/>, <Schedule from to/>, <Delay/>]],
  [DeviceFunctionEnum.OUT_OF_BEDROOM,
    [<Active/>, <Schedule from to/>, <VoiceSatellite/>]],
  [DeviceFunctionEnum.EPILEPSY_FUNCTION,
    [<Active/>]
  ]
]);