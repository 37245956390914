import {
  IButtons,
  ICheckboxes,
  IColors,
  IFont,
  IIcons,
  IInput,
  IMargins,
  IModalColors,
  IPaddings,
  ITextInput,
  ITheme,
  IDeviceStatus
} from "./types";

const colors: IColors = {
  accentContrast: "",
  backgroundTertiary: "",
  borderColor: "",
  borderRadius: "",
  invertBackgroundPrimary: "",
  statusBarBackground: "",
  svgFill: "",
  textHighlight: "",
  textPrimary: '#999',
  textSecondary: '#eee',
  textFocus: 'white',
  textClick: 'white',
  textHover: 'white',
  backgroundPrimary: '#202125',
  backgroundSecondary: '#2D2E32',
  border: '#3a3b3f',
  accent: 'orange',
  hoverAccent: '#0000ff'
};

const margins: IMargins = {
  standard: '10px',
  more: '30px'
};

const paddings: IPaddings = {
  standard: '10px',
  more: '30px'
};


const font: IFont = {
  family: 'Roboto',
  small: '12px',
  normal: '16px',
  large: '24px',
  xLarge: '32px'
};

const icons: IIcons = {
  small: '16px',
  medium: '24px',
  large: '32px'
};

const buttons: IButtons = {
  padding: {
    top: '10px',
    right: '20px',
    bottom: '10px',
    left: '20px'
  },
  margin: {
    top: '10px',
    right: '20px',
    bottom: '10px',
    left: '20px'
  },
  width: {
    small: '80px',
    medium: '120px',
    large: '180px'
  },
  height: {
    medium: '40px'
  }
};

const textInput: ITextInput = {
  border: '1px solid grey'
};


export const input: IInput = {
  width: {
    small: '75px',
    medium: '250px',
    mediumLarge: '300px',
    large: '500px'
  }
};

const checkboxes: ICheckboxes = {
  width: 24,
  height: 24,
  border: 4
};

const modalColors: IModalColors = {
  bodyBackground: "",
  headerBackground: "",
  text: ""
}

const deviceStatus: IDeviceStatus = {
  alarm: "#F80000",
  lowBattery: "#F5EECF",
  fire: "red",
  nonEscalatedAlarm: "#F5A623",
  checkmark: "#c0f090",
  geofenceWarning: "#7A7A7A",
  static: "#F9E7C0",
  clear: "#C0F090",
  fridge: "#4BC7FD",
  epilepsy: "yellow",
  
  defaultStatus: "#cacacb",
  defaultAlarm: "#F80000"
}

const common: ITheme = {
  modalColors,
  colors,
  font,
  icons,
  buttons,
  checkboxes,
  paddings,
  margins,
  textInput,
  input,
  deviceStatus
};

export default common;
