import * as React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import AddSafemate from "@Safemate/AddSafemate";
import  Devices  from "@Safemate/DeviceList";
import Dashboard from "@Safemate/Dashboard";
import Billing from "@Safemate/Billing";
import { GlobalRoles } from "@Safemate/GlobalRoles";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { accessToCustomer } from "@Safemate/Policy/rightsUtil";
import PartnerDashboard from "@Safemate/PartnerDashboard";
import  PartnerBilling  from "@Safemate/PartnerBilling";
import  UserAdmin  from "@Safemate/UserAdmin";
import Settings from "@Safemate/Settings";
import PartnerOrganization from "@Safemate/PartnerOrganization";
import SubUnits from "@Safemate/SubUnits";
import EventSetup from "@Safemate/EventSetupNew";
import TransactionLog, { MapType } from "@Safemate/map";
import MyPage from "@Safemate/MyPage";
import MyPageBilling from "@Safemate/PrivateMyBilling";
import RoleEditor from "@Safemate/RoleEditor";
import RoleManager from "@Safemate/RoleManager";
import DefaultSettings from "@Safemate/DefaultSettings";
import TranslatePage from "@Safemate/TranslationModule/TranslatePage";
import Gdpr from "@Safemate/Gdpr";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router";
import Transition from "./Transition";
import SafemateLog from "@Safemate/Log";
import {LandingHandler} from "@Safemate/Router/LandingHandler";
import EventView from "@Safemate/EventView";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import ErrorBoundary from "./ErrorBoundary";



const Fallback = ({ error, resetErrorBoundary }: {error: any, resetErrorBoundary: any}) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  const { formatMessage } = useIntl();

  const [ displayDetails, setDisplayDetails ] = React.useState(false);

  return (
    <div role="alert">
      <p>{formatMessage({id: "javascriptErrorMessage", defaultMessage: "Something went wrong, try to refresh and clear the site cache (ctrl + f5). If this doesn't help contact support."})}</p>
      <Button style={{margin: "10px 0px"}} onClick={() => {setDisplayDetails(!displayDetails)}}>{formatMessage({id: "details", defaultMessage: "Detaljer"})}</Button>
      {displayDetails && <div><span style={{ color: "red" }}>{error.message}</span></div>}
    </div>
  );
}

interface RouterProps{
  isPrivate: boolean;
  customersWithPolicy: ICustomerWithPolicy[];
  isBillingFilled: boolean;
}

const mapPropsToState = ({ appData: { isPrivate, customersWithPolicy, isBillingFilled } }: AppState) => {
  return{
    isPrivate,
    customersWithPolicy,
    isBillingFilled
  }
}

export const Router = connect(mapPropsToState)(({ isPrivate, customersWithPolicy, isBillingFilled }: RouterProps) => {
  let location = useLocation();

  if(isPrivate && !isBillingFilled){
    return(
      <Switch location={location} key={location.pathname}>
        <Route path="/">
          <Billing/>
        </Route>
      </Switch>
    )
  }
  
  return(
    <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <Transition>
              <LandingHandler/>
            </Transition>
          </Route>
          <Route path="/dashboard">
            <Transition>
              <ErrorBoundary>
                <Dashboard/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/partner_dashboard">
            <Transition>
              <ErrorBoundary>
                <PartnerDashboard/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/partner_billing">
            <Transition>
              <ErrorBoundary>
                <PartnerBilling/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/partner_organization">
            <Transition>
              <ErrorBoundary>
                <PartnerOrganization/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/safemates/:params?">
            <Transition>
              <ErrorBoundary>
                <Devices/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/add_safemate/:params?">
            <Transition>
              <ErrorBoundary>
                <AddSafemate/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/billing">
            <Transition>
              <ErrorBoundary>
                <Billing/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/users">
            <Transition>
              <ErrorBoundary>
                <UserAdmin/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/settings/:id/:dehaId" render={() => {
            return(
              <ErrorBoundary>
                <Settings/>
            </ErrorBoundary>)
            }}/>
          <Route path="/sub_units_react">
            <Transition>
              <ErrorBoundary>
                <SubUnits/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/event_setup/:id">
            <Transition>
              <ErrorBoundary>
                <EventSetup/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/mapsingle/:id">
            <Transition>
              <ErrorBoundary>
                <TransactionLog mapType={MapType.SINGLE}/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/map">
            <Transition>
            <ErrorBoundary>
              <TransactionLog mapType={MapType.ALL}/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/my_page">
            <Transition>
              <ErrorBoundary>
                <MyPage/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/my_page_private">
            <Transition>
              <ErrorBoundary>
                <MyPageBilling/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/role_editor/:id?/:duplicate?">
            <Transition>
              <ErrorBoundary>
                <RoleEditor/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/role_manager">
            <Transition>
              <ErrorBoundary>
                <RoleManager/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/default_settings">
            <Transition>
              <ErrorBoundary>
                <DefaultSettings/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/user_audit_log/:id">
            <Transition>
              <ErrorBoundary>
                <SafemateLog/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/device_audit_log/:id">
            <Transition>
              <ErrorBoundary>
                <SafemateLog/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/device_call_log/:id">
            <Transition>
              <ErrorBoundary>
                <SafemateLog/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/translate">
            <Transition>
              <ErrorBoundary>
                <TranslatePage/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/eventview">
            <Transition>
              <ErrorBoundary>
                <EventView/>
              </ErrorBoundary>
            </Transition>
          </Route>
          <Route path="/gdpr">
            <Transition>
              <ErrorBoundary>
                <Gdpr/>
              </ErrorBoundary>
            </Transition>
          </Route>
        </Switch>
      </AnimatePresence>
  )
})

const hasAccess = (allowedGlobals: GlobalRoles[], customersWithPolicy: ICustomerWithPolicy[], policy?: PolicyNameEnum) => {
  
  for(let i = 0; i < customersWithPolicy.length; i++){
    if(!policy){
      if(allowedGlobals.includes(customersWithPolicy[i].policies.roleName as GlobalRoles)){
        return true;
      }
    }
    else{
      if(accessToCustomer(customersWithPolicy[i].policies, policy, allowedGlobals)){
        return true;
      }
    }
  }
  return false;
}
