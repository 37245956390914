import * as React from "react";
import Tooltip from "@Elements/Tooltip";
import * as Icons from "@Icons/FontAwesome"
import { ISensorDTO } from "./types";
import { DeviceType, DeviceTypeLabel, SensorTypeEnum } from "./SensorTypeEnum";
import AlarmWristband from "@Icons/Safemate/solid/AlarmWristband";
import UniversalSensorImage from "../../../../assets/images/sensors/UT-15SL-01.png";
import DoorContactImage from "../../../../assets/images/sensors/DC-23-R3-02.png";
import FallSensorImage from "../../../../assets/images/sensors/fall_sensor_001.png";
import PirSensorImage from "../../../../assets/images/sensors/IR-29.png";
import PanicButtonImage from "../../../../assets/images/sensors/PB-23.png";
import PullCordImage from "../../../../assets/images/sensors/PCU-3_01.png";
import SmokeSensorImage from "../../../../assets/images/sensors/SD-29.png";
import TemperatureSensorImage from "../../../../assets/images/sensors/TS-9.png";
import DectSensorImage from "../../../../assets/images/sensors/VCP-DECT-R8-03.png";
import PirCameraSensorImage from "../../../../assets/images/sensors/VST-892HD.png";
import WadcSensorImage from "../../../../assets/images/sensors/WADC-1.png";
import WaterSensorImage from "../../../../assets/images/sensors/WLS-15-01.png";
import PendantImage from "../../../../assets/images/sensors/WTRZ-01.png";

export const mapType = (sensor: ISensorDTO) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.WRIST_BAND:
    case SensorTypeEnum.BASE_STATION:
      return getIconForType(sensor.sensor.type);

    case SensorTypeEnum.SAFEMATE_HOME:
      return getIconForType(sensor.type.code)


    default: return null;
  }
}

export const getIconForType = (type: DeviceType | string) => {
  switch (type) {
    case DeviceType.DOOR_CONTACT:
      return (
        <Tooltip
          popoverId="doorContactPopover"
          labelId={DeviceTypeLabel.DOOR_CONTACT_LABEL}
          defaultMessage="Dørsensor"
        >
          <Icons.DoorContact mediumSecondary />
        </Tooltip>
      );
    case DeviceType.WADC_1:
      return (
        <Tooltip
          popoverId="doorContactPopover"
          labelId={DeviceTypeLabel.DOOR_CONTACT_LABEL}
          defaultMessage="Dørsensor"
        >
          <Icons.Wadc1 mediumSecondary />
        </Tooltip>
      );
    case DeviceType.WATER_DEVICE:
      return (
        <Tooltip
          popoverId="waterDevicePopover"
          labelId={DeviceTypeLabel.WATER_DEVICE_LABEL}
          defaultMessage="Vannsensor"
        >
          <Icons.WaterDevice mediumSecondary />
        </Tooltip>
      );
    case DeviceType.PANIC_BUTTON:
      return (
        <Tooltip
          popoverId="panicButtonPopover"
          labelId={DeviceTypeLabel.PANIC_BUTTON_LABEL}
          defaultMessage="Alarmknapp"
        >
          <Icons.PanicButton mediumSecondary />
        </Tooltip>
      );
    case DeviceType.PIR_SENSOR:
      return (
        <Tooltip
          popoverId="pirSensorPopover"
          labelId={DeviceTypeLabel.PIR_SENSOR_LABEL}
          defaultMessage="PIR-sensor"
        >
          <Icons.PIR mediumSecondary />
        </Tooltip>
      );
    case DeviceType.PIR_SENSOR_CAMERA:
      return(
        <Tooltip
          popoverId="priSensorCameraPopover"
          labelId={DeviceTypeLabel.PIR_SENSOR_CAMERA_LABEL}
          defaultMessage="Camera PIR-sensor"
        >
          <Icons.PIRCamera mediumSecondary />
        </Tooltip>
      );
    case '11':
    case DeviceType.SMOKE_DETECTOR:
      return (
        <Tooltip
          popoverId="smokeDetectorPopover"
          labelId={DeviceTypeLabel.SMOKE_DETECTOR_LABEL}
          defaultMessage="Røyksensor"
        >
          <Icons.SmokeDetector mediumSecondary />
        </Tooltip>
      );
    case DeviceType.THERMOSTAT:
      return (
        <Tooltip
          popoverId="thermostatPopover"
          labelId={DeviceTypeLabel.THERMOSTAT_LABEL}
          defaultMessage="Termostat"
        >
          <Icons.Thermostat mediumSecondary />
        </Tooltip>
      );
    case DeviceType.PENDANT:
      return (
        <Tooltip
          popoverId="pendantPopover"
          labelId={DeviceTypeLabel.PENDANT_LABEL}
          defaultMessage="Alarmsmykke"
        >
          <Icons.Pendant mediumSecondary />
        </Tooltip>
      );
    case DeviceType.PULL_CORD:
      return (
        <Tooltip
          popoverId="pullCordPopover"
          labelId={DeviceTypeLabel.PULL_CORD_LABEL}
          defaultMessage="Dra-snor enhet"
        >
          <Icons.PullCord mediumSecondary />
        </Tooltip>
      );
    case DeviceType.UNIVERSAL_SENSOR:
    case DeviceType.UNIVERSAL_SENSOR_SPT_1:
      return (
        <Tooltip
          popoverId="universalSensorPopover"
          labelId={DeviceTypeLabel.UNIVERSAL_SENSOR_LABEL}
          defaultMessage="Universalsensor"
        >
          <Icons.UniversalSensor mediumSecondary />
        </Tooltip>
      );
    case DeviceType.DECT_MOBILE:
      return (
        <Tooltip
          popoverId="mobileDectPopover"
          labelId={DeviceTypeLabel.DECT_MOBILE_LABEL}
          defaultMessage="Mobil DECT-enhet"
        >
          <Icons.DectMobile mediumSecondary />
        </Tooltip>
      );
    case DeviceType.FALL_ALARM:
      return (
        <Tooltip
          popoverId="fallAlarmPopover"
          labelId={DeviceTypeLabel.FALL_ALARM_LABEL}
          defaultMessage="Fallalarm"
        >
          <Icons.FallSensor mediumSecondary />
        </Tooltip>
      );
    case DeviceType.DECT_MOUNTED:
      return (
        <Tooltip
          popoverId="stationaryDectPopover"
          labelId={DeviceTypeLabel.DECT_MOUNTED_LABEL}
          defaultMessage="Stasjonær DECT-enhet"
        >
          <Icons.DectMounted mediumSecondary />
        </Tooltip>
      );
    case DeviceType.LIGHT_BULB:
      return (
        <Tooltip
          popoverId="lightBulbPopover"
          labelId={DeviceTypeLabel.LIGHT_BULB_LABEL}
          defaultMessage="Lyspæresensor"
        >
          <Icons.LightBulb mediumSecondary />
        </Tooltip>
      );
    case DeviceType.WRIST_BAND:
      return (
        <Tooltip
          popoverId="wristbandPopover"
          labelId={DeviceTypeLabel.WRIST_BAND_LABEL}
          defaultMessage="Armbånd"
        >
          <AlarmWristband size="large"/>
        </Tooltip>
      )
    case DeviceType.BASE_STATION:
      return (
        <Tooltip
          popoverId="baseStationPopover"
          labelId={DeviceTypeLabel.BASE_STATION_LABEL}
          defaultMessage="Basestasjon"
        >
          <Icons.ChargingStation mediumSecondary/>
        </Tooltip>
      )
    case '0':
      return (
        <Tooltip
        popoverId="unknownPopover"
        labelId={DeviceTypeLabel.UNKNOWN_LABEL}
        defaultMessage="Ukjent"
        >
          <Icons.Unknown mediumSecondary />
        </Tooltip>
      );
    default:
      return (
        <Tooltip
        popoverId="unsupportedPopover"
        labelId={DeviceTypeLabel.UNSUPPORTED_LABEL}
        defaultMessage="Støttes ikke"
        >
          <Icons.Triangle mediumSecondary />
        </Tooltip>
      );
  }
};

export const getImageForType = (type: DeviceType | string) => {
  switch (type) {
    case DeviceType.DOOR_CONTACT:
      return (
        <img src={DoorContactImage}/>
      );
    case DeviceType.WADC_1:
      return (
        <img src={WadcSensorImage}/>
      );
    case DeviceType.WATER_DEVICE:
      return (
        
        <img src={WaterSensorImage}/>
      );
    case DeviceType.PANIC_BUTTON:
      return (
        <img src={PanicButtonImage}/>
      );
    case DeviceType.PIR_SENSOR:
      return (
        <img src={PirSensorImage}/>
      );
    case DeviceType.PIR_SENSOR_CAMERA:
      return(
        <img src={PirCameraSensorImage}/>
      );
    case '11':
    case DeviceType.SMOKE_DETECTOR:
      return (
        <img src={SmokeSensorImage}/>
      );
    case DeviceType.THERMOSTAT:
      return (
        <img src={TemperatureSensorImage}/>
      );
    case DeviceType.PENDANT:
      return (
        <img src={PendantImage}/>
      );
    case DeviceType.PULL_CORD:
      return (
        <img src={PullCordImage}/>
      );
    case DeviceType.UNIVERSAL_SENSOR:
    case DeviceType.UNIVERSAL_SENSOR_SPT_1:
      return (
        <img src={UniversalSensorImage}/>
      );
    case DeviceType.DECT_MOBILE:
      return (
        <img src={DectSensorImage}/>
      );
    case DeviceType.FALL_ALARM:
      return (
        <img src={FallSensorImage}/>
      );
    case DeviceType.DECT_MOUNTED:
      return (
        <img src={DectSensorImage}/>
      );
    case DeviceType.WRIST_BAND:
      return (
        <Tooltip
          popoverId="wristbandPopover"
          labelId={DeviceTypeLabel.WRIST_BAND_LABEL}
          defaultMessage="Armbånd"
        >
          <AlarmWristband size="large"/>
        </Tooltip>
      )
    case DeviceType.BASE_STATION:
      return (
        <Tooltip
          popoverId="baseStationPopover"
          labelId={DeviceTypeLabel.BASE_STATION_LABEL}
          defaultMessage="Basestasjon"
        >
          <Icons.ChargingStation mediumSecondary/>
        </Tooltip>
      )
    case '24':
    default:
      return (
        null
      );
  }
};
