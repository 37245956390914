import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";
import { Geofence } from '../Geofence/types';
import { AppAction } from '@Safemate/Store/types';

// Device
export const syncDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SYNC_DEVICE'
);
export const getDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_DEVICE'
);
export const smsReboot: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SMS_REBOOT'
);
export const smsReset: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SMS_RESET'
);
export const factoryReset: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FACTORY_RESET'
);

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/INITIALIZE'
);

export const initializeUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/INITIALIZE_USER'
);

// Sound and light
export const fetchSoundLight: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SOUND_LIGHT'
);
export const saveSoundLight: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_SOUND_LIGHT'
);

// Sensor
export const fetchSensors: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SENSOR'
);
export const updateSensors: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_SENSORS'
)
export const saveSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_SENSOR'
);
export const syncSensors: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SYNC_SENSORS'
);
export const connectBaseStation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/CONNECT_BASE_STATION'
);
export const deleteSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_SENSOR'
);
export const fetchUnsavedSensors: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_UNSAVED_SENSORS'
);
export const addSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_SENSOR'
);
export const addScannedSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_SCANNED_SENSOR'
);
export const editSensorName: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/EDIT_SENSOR_NAME'
);
export const editSensorFunction: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/EDIT_SENSOR_FUNCTION'
);
export const editSensorType: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/EDIT_SENSOR_TYPE'
);
export const processSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/PROCESS_SENSOR'
);
export const startLocalLearning: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/START_LOCAL_LEARNING'
);
export const stopLocalLearning: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/STOP_LOCAL_LEARNING'
);
export const addLocalLearning: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_LEARN_SENSOR'
);
export const addLearnFamilySensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_LEARN_FAMILY_SENSOR'
);
export const getFunctionsForType: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_FUNCTIONS_FOR_TYPE'
); 
export const saveEventConfig: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_EVENT_CONFIG'
)
export const getTransactionsForSensor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_TRANSACTIONS_FOR_SENSOR'
)

// Organisation
export const fetchOrganisation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_ORGANISATION'
);
export const saveOrganisation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_ORGANISATION'
);

// Geofence
export const fetchGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_GEOFENCE'
);
export const fetchGlobalGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_GLOBAL_GEOFENCE'
);
export const deleteGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_GEOFENCE'
);
export const deleteSharedGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_SHARED_GEOFENCE'
);
export const saveGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_GEOFENCE'
);
export const saveGlobalSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_GLOBAL_SETTINGS'
);
export const getFenceDistance: Routine<ActionFunctionAny<AppAction<Geofence>>> = createRoutine(
  '@@Settings/Geofence/GET_FENCE_DISTANCE'
);
export const getLatestPosition: Routine<ActionFunctionAny<AppAction<number>>> = createRoutine(
  '@@Settings/Geofence/GET_LATEST_POSITION'
);
export const getGeofenceIndoorLocations: Routine<ActionFunctionAny<AppAction<number>>> = createRoutine(
  '@@Settings/Geofence/GET_INDOOR_LOCATIONS'
);

export const getCustomersGeofence: Routine<ActionFunctionAny<AppAction<number>>> = createRoutine(
  '@@Settings/Geofence/GET_GEOFENCE_CUSTOMERS'
);

// Tracking
export const fetchTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_TRACKING'
);
export const saveTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_TRACKING'
);
export const updateAutoTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_AUTO_TRACKING'
);
export const updateAcceptLBS: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_ACCEPT_LBS'
);
export const updatePowerSaving: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_POWER_SAVING'
);
export const updateDevicePositioning : Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_DEVICE_POSITIONING'
);
export const startTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/START_TRACKING'
);
export const stopTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/STOP_TRACKING'
);
export const getPosition: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_POSITION'
);
export const findMe: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FIND_ME'
);


// General
export const fetchGeneral: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_GENERAL'
);
export const switchSafemate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SWITCH_SAFEMATE'
);
export const resetSafemate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/RESET_SAFEMATE'
);
export const forgetSafemate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FORGET_SAFEMATE'
);
export const clearWifiCache: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/CLEAR_WIFI_CACHE'
);
export const deleteSafemate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_SAFEMATE'
);
export const saveAlarmSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_ALARM_SETTINGS'
);
export const saveManDownSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_MAN_DOWN_SETTINGS'
);
export const saveHeartRateBloodPressureSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVED_HEART_RATE_BLOOD_PRESSURE_SETTINGS'
);
export const requestHeartRate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/REQUEST_HEART_RATE'
);
export const requestBloodPressure: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/REQUEST_BLOOD_PRESSURE'
);
export const getHeartRateBloodPressure: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/HEART_RATE_BLOOD_PRESSURES'
);
export const saveBattery: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_BATTERY'
);
export const savePositioning: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_POSITIONING'
);
export const saveGeneralSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_GENERAL_SETTINGS'
);
export const saveCommunication: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_COMMUNICATION'
);
export const deleteTwin: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_TWIN'
);
export const simulate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SIMULATE'
);
export const getStepData: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_STEP_DATA'
);
export const toggleDevicePedometer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/TOGGLE_DEVICE_PEDOMETER'
);

// Info
export const saveImage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_IMAGE'
);
export const removeImage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/REMOVE_IMAGE'
);
export const captureImage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/CAPTURE_IMAGE'
);
export const updateTraining: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_TRAINING'
);
export const getNote: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_NOTE'
);
export const saveNote: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_NOTE'
);
export const connectDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/CONNECT_DEVICE'
);
export const saveLastConnectionClick: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_LAST_CONNECTION_CLICK'
);
export const updateDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/UPDATE_DEVICE'
);
export const sendMessage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SEND_MESSAGE'
);
export const getMessages: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_MESSAGES'
);
export const setUserTag: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SET_USER_TAG'
);


// Indoor
export const getLearnLink: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_INDOOR_LEARN_LINK'
);

export const getDeviceMacs: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_DEVICE_MACS'
);

export const addDeviceKnownMac: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_DEVICE_KNOWN_MAC'
);

export const addDeviceKnownMacs: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/ADD_DEVICE_KNOWN_MACS'
);

export const deleteDeviceKnownMac: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_DEVICE_KNOWN_MAC'
);

export const getInitialPosition: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/INDOOR_INITIAL_POSITION'
);

export const scanWifi: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SCAN_WIFI'
);

export const scanBLE: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SCAN_BLE'
);

//Inactivity
export const fetchInactivity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_INACTIVITY'
);

export const saveInactivity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_INACTIVITY'
);

// Walktest
export const startWalktest: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/START_WALK_TEST'
);
export const stopWalktest: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/STOP_WALK_TEST'
);
export const fetchWalktest: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_WALK_TEST'
);

// Indoor Location
export const getIndoorLocationsForDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_INDOOR_LOCATIONS_DEVICE'
);

export const getIndoorLocationsForCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_INDOOR_LOCATIONS_CUSTOMER'
);

export const saveIndoorLocation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_INDOOR_LOCATION'
);

export const saveIndoorLocationName: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SAVE_INDOOR_LOCATION_NAME'
);

export const deleteIndoorLocation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/DELETE_INDOOR_LOCATION'
);

export const scanWifiIndoorLocation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SCAN_WIFI_INDOOR_LOCATION'
);

export const scanWifiIndoorLocationFailure: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/SCAN_WIFI_INDOOR_LOCATION_FAILURE'
);

export const getCustomersForIndoor: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/GET_CUSTOMERS_FOR_INDOOR'
);
