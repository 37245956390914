import { IFullCustomerWithPolicy } from "@Safemate/Model/Customer";
import { MappedSubOrgsForList } from "./Store/types";

export const createCustomerTree = (customers: IFullCustomerWithPolicy[]) => {

  const customerTree: MappedSubOrgsForList = {}

  customers.forEach(customer => {
    let children = customerTree[customer.firm.parent]
    if(children){
      children.push(customer);
    }
    else{
      children = [customer];
    }
    customerTree[customer.firm.parent] = children;
  })
  return customerTree;
}

export interface ChildMap{
  [index: number]: number[];
}

export const createChildMap = (originalId: number, customer: IFullCustomerWithPolicy, customerTree: MappedSubOrgsForList, childMap: ChildMap) => {

  let children = childMap[originalId];

  if(!children){
    children = [];
  }

  if(customerTree[customer.customerId]){
    customerTree[customer.customerId].forEach(cust => {
      children.push(cust.customerId);
      childMap[originalId] = children;
      if(customerTree[cust.customerId]){
        children.concat(createChildMap(originalId, cust, customerTree, childMap));
      }
    });
  }

  return children;
}
