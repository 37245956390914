import * as React from "react";
import { SubHeaderWrapper } from './styles';
import {Checkbox} from "../../../../Elements/CheckboxFormik";
import {TextField} from "./FormFields";
import {connect} from "formik";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {Col, Row} from "react-bootstrap";
import {LabelEnum} from "../../LabelEnum";
import { NoFlexContent } from "@Safemate/RoleEditor/WrapperStyles";
import { useIntl } from "react-intl";

interface EmailAsUsernameProps{
  ldapSelected: boolean,
  ldapOnly: boolean,
  formUsername: string,
  isPrivate: boolean,
  ssoUser: boolean
}

const EmailAsUsername = connect<EmailAsUsernameProps, UserWithCustomer>(({isPrivate, ldapSelected, ldapOnly, formUsername, ssoUser, formik: {setFieldValue, values: {user: {emailAsUsername, username, mailTo}}}}) => {
  
  const { formatMessage } = useIntl();
  
  return(
      <Row>
        {!isPrivate && <Col md={4} sm={6}>
           <SubHeaderWrapper>
            {formatMessage({id: LabelEnum.EMAIL_AS_USERNAME, defaultMessage: "Use email as username"})}
          </SubHeaderWrapper>
          <NoFlexContent>
          <Checkbox
              value={emailAsUsername}
              name={"user.emailAsUsername"}
              id={"user.emailAsUsername"}
              labelStyle={{ display: 'flex', alignItems: 'center' }}
              onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                const value = event.currentTarget.checked;
                setFieldValue("user.emailAsUsername", value);
                if(value){
                  setFieldValue("user.username", mailTo);
                } else{
                  setFieldValue("user.username", formUsername);
                }                      
              }}
            />
          </NoFlexContent>
        </Col>}
        {emailAsUsername &&
        <Col md={4} sm={6}>
          <TextField disabled={ ssoUser } header={{id: LabelEnum.EMAIL, defaultMessage: "E-Post"}} field={["user", "username"]}/>
        </Col>
        }
        {!emailAsUsername &&
       <Col md={4} sm={6}>
          <TextField disabled={ ssoUser } header={{id: LabelEnum.IDENTITY, defaultMessage: "Ident"}} field={["user", "username"]}/>
        </Col>          
        }
        {!emailAsUsername &&
        <Col md={4} sm={6}>
          <TextField header={{id: LabelEnum.EMAIL, defaultMessage: "E-Post"}} field={["user", "mailTo"]}/>
        </Col>          
        }
      </Row>
  )
});

export default EmailAsUsername;
