import {connect} from "react-redux";
import withFormWrapper from "@Elements/Form/formWrapper";
import {useIntl} from "react-intl";
import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {Button, Col, Row} from "react-bootstrap";
import {StyledField} from "@Safemate/Settings/IndoorLocation";
import {confirmModal,} from "@Safemate/Settings/IndoorLocation/utils";
import styled from "styled-components";
import {SelectOption} from "@Safemate/DefaultSettings/types";
import {LabelEnum} from "@Safemate/DefaultSettings/LabelEnum";
import {Option} from "@Elements/Dropdown/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {GeofenceTypeEnum} from "@Safemate/Settings/Geofence/GeofenceTypeEnum";
import {Map} from "@Safemate/DefaultSettings/Settings/Geofence/map/map";
import TimeToggle from "@Safemate/DefaultSettings/Settings/Geofence/controls/timeToggle";
import TimeControls from "@Safemate/DefaultSettings/Settings/Geofence/controls/timeControls";
import {GeofenceStatus} from "@Safemate/DefaultSettings/Settings/Geofence/controls/status";
import Radius from "@Safemate/DefaultSettings/Settings/Geofence/controls/radius";
import {DropdownWrapper} from "@Safemate/Settings/Geofence/Controls/share";
import {CancelButton} from "@Safemate/Settings/Geofence/Controls/save";
import {defaultFence} from "@Safemate/Settings/Geofence/utils";
import {TypeSelector} from "@Safemate/DefaultSettings/Settings/Geofence/controls/typeSelector";
import { AppState } from "@Safemate/Store/types";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import { deleteGeofence } from "@Safemate/DefaultSettings/Store/routines";
import { GeofenceSettings } from "../types";
import { Header } from "./styles";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import {AllPermissions, PolicyContext} from "@Safemate/Policy/Provider";
import {SettingToPolicyMap} from "@Safemate/DefaultSettings/Settings/tabDefinition";
import {SettingPrefixEnum} from "@Safemate/DefaultSettings/Settings/settingEnum";

const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
`

const DeleteButton = styled(Button)`
  background: #d60403;
  margin-top: 15px;
`
export const PaddedCol = styled(Col)`
  padding-bottom: 1em;
`

export const PaddedColCenter = styled(Col)`
  padding-bottom: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
`

export interface GeofencesIndexProps{
  geofencesIndex: number;
  disabled: boolean;
}

export const DefaultGeofences = withFormWrapper<GeofenceSettings, {}>(
    ({ formik: { values: { geofences } } }) => {

  const { formatMessage } = useIntl();
  const [ geofencesIndex, setGeofencesIndex ] = React.useState(0);
  const [geofenceType, setGeofenceType] = React.useState(GeofenceTypeEnum.NONE);

  const options: SelectOption[] = geofences.map((geofence, index) => {
    return { "value": geofences[index].index, "text": geofences[index].name }
  });

  return (
    <React.Fragment>
      { geofences.length > 0 && <Row>
        <Col md={4} sm={6} >
          <Dropdown
            size={{width: "100%"}}
            title={formatMessage({id: LabelEnum.GEOFENCE, defaultMessage: "Geofence"})}
            options={options}
            initial={ geofencesIndex }
            onChange={(object: Option | Option[]) => {
              const value = (object as Option).value;
              setGeofencesIndex(value);
            }}
          />
        </Col>

      </Row> }

      <DefaultGeofence 
        setGeofencesIndex={setGeofencesIndex} 
        geofencesIndex={geofencesIndex}  
        setGeofenceType={setGeofenceType} geofenceType={geofenceType}
      />

    </React.Fragment>
  )

})

interface GeofenceProps {
  custId: number;
  geofencesIndex: number;
  setGeofencesIndex: Function,
  setGeofenceType: Function;
  geofenceType: GeofenceTypeEnum;
  deleteGeofence: Routine<ActionFunctionAny<Action<any>>>;
  customerIndoorLocations: IndoorLocation[];
}


const mapStateToProps = ({ defaultSettings: {customer: { selectedCustomer: { customerId }}, settings: {customerIndoorLocations}}}: AppState) => {
  return {
    custId: customerId,
    customerIndoorLocations
  }
}

const mapDispatchToProps = {
  deleteGeofence
};

const DefaultGeofence = connect(mapStateToProps, mapDispatchToProps)(withFormWrapper<GeofenceSettings, GeofenceProps>((
  { formik: { values: { geofences }, setFieldValue}, 
  deleteGeofence, geofencesIndex, setGeofenceType, geofenceType, setGeofencesIndex, 
  customerIndoorLocations, custId}) => {

  const { formatMessage } = useIntl();
  const {Comp, func} = confirmModal();
  const [addState, setAddState] = React.useState(false);

  const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const policy: PolicyNameEnum = SettingToPolicyMap.get(SettingPrefixEnum.GEOFENCE) as PolicyNameEnum;
  const currentPermission = permissions.get(policy).customerPermissions[custId];
  const disabled = (!currentPermission.edit && !currentPermission.all ) && currentPermission.read;

  React.useEffect(() => {
    setGeofencesIndex(0);
  }, [customerIndoorLocations]);

  const mappedIndoorLocations: Option[] = React.useMemo(() => {
    let opts: Option[] = [];
    customerIndoorLocations.map((indoorLocation) => {
      if( indoorLocation.inloId > 0 ){
        opts.push({
          text: indoorLocation.name,
          value: indoorLocation.inloId
        })
      }
    })
    return opts
  }, [customerIndoorLocations]);

  const filterIndoorLocations = (value:string) => {
    const valNum = value.split(',').map(function(item) {
      return parseInt(item, 10);
    });
    setFieldValue(`geofences.${geofencesIndex}.inloIds`, valNum);
    if(addState && valNum.length > 0){
      setAddState(false);
    }
  }

  const newGeofence = () => {
    let index = geofences.length;
    const newFence = defaultFence();
    const takenNames:string[] = [];
    geofences.map((fence) => { takenNames.push(fence.name) } );

    while(takenNames.includes(newFence.name)){
      const currentCount = parseInt(newFence.name[newFence.name.length - 1], 10);
      newFence.name = `${newFence.name.slice(0, newFence.name.length - 1)}${currentCount + 1}`;
    }
    newFence.index = index;
    newFence.sharedCustId = custId;
    //newFence.vertices.push( { lat: 59.50586,lng: 9.19074 } )
    newFence.enabled = true;
    newFence.share = true;
    newFence.circle = false;
    geofences.push(newFence);
    setFieldValue(`geofences`, geofences);
    setGeofencesIndex(index);
    setGeofenceType(GeofenceTypeEnum.NONE);
  }

  const handleDelete = (geofenceId: number) => {
    deleteGeofence(geofenceId);
  }

  if( geofences[geofencesIndex] ){
    const selector: boolean = 
      (geofences[geofencesIndex].circle 
        || geofences[geofencesIndex].polygon 
        || geofences[geofencesIndex].indoor);



    return(
      <Row style={{marginTop: "1em"}}>
        { selector && <React.Fragment>
          <PaddedCol xs={12} sm={6} md={4}>
            <Header>{formatMessage({id: "geofenceName", defaultMessage: "Geofence name"})}</Header>
            <StyledField  disabled={disabled} id="geofenceNameDefault" type="text" name={`geofences.${geofencesIndex}.name`} />
          </PaddedCol>
          <PaddedCol xs={12} sm={6} md={3}>
            <GeofenceStatus disabled={disabled} geofencesIndex={geofencesIndex} />
          </PaddedCol>
          { geofences[geofencesIndex].circle &&
            <PaddedCol xs={12} sm={6} md={5}>
              <Radius disabled={disabled} geofencesIndex={geofencesIndex} />
            </PaddedCol>
          }

          { geofences[geofencesIndex].indoor &&
            <PaddedCol xs={12} sm={6} md={5}>
              <Header>{formatMessage({id: "selectIndoorLocationGeofence", defaultMessage: "Wifi location"})}</Header>
              <DropdownWrapper>
                <Dropdown
                  multi
                  options={mappedIndoorLocations}
                  onChange={option => {
                    const value = (option as Option[]).map(item => item.value);
                    filterIndoorLocations(value.toString());
                  }}
                  initial={ geofences[geofencesIndex].inloIds }
                  title={formatMessage({id: "selectIndoorLocationGeofence", defaultMessage: "Select"})}
                  disabled={disabled}
                />
              </DropdownWrapper>
            </PaddedCol>
          }
          <PaddedCol xs={12}>
            <TimeToggle disabled={disabled} geofencesIndex={geofencesIndex} />
            <TimeControls disabled={disabled} geofencesIndex={geofencesIndex} />
          </PaddedCol>
          <PaddedColCenter md={12}>
            <React.Fragment>
              <CancelButton onClick={() => func(true)}  disabled={disabled}>
                {formatMessage({ id: "delete", defaultMessage: "Slette" })}
              </CancelButton>
              <Comp
                title={formatMessage({id: "deleteGeofence", defaultMessage: "Slett geofence"})}
                deleteButton={true}
                body={
                  <p>
                    {`${formatMessage({id: "deleteGeofenceDesc", defaultMessage: "Er du sikker på at du vil slette"})} ${geofences[geofencesIndex].name}?`}
                  </p>
                }
                confirmText={formatMessage({id: "delete", defaultMessage: "Slett"})}
                confirmFunc={() => {
                  handleDelete(geofences[geofencesIndex].id);
                  func(false);
                }}
              />

            </React.Fragment>
          </PaddedColCenter>



        </React.Fragment> }

        { !selector &&
          <PaddedCol id={"geofenceTypeSelector"} md={12}>
            <TypeSelector  disabled={disabled} fences={geofences} setGeofenceType={setGeofenceType} geofenceType={geofenceType} geofencesIndex={geofencesIndex} />
          </PaddedCol>
        }

        <PaddedCol md={12} >
          <Map geofenceType={geofenceType}
               geofencesIndex={geofencesIndex}
               setGeofencesIndex={setGeofencesIndex}
               setAddState={setAddState}
          />
        </PaddedCol>
        {
          !addState && <PaddedColCenter md={12} >
            <SaveButton disabled={disabled} onClick={() => {newGeofence(); setAddState(true);  }}>{formatMessage({id: "addStandardSettingsGeofence", defaultMessage: "Add"})}</SaveButton>
          </PaddedColCenter>
        }


      </Row>
    )

  } else {
    return (
      <Row>
        <PaddedColCenter md={12} >
          <SaveButton disabled={disabled} onClick={() => {newGeofence(); setAddState(true);}}>{formatMessage({id: "addStandardSettingsGeofence", defaultMessage: "Add"})}</SaveButton>
        </PaddedColCenter>
      </Row>
    )
  }
}))
