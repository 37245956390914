import { CustomerDTO } from "@Safemate/Model/Customer";

export const mapCustomerToForm = (customer: CustomerDTO): CustomerDTO => {

  return {
    ...customer,
    custId: customer.custId,
    firstName: customer.firstName || "",
    firmName: customer.lastName || "",
    lastName: customer.lastName || "",
    email: customer.email || "",
    mobile: customer.mobile || "",
    orgNum: customer.orgNum || "",
    addrName: customer.addrName || "",
    billingAddrName: customer.billingAddrName || "",
    addrReference: customer.addrReference || "",
    addressLine1: customer.addressLine1 || "",
    addressLine2: customer.addressLine2 || "",
    addrZipCode: customer.addrZipCode || "",
    addrCity: customer.addrCity || "",
    country: customer.country || "",
    integrationId: customer.integrationId || "",
    integrationRole: customer.integrationRole || "",
    whiteListPhoneNumbers: customer.whiteListPhoneNumbers || "",
    kombitUUID: customer.kombitUUID || ""
  }
}
