import * as React from "react";
import { Formik, Form, Field } from "formik";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import SettingTab from "@Safemate/Settings/Components/settingTab";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import { InactivitySettings } from "../../types";
import { saveInactivity } from "@Safemate/Settings/Store/routines";
import { SettingEnum } from "@Safemate/Settings/settingEnum";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { inactivityOptions, warnTimeOptions } from "@Safemate/Settings/EventConfig/options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { FieldWrapper, InactivityWrapper, PeriodWrapper, StyledInput  } from "@Safemate/Settings/EventConfig/styles";
import { SettingHeader, TitleWrap } from "@Elements/Form/styles";
import InformationIcon from "@Elements/Icon/Icons/InformationIcon";

interface InactivityProps{
  dehaId: number;
  deviId: number;
  inactivity: InactivitySettings;
  saveInactivity: Routine<ActionFunctionAny<Action<any>>>;
}

const Inactivity = ({ dehaId, deviId, inactivity, saveInactivity }: InactivityProps) => {

  console.log(inactivity);

  return (
    <SettingTab
      dehaId={dehaId}
      deviId={deviId}
      settings={inactivity}
      save={saveInactivity}
      settingEnum={SettingEnum.INACTIVITY_EVENT}
      header={{
        title: "noActivityAlarm",
        tooltip: "inactivitySettingsTabToolTipText",
        tooltipTitle: "noActivityAlarm",
        toggle: true,
        toggleValue: ["inactivityFunction"]
      }}
    />
  )
}


/*
 <SettingsHeader 
          title={formatMessage({id: "noActivityAlarm", defaultMessage: "noActivityAlarm"})}
          tooltip="inactivitySettingsTabToolTipText"
          tooltipTitle={{
            id: "noActivityAlarm",
            defaultMessage: "noActivityAlarm"
          }}
        >
          <Toggle
            checked={inactivityFunction}
            onChange={(value: boolean) => setFieldValue("inactivity.inactivityFunction", value)}
            />
        </SettingsHeader>
        */

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {inactivity}}}: AppState) => {
  return {
    dehaId,
    deviId,
    inactivity,
  }
}

const mapDispatchToProps = {
  saveInactivity,
}

export default connect(mapStateToProps, mapDispatchToProps)(Inactivity);


interface InactivityFieldProps{
  intervalName: string;
  periodToName: string;
  periodFromName: string;
  count: number;
}

export const InactivityField = ({intervalName, periodToName, periodFromName, count }: InactivityFieldProps) => {

  const { formatMessage } = useIntl();

  const Interval = DropdownWrap(
    intervalName, 
    {
      title: {id: "interval", defaultMessage: "Intervall"},
      tooltip: "inactivityLimitToolTipText",
      tooltipTitle: {id: "inactivityLimitToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      dataOptions: {
        options: inactivityOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND
      },
      width: "250px"
    }
  );

  return (
    <React.Fragment>
      <TitleWrap right>
        {`${formatMessage({id: "inactivitySchedule", defaultMessage: "Tidsskjema"})} ${count}`}
      </TitleWrap>
      <InactivityWrapper>
        <div>
          <Interval/>
        </div>
        <div style={{display: "flex"}}>
        <FieldWrapper>
        <SettingHeader>
          <TitleWrap right>
            {formatMessage({id: "inactivityFromParagraphTitle", defaultMessage: "Fra"})}
          </TitleWrap>
        </SettingHeader>
          <PeriodWrapper>
            <StyledInput
              type="time"
              name={periodFromName}
              step="60"
            />
          </PeriodWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <SettingHeader>
            <TitleWrap right>
              {formatMessage({id: "inactivityToParagraphTitle", defaultMessage: "Fra"})}
            </TitleWrap>
          </SettingHeader>
          <PeriodWrapper>
            <StyledInput
              type="time"
              name={periodToName}
              step="60"
              />
          </PeriodWrapper>
        </FieldWrapper>
          &nbsp;
          <Tooltip
            style={{alignSelf: "center"}}
            labelId="inactivityPeriodToolTipText"
            defaultMessage="Her angis tidsrommet det aksepteres at man ikke måler livstegn."
            popoverId={`${periodFromName}-popover`}
            title={formatMessage({id: "inactivityPeriodToolTipTitle", defaultMessage: "Periode"})}
          >
            <InformationIcon size="medium" />
          </Tooltip>
          </div>
      </InactivityWrapper>
    </React.Fragment>
  );
};

export const InactivityWarnTime = 
  DropdownWrap(
    "inactivityWarnTime", 
    {
      title: {id: "inactivityAlertToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      tooltip: "inactivityAlertToolTipText",
      tooltipTitle: {id: "inactivityAlertToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      dataOptions: {
        options: warnTimeOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px"
    }
  );