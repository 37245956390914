import { MessageDescriptor } from "@formatjs/intl";
import { DeviceType } from "./SensorTypeEnum";
import { ISensorFunction, ISensorType } from "./types";


export enum DeviceFunctionEnum{
  FRIDGE_FUNCTION = "31",
  WANDERER_FUNCTION = "32",
  NOT_BACK_IN_BED_FUNCTION = "33",
  NOT_STOOD_UP_FUNCTION = "34",
  EPILEPSY_FUNCTION = "35",
  GENERIC_SENSOR_FUNCTION = "generic_sensor_function",
  MEDICAL_ALARM = "medical_alarm",
  CUSTOM_SENSOR_FUNCTION = "custom_sensor_function",
  OUT_OF_BED = "36",
  OUT_OF_BEDROOM = "39"
}
// The climax hub has different codes for different out of bed related events, but we want to present these events as a single out of bed event for the user.
export const OUT_OF_BED_EVENTS = [DeviceFunctionEnum.NOT_BACK_IN_BED_FUNCTION, DeviceFunctionEnum.OUT_OF_BED, DeviceFunctionEnum.OUT_OF_BEDROOM];

export const DEFAULT_NAME = 'standardAlarm';

export const UNKNOWN = '-1';

export type GetFunctionName = (type: ISensorType, deviceFunction: ISensorFunction, translate: (msg: MessageDescriptor) => string) => string | null;

export const getFunctionName: GetFunctionName = (type, deviceFunction, translate) => {

  if(type.code === DeviceType.PIR_SENSOR && deviceFunction.code === DeviceFunctionEnum.GENERIC_SENSOR_FUNCTION){
    return translate
      ? translate({
          id: `default`,
          defaultMessage: "default"
        }).charAt(0).toLocaleUpperCase() + translate({
          id: `default`,
          defaultMessage: "default"
        }).slice(1)
      : "Default"
  }

  if(OUT_OF_BED_EVENTS.includes(deviceFunction.code as DeviceFunctionEnum)){
    return translate({id: "sensorFunction39", defaultMessage: "Out of bed"})
  }
  

  if (!deviceFunction)
    return translate
      ? translate({
          id: `sensorFunction${DEFAULT_NAME}`,
          defaultMessage: DEFAULT_NAME
        })
      : DEFAULT_NAME;

  const code = deviceFunction.code;
  switch (code) {
    case DeviceFunctionEnum.FRIDGE_FUNCTION:
    case DeviceFunctionEnum.WANDERER_FUNCTION:
    case DeviceFunctionEnum.NOT_BACK_IN_BED_FUNCTION:
    case DeviceFunctionEnum.NOT_STOOD_UP_FUNCTION:
    case DeviceFunctionEnum.EPILEPSY_FUNCTION:
    case DeviceFunctionEnum.OUT_OF_BED:
    case DeviceFunctionEnum.OUT_OF_BEDROOM:
      return translate
        ? translate({
            id: `sensorFunction${code}`,
            defaultMessage: code
          })
        : code;
    case UNKNOWN:
      return null;
    default:
      return translate
        ? translate({
            id: code,
            defaultMessage: code
          })
        : code;
  }
};