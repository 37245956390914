import _axios, {AxiosInstance, AxiosResponse} from 'axios';
import {PostalCityResult} from "../Form/types";
import { addRolesToRequest } from '@Safemate/utils';
import { CustomerDTO, ICustomer } from '@Safemate/Model/Customer';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getPaymentOptions = async (custId: number, parent: number) => {
  const url: string = `/paymentProfile/choices/${custId}/${parent}`;
  const response = await axios.get(url);
  return response.data;
}

export const getActivePaymentProfile = async (custId: number) => {
  const url: string = `/paymentProfile/active/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const deleteSubOrg = async (custId: number) => {
  const url: string = `/firm/${custId}`;
  const response = await axios.delete(url); 
  return response.data;
}

export const getCustomers = async () => {
  const url: string = `/firm/suborgunitswithdevicecountandrights`;
  const response = await axios.get(url);
  return response.data;
}

export const saveForm = async (pptyId: number, customer: CustomerDTO) => {
  const url: string = `/firm/save/${pptyId}`;
  const response = await axios.post(url, customer);
  return response.data;
}

export const getAlarmOptions = async (custId: number) => {
  const url = `/alarmHelpProvider/forcustomer/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPostCity = async (pnr: string) => {
  const url = `/firm/postcode/${pnr}`;
  const response: AxiosResponse<PostalCityResult> = await axios.get(url);
  return response.data;
}
