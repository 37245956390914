import { lighten } from 'polished';
import common from './common';
import { IColors, IModalColors, ITheme, IDeviceStatus, ITableColors, IEventSetupColors, IEventViewColors } from './types';

const colors: IColors = {
  ...common.colors,
  textPrimary: '#202125',
  textSecondary: '#2a241f',
  textFocus: 'black',
  textHover: lighten(0.5, '#353534'),
  textClick: 'white',
  svgFill: 'black',
  backgroundPrimary: '#f9f9f9',
  backgroundSecondary: 'white',
  backgroundTertiary: '#f1f1f1',
  border: '1px solid #C9C6C1',
  borderColor: '#C9C6C1',
  borderRadius: '4px',
  accent: '#B24207',
  accentContrast: 'white',
  statusBarBackground: '#ececec',
  invertBackgroundPrimary: '#2c2e32',
  buttonBorder: '#898583'
};

const deviceStatus: IDeviceStatus = {
  alarm: "#F80000",
  lowBattery: "#d2b83f",
  fire: "red",
  nonEscalatedAlarm: "#B24207",
  checkmark: "#c0f090",
  geofenceWarning: "#7A7A7A",
  static: "#F9E7C0",
  clear: "green",
  fridge: "#4BC7FD",
  epilepsy: "#D78DFF",
  
  defaultStatus: "#202125",
  defaultAlarm: "#F80000"
};

const modalColors: IModalColors = {
  headerBackground: '#202125',
  bodyBackground: '#f9f9f9',
  text: '#cacacb'
};

const tableColors: ITableColors = {
  alarm: "#ff8080",
  new: "#D4E6C7",
  notifyEvent: "#4BC7FD"
}

const eventSetupColors: IEventSetupColors = {
  family: "#D4E6C7",
  familyHover: "#EDF5E8"
}

const eventViewColors: IEventViewColors = {
  weakAddress: "#353534"
}

const safemateLight: ITheme = {
  ...common,
  colors,
  modalColors,
  deviceStatus,
  tableColors,
  eventSetupColors,
  eventViewColors
};

export default safemateLight;
