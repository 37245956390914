import * as React from "react";
import { SettingEnum } from "../../../../DefaultSettings/Settings/settingEnum";
import {
  heartBeatOpts,
  guardTimeOpts,
  bypassTimerOpts,
  sensorSensitivity,
  guardTimeSTCBOpts,
  alarmButtonSensitivity
} from "./options";
import withFormWrapper from "@Elements/Form/formWrapper";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { Col } from "react-bootstrap";
import { BorderCol, ComponentWrapper } from "../styled";
import { GeneralSettingsForm } from "../../types";
import { AclCheck } from "../generalSettings";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import SettingsHeader from "../../header";
import { useIntl } from "react-intl";
import Toggle from "@Elements/Switch/Toggle";
import { InactivityField, InactivityWarnTime } from "./inactivity";


export const GX8Heartbeat = 
  DropdownWrap(
    SettingEnum.HEARTBEAT_INTERVAL, 
    {
      title: {id: "heartbeatIntervalText", defaultMessage: "Heartbeat intervall"},
      dataOptions: {
        options: heartBeatOpts,
        offset: TIME_OFFSET.SECONDS_IN_MINUTE,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const GuardTime = 
  DropdownWrap(
    "guardTime", 
    {
      title: {id: "guardTimeText", defaultMessage: "Angrefristalarm"},
      tooltip: "generalSensorGuardToolTipText",
      tooltipTitle: {id: "generalSensorGuardToolTipTitle", defaultMessage: "Angrefristalarm"},
      dataOptions: {
        options: guardTimeOpts,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const GuardTimeSTCB =
  DropdownWrap(
    "guardTime",
    {
      title: {id: "guardTimeText", defaultMessage: "Angrefristalarm"},
      tooltip: "generalSensorGuardToolTipText",
      tooltipTitle: {id: "generalSensorGuardToolTipTitle", defaultMessage: "Angrefristalarm"},
      dataOptions: {
        options: guardTimeSTCBOpts,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const BypassTimer = 
  DropdownWrap(
    "bypassTimer", 
    {
      title: {id: "silenceWandereralarmLabel", defaultMessage: "Bedøv vandreralarm"},
      tooltip: "silenceWandereralarmText",
      tooltipTitle: {id: "silenceWandereralarmLabel", defaultMessage: "Bedøv vandreralarm"},
      dataOptions: {
        options: bypassTimerOpts,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const LocalLearning =
  CheckWrap<GeneralSettingsForm>(
    "localLearning",
    {
      label: {
        id: "activateLocalLearning",
        defaultMessage: "Aktiver Lokal innlæring"
      },
      contents: true,
      tooltip: "localLearningToolTip",
      tooltipTitle: {id: "activateLocalLearning", defaultMessage: "Aktiver Lokal innlæring"}
    }
  )

export const SensorSensitivity = 
  DropdownWrap(
    SettingEnum.SENSOR_SENSITIVITY , 
    {
      title: {id: "sensorSensitivity", defaultMessage: "Sensor sensitivity"},
      tooltip: "sensorSensitivityTooltip",
      tooltipTitle: {id: "sensorSensitivityTooltipTitle", defaultMessage: "Sensor sensitivity"},
      dataOptions: {
        options: sensorSensitivity,
        skipMap: true
      },
      width: "250px"
    }
  );

export const AlarmButtonSensitivity = 
  DropdownWrap(
    SettingEnum.ALARM_BUTTON_SENSITIVITY , 
    {
      title: {id: "alarmButtonSensitivity", defaultMessage: "WTRS-E sensitivity"},
      tooltip: "alarmButtonSensitivityTooltip",
      tooltipTitle: {id: "alarmButtonSensitivityTooltipTitle", defaultMessage: "WTRS-E sensitivity"},
      dataOptions: {
        options: alarmButtonSensitivity,
        skipMap: true,
        translate: true
      },
      width: "250px"
    }
  );

export const GeneralSection = () => {
  return(
    <Col md={5}>
      <ComponentWrapper>
      <AlarmButtonSensitivity/> 
      </ComponentWrapper>
      <ComponentWrapper>
      <SensorSensitivity/>
      </ComponentWrapper>
      <ComponentWrapper>
      <GuardTime/>
      </ComponentWrapper>
      <ComponentWrapper>
      <BypassTimer/>
      </ComponentWrapper>
      <ComponentWrapper>
      <AclCheck/>
      </ComponentWrapper>
      <ComponentWrapper>
      <LocalLearning/>
      </ComponentWrapper>
    </Col>
  )
}

export const GeneralSectionSTBC = () => {
  return(
    <Col md={5}>
      <ComponentWrapper>
      <AlarmButtonSensitivity/>
      </ComponentWrapper>
      <ComponentWrapper>
      <SensorSensitivity/>
      </ComponentWrapper>
      <ComponentWrapper>
      <GuardTimeSTCB/>
      </ComponentWrapper>
    </Col>
  )
}


export const GeneralSectionInactivity = withFormWrapper<GeneralSettingsForm, {}>(({ formik: { values: { inactivity: { inactivityFunction }}, setFieldValue } }) => {

  const { formatMessage } = useIntl();

  return(
    <BorderCol md={7}>
      <ComponentWrapper>
        <SettingsHeader 
          title={formatMessage({id: "noActivityAlarm", defaultMessage: "noActivityAlarm"})}
          tooltip="inactivitySettingsTabToolTipText"
          tooltipTitle={{
            id: "noActivityAlarm",
            defaultMessage: "noActivityAlarm"
          }}
        >
          <Toggle
            checked={inactivityFunction}
            onChange={(value: boolean) => setFieldValue("inactivity.inactivityFunction", value)}
            />
        </SettingsHeader>
      </ComponentWrapper>
      <ComponentWrapper>
        <InactivityField 
          prefix="inactivity"
          intervalName="inactivityIntervalOne" 
          periodToName="inactivityToOne" 
          periodFromName="inactivityFromOne"
          count={1}
        />
        <InactivityField 
          prefix="inactivity"
          intervalName="inactivityIntervalTwo" 
          periodToName="inactivityToTwo" 
          periodFromName="inactivityFromTwo"
          count={2}
        />
      </ComponentWrapper>
      <ComponentWrapper>
        <InactivityWarnTime/>
      </ComponentWrapper>
    </BorderCol>
  )
})