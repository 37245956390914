import { call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from '../routines';
import {
  feedbackFailureString,
  feedbackFailure,
  feedbackSuccess
} from '../../../Feedback/reducer';
import { Feedback } from '../../labels';
import { DeviceNote } from '../../types';
import { UserTags } from '@Safemate/enums/UserTags';

export type SaveImage = {
  deviceId: number;
  file: File;
};

const MAX_FILE_SIZE = 20000000;

function* getNote(action: AppAction<number>){
  try{
    const note = yield call(Api.getNote, action.payload);
    yield put(Routines.getNote.success(note));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getNote.failure());
  }
}

function* saveNote(action: AppAction<DeviceNote>){
  try{
    const note = yield call(Api.saveNote, action.payload);
    yield put(Routines.saveNote.success(note));
    if(action.payload.note.length > 0){
      yield feedbackSuccess(Feedback.SAVED_NOTE);
    }else{
      yield feedbackSuccess(Feedback.DELETED_NOTE);

    }
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveNote.failure());
  }
}

function* saveImage(action: AppAction<SaveImage>){
  try {
    const { file } = action.payload;
    if (file.size > MAX_FILE_SIZE) {
      yield feedbackFailureString("imageIsTooLarge");
      return;
    }

    yield call(Api.saveImage, action.payload);
    yield put(Routines.saveImage.success());
    yield feedbackSuccess(Feedback.USER_IMAGE_SAVED);
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.saveImage.failure());
  }
}

function* removeImage(action: AppAction<number>) {
  try {
    yield call(Api.removeImage, action.payload);
    yield put(Routines.removeImage.success());
    yield feedbackSuccess(Feedback.USER_IMAGE_REMOVED);
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.removeImage.failure());
  }
}

export type UpdateTraining = {
  deviceId: number;
  status: boolean;
};

function* updateTraining(action: AppAction<UpdateTraining>) {
  try {
    const status = yield call(Api.updateTraining, action.payload);
    yield put(Routines.updateTraining.success(status));
    yield feedbackSuccess(Feedback.SAVED_TRAINING);
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.updateTraining.failure());
  }
}

export type ConnectDevice = {
  deviId: number;
  eview: boolean;
  peaktech: boolean;
  takit: boolean;
  toplovo: boolean;
  lastConnectionClick: Date;
};
 
function* connectDevice(action: AppAction<ConnectDevice>) {
  try {
    const {deviId, eview, peaktech, takit, toplovo, lastConnectionClick} = action.payload;
    yield put(Routines.saveLastConnectionClick.success(lastConnectionClick));
    let deviceType = "";
    const deviceId = deviId;
    const roles = "";
    if(eview){
      deviceType = "eview";
      yield call(Api.init, deviId, deviceType);
    }else if(peaktech){
      deviceType = "peaktech";
      yield call(Api.init, deviId, deviceType);
    }else if(takit){
      deviceType = "takit";
      yield call(Api.init, deviId, deviceType);
    }else if(toplovo){
      deviceType = "toplovo";
      yield call(Api.init, deviId, deviceType);
    }
    const device = yield call(Api.getDevice, {deviceId, roles});
    yield put(Routines.updateDevice.success(device));
    yield feedbackSuccess(Feedback.CONNECT_NOTE);
  } catch (e) {
    yield feedbackFailure(e);
    yield put(Routines.updateTraining.failure());
    const device = yield call(Api.getDevice, {deviceId, roles});
    yield put(Routines.updateDevice.success(device));
  }
}

export interface SendMessage{
  deviId: number;
  message: string;
}

function* sendMessage(action: AppAction<SendMessage>){
  try{
    const response = yield call(Api.sendMessage, action.payload);
    yield put(Routines.sendMessage.success(response));
    yield feedbackSuccess(Feedback.SEND_MESSAGE);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.sendMessage.failure());
  }
}

function* getMessages(action: AppAction<number>){
  try{
    const response = yield call(Api.getMessages, action.payload);
    yield put(Routines.getMessages.success(response));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getMessages.failure());
  }
}

interface SetUserTag{
  deviceId: number;
  userTag: UserTags;
}

function* setUserTag(action: AppAction<SetUserTag>){
  try{
    const { deviceId, userTag } = action.payload;
    yield call(Api.setUserTag, deviceId, userTag);
    yield put(Routines.setUserTag.success());
    yield feedbackSuccess(Feedback.UPDATE_USER_TAG);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.setUserTag.failure());
  }
}

function* infoSaga(){
    yield takeLatest(Routines.saveImage.TRIGGER, saveImage);
    yield takeLatest(Routines.removeImage.TRIGGER, removeImage);
    yield takeLatest(Routines.updateTraining.TRIGGER, updateTraining);
    yield takeLatest(Routines.getNote.TRIGGER, getNote);
    yield takeLatest(Routines.saveNote.TRIGGER, saveNote);
    yield takeLatest(Routines.connectDevice.TRIGGER, connectDevice);
    yield takeLatest(Routines.sendMessage.TRIGGER, sendMessage);
    yield takeLatest(Routines.getMessages.TRIGGER, getMessages);
    yield takeLatest(Routines.setUserTag.TRIGGER, setUserTag);
}

export default infoSaga;
