import {call, put, takeLatest, take, race, select} from 'redux-saga/effects';

import {AppAction, MappedSubOrgsForList, PostCity} from "../types";
import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import { IRace } from '../../../Store/types';
import { accessToCustomer } from '../../../Policy/rightsUtil';
import { PolicyNameEnum } from '../../../PolicyNameEnum';
import { GlobalRoles } from '../../../GlobalRoles';
import { getSuborgsWithRoles } from '@Safemate/Store/routines';
import { PaymentProfile, PaymentProfileType } from '@Safemate/Model/PaymentProfile';
import { CustomerDTO, ICustomer, IFullCustomerWithPolicy } from '@Safemate/Model/Customer';
import { CustomerForm } from '@Safemate/SubUnits/Form/types';
import { createCustomerTree } from '@Safemate/SubUnits/utils';
import { selectCustomers, selectCustomerTree } from '../selectors';


export interface PostalProps{
  pnr: string,
  isBilling: boolean
}

function* selectCustomer(action: AppAction<IFullCustomerWithPolicy>){
  try{
    const customer = action.payload;
    const paymentDetail: PaymentProfileType = yield call(Api.getPaymentOptions, customer.customerId, customer.firm.parent);
    yield put(Routines.getPayment.success(paymentDetail));
    yield put(Routines.selectCustomer.success(customer));
  }
  catch(e){
    console.log(e)
    yield put(Routines.selectCustomer.failure());
  }
}
export interface SaveFormInterface{
  formValues: CustomerForm;
  saveMessage: string;
}

function* getCustomers(action: AppAction<null>){
  try{
    const customers: IFullCustomerWithPolicy[] = yield call(Api.getCustomers);
    yield put(Routines.getCustomers.success(customers));
  }
  catch(e){
    yield put(Routines.getCustomers.failure());
  }
}

function* saveCustomer(action: AppAction<SaveFormInterface>){
  try{
    const { formValues: { pptyId, customer }, saveMessage } = action.payload;
    
    const response: CustomerDTO = yield call(Api.saveForm, pptyId, {...customer, firmName: customer.lastName});
    const customers: IFullCustomerWithPolicy[] = yield call(Api.getCustomers);
    
    yield put(Routines.saveCustomer.success(customers))
    
    const selectedFirm: IFullCustomerWithPolicy = customers.find(customer => customer.customerId === response.custId);
    yield put(Routines.selectCustomer.trigger(selectedFirm));

    yield feedbackSuccess(saveMessage + " " + customer.lastName);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveCustomer.failure());
  }
}


function* deleteCustomer(action: AppAction<number>){
  try {
    yield call(Api.deleteSubOrg , action.payload);
    
    yield put(Routines.getCustomers());
    const {success, failure}: IRace<IFullCustomerWithPolicy[]> = yield race({
      success: take(Routines.getCustomers.SUCCESS),
      failure: take(Routines.getCustomers.FAILURE)
    });

    if(success){

      const customers = success.payload;
      const selectedFirm: IFullCustomerWithPolicy = customers.find(customer =>
        accessToCustomer(customer.policies, PolicyNameEnum.SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])) as IFullCustomerWithPolicy;

      yield put(Routines.selectCustomer.trigger(selectedFirm));
    }
    yield put(Routines.deleteCustomer.success());
    yield feedbackSuccess("subUnitDeleted");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteCustomer.failure());
  }
}

function* getPostalCity(action: AppAction<PostalProps>){
  try{
    const postalCity: PostCity = yield call(Api.getPostCity, action.payload.pnr);
    if(action.payload.isBilling){
      yield put(Routines.getBillingPostCity.success(postalCity));
    }else{
      yield put(Routines.getPostCity.success(postalCity));
    }
  }
  catch(e){
    yield put(Routines.getPostCity.failure());
  }
}


function* alterTree(action: AppAction<ICustomer>){

  const  customer  = action.payload
  const customersWithPolicy: IFullCustomerWithPolicy[] = yield select(selectCustomers);

  let match = false;

  const clonedCustomers = customersWithPolicy.map(cust => {
    if(cust.customerId === customer.custId){
      match = true;
      return {
        ...cust,
        firm: {
          ...cust.firm,
          ...customer
        }
      }
    }
    else{
      return cust;
    }
  })

  yield put(Routines.alterTree.success(createCustomerTree(clonedCustomers)));

}

function* customerSaga(){
  yield takeLatest(Routines.selectCustomer.TRIGGER, selectCustomer);
  yield takeLatest(Routines.saveCustomer.TRIGGER, saveCustomer);
  yield takeLatest(Routines.deleteCustomer.TRIGGER, deleteCustomer);
  yield takeLatest(Routines.getPostCity.TRIGGER, getPostalCity);
  yield takeLatest(Routines.getBillingPostCity.TRIGGER, getPostalCity);
  yield takeLatest(Routines.getCustomers.TRIGGER, getCustomers);
  yield takeLatest(Routines.alterTree.TRIGGER, alterTree);
}

export default customerSaga;
