import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Formik, Form, Field } from "formik";

import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import { Checkbox } from "../../../Elements/CheckboxFormik";
import { saveNote, getNote } from "../Store/routines";
import { connect } from "react-redux";
import { DeviceNote, DeviceNoteLog, Device } from "../types";
import { IDevice as ListDevice } from "@Safemate/Model/Device";
import confirmModal from "../../Modals/confirmModal";
import { formatDate } from "../../utils";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";

export const TextArea = styled(Field)`
  resize: none;
  width: 100%;
  height: 7em;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    margin-bottom: 0;
  }
`;

const ClearButton = styled(Button)`
  margin-left: 10px;
  background: #F12638;
  color: white;
`;

const CheckWrapper = styled.div`
  margin: 10px 0px;
  div {
    float: none;
  }
`;

const NoteHeader = styled.h3`
  margin-right: 5px;
`;

const mapStateToProps = ({ settings: {info: { note, log }}}: AppState) => {
  return{
    note,
    log,
  }
}

const mapDispatchToProps = {
  saveNote,
  getNote
}

interface NoteProps extends WrappedComponentProps{
  note: DeviceNote;
  device: Device | ListDevice;
  custId: number;
  saveNote: Routine<ActionFunctionAny<Action<any>>>;
  getNote: Routine<ActionFunctionAny<Action<any>>>;
  log: DeviceNoteLog;
} 

export const Note = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({ intl: { formatMessage }, saveNote, getNote, device, custId, note, log }: NoteProps) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;


    if(checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_NOTE, custId)){
    
    const deviId = device.deviId;
    const isTwin = device.twinChild;

    React.useEffect(() => {
      getNote(deviId)
    }, [deviId])

    const { Comp, func } = confirmModal(); 

    return(
      <Formik
        onSubmit={(values) => {
          if(!isTwin){
            const { id, note, sms } = values;
            saveNote({
              id: id || 0,
              deviId,
              note,
              sms
            })
          }
        }}
        initialValues={{
          ...note
        }}
        enableReinitialize
      >
        {({values, dirty, submitForm, setFieldValue}) => {
          return(
          <Form>
            <Header>
            <NoteHeader>{formatMessage({id: "note", defaultMessage: "Notat"})}</NoteHeader>
              <Tooltip
                popoverId="safemate-note-popover"
                labelId="safemateNoteDesc"
                defaultMessage="Her kan du legge til tekst som vises i Safemate-listen. Vær varsom med hva slags informasjon du legger inn her, denne informasjonen vil være synlig for alle med tilgang til Safemate-listen!"
              >
                <InformationIcon size="medium" />
              </Tooltip>
            </Header>
            <TextArea
              disabled={isTwin}
              maxlength={256}
              component="textarea"
              name="note"
            />
            {log.changedBy &&
            <span>
              {`${formatDate(note.lastChanged, true, false, false, false, false, formatMessage)}, ${log.changedBy}`}
            </span>}
            <CheckWrapper>
              <Field
                outerDiv={{float: "unset"}}
                disabled={isTwin}
                value={values.sms}
                component={Checkbox}
                id="include-in-sms-check"
                name="sms"
                label={formatMessage({id: "includeNoteInAlarm", defaultMessage: "Inkluder notat i alarmmeldinger"})}
              />
            </CheckWrapper>
            {!isTwin &&
            <div>
              <Button 
                disabled={!dirty}
                onClick={() => {
                  func(true);
                }}
              >
                {formatMessage({id: "save", defaultMessage: "Lagre"})}
              </Button>
              <ClearButton
                disabled={values.note.length === 0}
                onClick={() => {
                  setFieldValue("note", "");
                }}
              >
                {formatMessage({id: "deleteNote", defaultMessage: "Slett"})}
              </ClearButton>
              <Comp
                title={formatMessage({id: "saveNote", defaultMessage: "Lagre notat"})}
                body={
                  <React.Fragment>
                  <p>
                    {formatMessage({id: "saveNoteConfirmation", defaultMessage: "Er du sikker på at du vil lagre dette notatet?"})}
                  </p>
                  <p>
                    {values.sms ? formatMessage({id: "noteInSmsConfirmation", defaultMessage: "Er du sikker på at du ønsker å sende ut denne meldingen til mottakere ved alarm?"}) : ""}
                  </p>
                  </React.Fragment>
                }
                confirmText={formatMessage({id: "save", defaultMessage: "Lagre"})}
                confirmFunc={() => {
                  submitForm();
                  func(false);
                }}
              />
            </div>}
            <UnsavedCheck/>
          </Form>)}
        }
      </Formik>
    )
  }
  return null;
  })
)

export default Note;
