import * as React from "react";
import FieldWrapper, {GenericCheckbox, GenericDropdown,} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {bypassTimer, guardTime, sensorSupervisionCheckTimer, sensorSupervisionCheckTimerPendant, alarmSmsReceipt, sensorSensitivity, alarmButtonSensitivity} from "./options";
import {LabelEnum} from "../../LabelEnum";
import withFormWrapper from "@Elements/Form/formWrapper";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { DeviceSettings } from "../types";

// Dropdowns
export const SensorSupervisionCheckTimer = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.SENSOR_SUPERVISION_CHECK_TIMER,
  title: LabelEnum.SENSOR_SUPERVISION_CHECK_TIMER,
  dataOptions: {
    options: sensorSupervisionCheckTimer,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
});

export const SensorSupervisionCheckTimerPendant = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.SENSOR_SUPERVISION_CHECK_TIMER_PENDANT,
  title: LabelEnum.SENSOR_SUPERVISION_CHECK_TIMER_PENDANT,
  dataOptions: {
    options: sensorSupervisionCheckTimerPendant,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND,
    mapRoundDown: true
  }
});

export const GuardTime = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.GUARD_TIME,
  title: LabelEnum.GUARD_TIME,
  dataOptions: {
    options: guardTime,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
});

export const AlarmSmsReceipt = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.ALARM_SMS_RECEIPT,
  title: LabelEnum.ALARM_SMS_RECEIPT,
  dataOptions: {
    options: alarmSmsReceipt,
    skipMap: true,
    translate: true
  }
});

export const BypassTimer = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.BYPASS_TIMER,
  title: LabelEnum.BYPASS_TIMER,
  dataOptions: {
    options: bypassTimer,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
})

export const SensorSensitivity = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.SENSOR_SENSITIVITY,
  title: LabelEnum.SENSOR_SENSITIVITY,
  dataOptions: {
    options: sensorSensitivity,
    skipMap: true
  }
})

export const AlarmButtonSensitivity = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.ALARM_BUTTON_SENSITIVITY,
  title: LabelEnum.ALARM_BUTTON_SENSITIVITY,
  dataOptions: {
    options: alarmButtonSensitivity,
    skipMap: true,
    translate: true
  }
})

// Checkboxes
export const SensorSupervisionCheckActive = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.SENSOR_SUPERVISION_CHECK_ACTIVE, defaultMessage: "Sensorovervåking"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.SENSOR_SUPERVISION_CHECK_ACTIVE
});

export const LocalLearning = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.LOCAL_LEARNING, defaultMessage: "Aktiver lokal innlæring"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.LOCAL_LEARNING
});

export const ACLCheck = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.ACL_CHECK_LABEL, defaultMessage: "Call filter"},
      modal: true,
      modalActive: false,
      modalHeader: {id: "callFilterDisableModalHeader", defaultMessage: "Confirm action"},
      modalText: {id: "callFilterDisableModalMessage", defaultMessage: "Switching off the call filter means that everyone who knows the phone number of the device may enter a two-way call with device. Are you sure you want to continue?"},
      modalOkText: {id: "callFilterDisableModalOk", defaultMessage: "I understand the risk, proceed"},
      modalCancelText: {id: "callFilterDisableModalCancel", defaultMessage: "Abort"},
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.ACL_CHECK
});


export const TeamAlertLearn = withFormWrapper<DeviceSettings, {}>(({ formik: { values: { teamAlertLearnUrl }}}) => {
  if(!teamAlertLearnUrl) return null;
  return(
    <a target="_blank" href={teamAlertLearnUrl}><InformationIcon size="medium" /></a>
  )
})

export const TeamAlert = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.TEAM_ALERT, defaultMessage: "Kollegavarsling"},
      customTooltip: TeamAlertLearn
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.GENERAL,
  field: SettingEnum.TEAM_ALERT
});
