import {call, put, takeLatest, takeLeading} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../../Store/types';
import * as Routines from "../routines";

function* getCountries(action: AppAction<string>){
  try {
    const countries = yield call(Api.getCountries, action.payload);
    yield put(Routines.getCountries.success(countries));
  }
  catch(e){
    yield put(Routines.getTexts.failure());
  }
}


function* countrySaga(){
  yield takeLeading(Routines.getCountries.TRIGGER, getCountries);
}

export default countrySaga;
