import { CustomRoleWithPolicy } from "./Policy";
import { PaymentProfile } from "./PaymentProfile";

export interface ICustomerWithPolicy{
  customerId: number;
  customerName: string;
  parent: number;
  defaultAhp: number;
  level: number;
  whiteListPhoneNumbers: string | null;
  policies: CustomRoleWithPolicy;
}

export interface IFullCustomerWithPolicy{
  customerId: number;
	firm: CustomerDTO;
	numberOfDevices: number;
	policies: CustomRoleWithPolicy;
	paymentProfile: PaymentProfile;
	priceGroup?: PriceGroup;
	zone: number;
  unsaved?: boolean;
}

export interface ICustomer {
  lastChanged: number;
  lastChangedBy: number;
  created: number;
  createdBy: number;
  custId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: number;
  activatedDate: number;
  prgrId: number;
  invoiceCreditLimitExclVat: number;
  invoiceBalanceExclVat: number;
  allowAllPhoneNo: boolean;
  latestInvoiceDate: number;
  orgNum: string;
  addrName: string;
  billingAddrName: string;
  addrReference: string;
  addressLine1: string;
  addressLine2: string;
  addrZipCode: string;
  addrCity: string;
  addrCounId: number;
  country: string;
  parent: number;
  defaultAhp: number;
  forceTfa: boolean;
  forceCoverage: boolean;
  individualBilling: boolean;
  integrationId: string;
  integrationRole: string;
  climaxConfig: boolean;
  allowDeviceDeletes: boolean;
  priceGroup: PriceGroup;
  defaultBillingCode: BillingCode;
  billingCodes: BillingCode[];
  numActiveDevices: number;
  numActiveDevicesIncludingChildren: number;
  active: boolean;
  company: boolean;
  languageCode: string;
  prioritySupport: boolean;
  privateUser: boolean;
  coaddress: string;
  deleted: boolean;
  addrNameForBilling: string;
  whiteListPhoneNumbers: string;
  billingAccepted: boolean;
  informalContactName: string;
  firmName: string;
  streetAddress: string;
  name: string;
}

export interface CustomerDTO{
  custId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: number;
  activatedDate: number;
  prgrId: number;
  invoiceCreditLimitExclVat: number;
  invoiceBalanceExclVat: number;
  allowAllPhoneNo: boolean;
  latestInvoiceDate: number;
  orgNum: string;
  ean: string;
  addrName: string;
  billingAddrName: string;
  addrReference: string;
  addressLine1: string;
  addressLine2: string;
  addrZipCode: string;
  addrCity: string;
  addrCounId: number;
  country: string;
  parent: number;
  defaultAhp: number;
  isCompany: boolean;
  isPrivateUser: boolean;
  isPrioritySupport: boolean;
  forceTfa: boolean;
  forceCoverage: boolean;
  isBillingAccepted: boolean;
  individualBilling: boolean;
  integrationId: string;
  integrationRole: string;
  climaxConfig: boolean;
  allowDeviceDeletes: boolean;
  stock: boolean;
  whiteListPhoneNumbers: string;
  firmName: string;
  kombitCustomer:  boolean;
  kombitUuid: String;
  [index: string]: string | number | any;
}

export interface PriceGroup {
  prgrId: number
  siteIdOriginated: number
  name: string
  defaultGroup: boolean
  defaultCompanyGroup: boolean
  invoiceCreditLimitExclVat: number
  couponCode: string
  id: number
}

export interface BillingCode {
  lastChanged: number
  lastChangedBy: number
  created: number
  createdBy: number
  bicoId: number
  custId: number
  sapeId: number
  billingCode: string
  ruleThemAll: boolean
  prgrId: number
  priceGroup: PriceGroup;
  forSalesPerson: boolean
}


export const defaultCustomer = {
  activatedDate: 0,
  active: false,
  addrCity: "",
  addrCounId: 0,
  addressLine1: "",
  addressLine2: "",
  addrName: "",
  addrNameForBilling: "",
  addrReference: "",
  addrZipCode: "",
  allowAllPhoneNo: false,
  billingAddrName: "",
  coaddress: "",
  company: false,
  country: "",
  custId: 0,
  created: 0,
  createdBy: 0,
  defaultAhp: 0,
  deleted: false,
  email: "",
  firstName: "",
  firmName: "",
  forceCoverage: false,
  forceTfa: false,
  informalContactName: "",
  integrationId: "",
  integrationRole: "",
  invoiceBalanceExclVat: 0,
  invoiceCreditLimitExclVat: 0,
  languageCode: "",
  lastChanged: 0,
  lastChangedBy: 0,
  lastName: "",
  latestInvoiceDate: 0,
  mobile: "",
  name: "",
  numActiveDevices: 0,
  orgNum: "",
  parent: 0,
  prgrId: 0,
  priceGroup: {
    prgrId: 0,
    siteIdOriginated: 0,
    name: "",
    defaultGroup: false,
    defaultCompanyGroup: false,
    invoiceCreditLimitExclVat: 0,
    couponCode: "",
    id: 0,
  },
  privateUser: false,
  status: 0,
  streetAddress: "",
  individualBilling: false,
  climaxConfig: false,
  allowDeviceDeletes: false,
  defaultBillingCode: {
    lastChanged: 0,
    lastChangedBy: 0,
    created: 0,
    createdBy: 0,
    bicoId: 0,
    custId: 0,
    sapeId: 0,
    billingCode: "",
    ruleThemAll: false,
    prgrId: 0,
    priceGroup: 0,
    forSalesPerson: false
  },
  billingCodes: [] as BillingCode[],
  numActiveDevicesIncludingChildren: 0,
  prioritySupport: false,
  whiteListPhoneNumbers: "",
  billingAccepted: false
}

export const defaultNewCustomer: CustomerDTO = {
  custId: 0,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  status: 1,
  activatedDate: 0,
  prgrId: 0,
  invoiceCreditLimitExclVat: 0,
  invoiceBalanceExclVat: 0,
  allowAllPhoneNo: false,
  latestInvoiceDate: 0,
  orgNum: "",
  addrName: "",
  billingAddrName: "",
  addrReference: "",
  addressLine1: "",
  addressLine2: "",
  addrZipCode: "",
  addrCity: "",
  addrCounId: 0,
  country: "",
  parent: 0,
  defaultAhp: 0,
  isCompany: true,
  isPrivateUser: false,
  isPrioritySupport: false,
  forceTfa: false,
  forceCoverage: false,
  isBillingAccepted: false,
  individualBilling: false,
  integrationId: "",
  integrationRole: "",
  climaxConfig: false,
  allowDeviceDeletes: true,
  stock: false,
  kombitUuid:"",
  kombitCustomer: false,
  whiteListPhoneNumbers: ""
}

export interface CustomerWithChildren{
  children: CustomerWithChildren[] | null;
  customer: {
    custId: number;
    firmName: string;
  };
  rights: boolean;
}
