import { FilterState } from "./types";
import { AlarmFilterEnum } from '../AlarmFilterEnum';
import { OrderFilterEnum } from '../OrderFilterEnum';
import { UserTags } from "@Safemate/enums/UserTags";


export interface PersistedFilters{
  deviceTypes: number[];
  customers: number[];
  alarmFilters: AlarmFilterEnum[];
  orderFilter: OrderFilterEnum;
  userTags: UserTags[];
}

export const loadFilters = () => {
  try {
    const serializedState = localStorage.getItem('device_list_filter');
    if (serializedState === null) {
      return;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return;
  }
}; 

export const saveFilters = (state: PersistedFilters) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('device_list_filter', serializedState);
  } catch {
    // ignore write errors
  }
};