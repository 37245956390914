import * as React from "react";
import moment from "moment";
import {DeletedUser, User} from "../Models/User";
import Trained from "../../../Elements/Icon/Icons/Trained";
import {UserWithCustomer} from "../Models/UserWithCustomer";
import {MessageDescriptor, useIntl} from "react-intl";
import {Role} from "../Models/Role";
import {Customer} from "../Models/Customer";
import Actions from "./Actions";
import Active, {Deactivated} from "./Active";
import {LdapUser} from "../Models/LdapUser";
import {Option} from "../../DefaultSettings/Settings/FieldWrapper";
import {FilterAccountStatus, FilterState} from "../Store/types";
import {translateOptions, translateOptionsExport} from "../utils"
import { GlobalRoleName } from "../../Policy/GlobalRoleName";
import { LabelEnum } from '../LabelEnum';
import { OrderFilterEnum } from '../OrderFilterEnum';
import { UserShield } from "@Elements/Icon/Icons/FontAwesome";
import { AppState, GdprManager } from "@Safemate/Store/types";
import { connect } from "react-redux";
import styled from "styled-components";
import { formatDate } from "@Safemate/utils";
import Restore from "./Actions/Restore";

export type MapUsers = (
  users: Array<UserWithCustomer>,
  roles: Array<Role>,
  filter: FilterState,
  formatMessage: (message: MessageDescriptor) => string,
  isAhp: boolean,
  isPrivate: boolean,
  isPartner: boolean,
  isSuperadmin: boolean
) => Array<Array<RowContent>>;

export type Header = {
  text: string;
  priority?: number;
  align?: string;
  export?: boolean;
}

export type MapHeader = (formatMessage: (message: MessageDescriptor) => string, isAhp: boolean, isPrivate: boolean, isPartner: boolean, active: boolean) => Header[];

export type MapDeletedUsers = (
  users: UserWithCustomer[],
  deletedUsers: Array<DeletedUser>,
  roles: Array<Role>,
  filter: FilterState,
  formatMessage: (message: MessageDescriptor) => string
) => Array<Array<RowContent>>;

export type GetCustomerListAsNumber = (users: Array<UserWithCustomer>) => Array<number>;

export type MapLdapUsers = (ldapUsers: Array<LdapUser>) => Array<Option>;

export type GetTimeAgoText = (user: UserWithCustomer, formatMessage: (message: MessageDescriptor) => string) => string;

export type FilterRole = (roles: number[], role: number) => boolean;
export type FilterCustomer = (customers: number[], customer: number[]) => boolean;
export type FilterStatus = (statusFilter: FilterAccountStatus, user: User) => boolean;

export const isActive = (user: User) => {
  let deactivated = user.deactivated;
  if (user.tempDeactivated) {
    const dateFrom = user.deactivatedFrom;
    const dateTo = user.deactivatedTo;
    deactivated = moment().isBetween(
      moment(dateFrom),
      moment(dateTo)
    );
  }
  return !deactivated;
};

export const getTimeAgoText: GetTimeAgoText = (user, formatMessage) =>{
  if (user.lastOnlineDate > 0) {
    if (new Date().getTime() - user.lastOnlineDate < 70000 ) {
        return formatMessage({id: "online", defaultMessage: "Online"}) + ' ' + formatMessage({id: "now", defaultMessage: "now"});
    } else if (new Date().getTime() - user.lastOnlineDate < 200000 ) {
      return formatMessage({id: "recently", defaultMessage: "Nylig"});
    } else {
      return formatDate(user.lastOnlineDate, false, false, false, false, true, formatMessage);
    }
  }
  return "";
}

export const makeShortSubOrgStr = (orgList: Customer[], formatMessage: (message: MessageDescriptor) => string) => {
  var str = formatMessage({id: 'multiple...', defaultMessage: "Flere..."});
  if(orgList.length == 1) {
    str = orgList[0].firmName;
  }
  return str;
};

export interface RowContent{
  text: string | React.ReactElement;
  priority?: number;
}

export const mapLdapUsers: MapLdapUsers = (ldapUsers) =>
  ldapUsers.map((ldapUser: LdapUser) => {
    return {
      text: `${ldapUser.displayName} (${ldapUser.userName})`,
      value: {
        firstName: ldapUser.givenName,
        lastName: ldapUser.sureName,
        username: ldapUser.userName,
        mobile: ldapUser.phone,
        email: ldapUser.email
      }
    }
  })

export const mapUserForEdit = ({user: {deactivatedTo, deactivatedFrom, ...user}, to, from, ...userWithCustomer}: UserWithCustomer) => {
  const mappedUser: UserWithCustomer = {
    ...userWithCustomer,
    to: to ? moment(to) : moment(),
    from: from ? moment(from) : moment(),
    user: {
      ...user,
      deactivatedTo: deactivatedTo ? moment(deactivatedTo, 'YYYY-MM-DD') : moment(),
      deactivatedFrom: deactivatedFrom ? moment(deactivatedFrom, 'YYYY-MM-DD') : moment(),
    }
  }
  return mappedUser;
}

export const userComparator = (filter: OrderFilterEnum, a: UserWithCustomer, b: UserWithCustomer) => {
  //const userA = `${a.user.lastName} ${a.user.firstName}`;
  //const userB = `${b.user.lastName} ${b.user.firstName}`;
  const userA = a.lastOnlineDate;
  const userB = b.lastOnlineDate;
  if(userA > userB) return -1;
  if(userA < userB) return 1;
  return 0;
}

export const mapHeader: MapHeader = (formatMessage, isAhp, isPrivate, isPartner, active) => {

  if(!active){
    return [
      { text: formatMessage({id: "name", defaultMessage: "Navn"})},
      { text: formatMessage({id: "phone", defaultMessage: "Telefon"}), priority: 2 },
      { text: formatMessage({id: "username", defaultMessage: "Brukernavn"}), priority: 2 },
      { text: formatMessage({id: "deletedBy", defaultMessage: "Slettet av"})},
      { text: formatMessage({id: "deletedDate", defaultMessage: "Dato slettet"})},
      { text: formatMessage({id: "subOrgs", defaultMessage: "Underenheter"})},
    ]
  }
  
 const header: Header[] = [
  { text: formatMessage({id: "name", defaultMessage: "Navn"})},
  { text: formatMessage({id: "phone", defaultMessage: "Telefon"}), priority: 2 },
  ...(isPrivate ? [] : [  { text: formatMessage({id: "trained", defaultMessage: "Opplært"})},]),
  { text: formatMessage({id: "username", defaultMessage: "Brukernavn"}), priority: 2 },
  ...(isPartner ? [] : [  { text: formatMessage({id: "role", defaultMessage: "Rolle"})}]),
  {
    text: formatMessage({id: "active", defaultMessage: "Aktiv"}),
    align: 'center'
  },
  {
    text: formatMessage({id: "lastVisit", defaultMessage: "Siste besøk"}),
    priority: 2
  }];
  if(isAhp || isPrivate ){

  }else{
    header.push({ text: formatMessage({id: "subOrgs", defaultMessage: "Underenheter"})});
  }
  header.push({ text: formatMessage({id: "thAction", defaultMessage: "handlinger"}), export: false})
  return header;
}

export const mapUsers: MapUsers = (
  users,
  roles,
  filter,
  formatMessage,
  isAhp,
  isPrivate,
  isPartner,
  isAdminSso
) => {
  if(users === undefined){
    return [];
  }
  return users.filter((item: UserWithCustomer) => {
    const customers = getCustomerListAsNumber([item]);
    if((isPrivate || users.length < 2 ) ||  
      (filterRole(filter.role, item.role) || filterRole(filter.role, item.temporaryRole))
      &&(filter.search === ""
        || item.user.email.toLowerCase().includes(filter.search.toLowerCase())
        || item.user.firstName.toLowerCase().includes(filter.search.toLowerCase())
        || item.user.lastName.toLowerCase().includes(filter.search.toLowerCase())
        || item.user.mobile.toLowerCase().includes(filter.search.toLowerCase()))
      && filterCustomer(filter.customer, customers)
      && filterStatus(filter.status, item.user)
    ) return true;
    return false;
  }).sort((a, b) => {
    const f = filterOrder(filter.orderFilter, a, b)
    return f;
  })
    .map((item: UserWithCustomer) => {

    const permanentRole = roles.find(role => role.id === item.role);
    const temporaryRole = roles.find(role => role.id === item.temporaryRole);

    const userRole =
      `
      ${permanentRole
        ? formatMessage({id: permanentRole.roleName, defaultMessage: permanentRole.roleName})
        : ""
      }
      ${temporaryRole
        ? ` (${formatMessage({id: temporaryRole.roleName, defaultMessage: temporaryRole.roleName})}) `
        : ""
      } 
    `;
    const isOnlineNow = new Date().getTime() - item.lastOnlineDate < 70000;
    const lastOnelineOneYearPlus = new Date().getTime() - item.lastOnlineDate > 31556952000;
    const isSso = item.user.lastSso !=  null;
    const mappedUser = [
      {text: item.user.name, skipTranslate: true, export: true},
      {text: item.user.mobile, priority: 2, skipTranslate: true, export: true},
      ...(isPrivate ? [] : [{
        text: item.user.trained ? <Trained title={`${formatMessage({id: LabelEnum.USER_TRAINED_BY, defaultMessage: "Opplæring godkjent av"})} ${item.user.trainingApprovedByName}`} size="medium"/> : "", value: item.user.trained ? "X" : "", export: true
      },]),
      {text: <Username userId={item.user.userId} username={item.user.username.toLowerCase()}/>, value: item.user.username.toLowerCase(), priority: 2, skipTranslate: true, export: true},
      ...(isPartner ? [] : [  {text: userRole, skipTranslate: true, export: true }]),
      {
        text: isActive(item.user)
          ? <Active isOnlineNow={isOnlineNow} overOneYearInactive={lastOnelineOneYearPlus} sso={isSso}  />
          : <Deactivated/>
          , value: isActive(item.user) ? "X" : "", export: true, align: "center"
      },
      {text: getTimeAgoText(item, formatMessage), priority: 2, skipTranslate: true, export: true }];
      if(isAhp || isPrivate){

      }else{
        mappedUser.push({text: makeShortSubOrgStr(item.subOrgs, formatMessage), skipTranslate: true, export: true });
      }

    mappedUser.push({
      text: <Actions user={item} isAhp={isAhp}/>, value: "", export: false,
    });
    return mappedUser;
  });
};

const mapStateToUsernameProps = ({ appData: { gdprManager } }: AppState) => {
  return {gdprManager}
}

const SvgWrap = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: 5px;
    width: 30px !important;
    height: 30px !important;
  }
`;

const Username = connect(mapStateToUsernameProps)(({username, userId, gdprManager}: {username: string, userId: number, gdprManager: GdprManager}) => {
  const { formatMessage } = useIntl();
  if(userId == gdprManager.userId){
    return <SvgWrap>
      {username}
      <UserShield title={formatMessage({id: "gdprManagerTooltip", defaultMessage: ""})}/>
    </SvgWrap>
  }
  else{
    return username;
  }
})

export const suborgMap = (suborg: string | Customer): number => {
  if(typeof(suborg) === "string"){
    return parseInt(suborg);
  }
  return suborg.custId;
}


export const getCustomerListAsNumber: GetCustomerListAsNumber = (users) => {
  if(users === undefined){
    return [];
  }
  return [...new Set(users.map(user =>
      [
        ...user.subOrgs.map(suborgMap),
        ...user.temporarySubOrgs.map(suborgMap)
      ]
    ).flat())];
}


const filterOrder = (filter: OrderFilterEnum, userOne: UserWithCustomer, userTwo: UserWithCustomer): number => {
  switch (filter){
    case OrderFilterEnum.ALPHABETICAL:
      return sortByString(userOne.user.name, userTwo.user.name);
    case OrderFilterEnum.LAST_LOGIN:
      //return sortByString(userTwo.lastActivity, userOne.lastActivity);
      return userTwo.lastOnlineDate - userOne.lastOnlineDate;
  }
}

type SortByString = (a: string, b: string) => number;

const sortByString: SortByString = (a,b) => {
  const nameA = a.toUpperCase(); // ignore upper and lowercase
  const nameB = b.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export const mapDeletedUsers: MapDeletedUsers = (
  users,
  deletedUsers,
  roles,
  filter,
  formatMessage
) => {
  if(users === undefined){
    return [];
  }

  const activeUsersnames = users.map(u => u.user.username.toLowerCase());

  return deletedUsers.filter(user => {
    if(
      !activeUsersnames.includes(user.email.toLowerCase()) 
      && filterRole(filter.role, user.role)
      &&(filter.search === ""
      || user.email.toLowerCase().includes(filter.search.toLowerCase())
      || user.firstname.toLowerCase().includes(filter.search.toLowerCase())
      || user.lastname.toLowerCase().includes(filter.search.toLowerCase())
      || user.mobile.toLowerCase().includes(filter.search.toLowerCase()))
    ) return true;
    return false;
  }).sort(sortDeletedUsers).map((user) => {
    return[
      {text: `${user.firstname} ${user.lastname}`, export: true},
      {text: user.mobile, export: true},
      {text: user.email, export: true},
      {text: user.deletedByName || formatMessage({id: "unknown", defaultMessage: "Ukjent"}), export: true},
      {text: user.deletedDate ? formatDate(user.deletedDate, false, false, false, false, false, formatMessage) : " ", export: true},
      {text: user.suborgs.length > 1 ? formatMessage({id: 'multiple...', defaultMessage: "Flere..."}) : user.suborgs[0].name, export: true},
      {text: <Restore user={user}/>}
    ]
  })
};

const sortDeletedUsers = (userOne: DeletedUser, userTwo: DeletedUser): number => {
  return sortByString(userOne.lastname, userTwo.lastname);
}

export const getExportData = (header: Array<Header>, data: Array<any>, formatMessage:any): Array<Array<string>> => {
  let exportData = [];
  exportData.push(translateOptionsExport(header, formatMessage));
  for(let i=0; i<data.length; i++){
    let row = []
    for(let j =0; j<8; j ++){
      if(data[i][j] !== undefined){
        let dataCol = data[i][j].value !== undefined ? data[i][j].value: data[i][j].text;
        if(dataCol != null && data[i][j].export){
          row.push(dataCol.replace(/[\n\r]/g, "").trim()); 
        }
      }
    }
    exportData.push(row);
  }
  return exportData;
}

export const mapRoles = (roles: Role[], formatMessage: (message: MessageDescriptor) => string, isAhp: boolean) => {
  if(isAhp && roles.length > 0){
    const superfirmId = roles.find(role => role.roleName === GlobalRoleName.SUPERFIRM)?.id;
    const firmId = roles.find(role => role.roleName === GlobalRoleName.FIRM)?.id;
    return [
      {value: superfirmId, text: formatMessage({id: GlobalRoleName.SUPERFIRM, defaultMessage: GlobalRoleName.SUPERFIRM})},
      {value: firmId, text: formatMessage({id: GlobalRoleName.FIRM, defaultMessage: GlobalRoleName.FIRM})},
    ]
  }
  
  return translateOptions(roles.map(role => {
    return{
      value: role.id,
      text: role.roleName
    }
  }), formatMessage);
}

const filterRole: FilterRole = (rolesFilter, role) => {
  return rolesFilter.length === 0 || rolesFilter.includes(role);
} 

const filterCustomer: FilterCustomer = (customersFilter, customers) => {
  if(customersFilter.length !== 0){
    let match = false;
    customersFilter.forEach(customer => {
      if(customers.includes(customer)){
        match = true;
      }
    })
    return match;
  }
  return true;
}

const filterStatus: FilterStatus = (statusFilter, user) => {

  let deactivated = user.deactivated;

  if(user.tempDeactivated){
    const from = moment(user.deactivatedFrom);
    const to = moment(user.deactivatedTo);
    const now = moment();
    deactivated = now.isBetween(from, to) || deactivated;
  }

  if(deactivated && statusFilter === FilterAccountStatus.INACTIVE) return true;
  else if(!deactivated && statusFilter === FilterAccountStatus.ACTIVE) return true;
  return false;
}

const leftPadZero = (number: number) =>{
  return (number < 10 ) ? '0'+number : ''+number;
}

export const formatDateLocal = (date: Date) => {
  const today = new Date(date);
  const dd = leftPadZero( today.getDate() );
  const mm = leftPadZero( ( today.getMonth() + 1 ) );
  const yyyy = '' + today.getFullYear();
  const hh = leftPadZero( today.getHours() );
  const mi = leftPadZero( today.getMinutes() );
  const ss = leftPadZero( today.getSeconds() );
  return dd+'/'+mm+'/'+yyyy +' '+hh+':'+mi+':'+ss;
}

export type GetOrderFilter = (
  formatMessage: (message: MessageDescriptor) => string
) => Option[];

export const getOrderFilter: GetOrderFilter = (formatMessage) => {
  return[
    {text: capitalizeLabel(formatMessage({id: LabelEnum.ALPHABETICAL, defaultMessage: "Alfabetisk"})), value: OrderFilterEnum.ALPHABETICAL},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.LAST_LOGIN, defaultMessage: "Sist pålogget"})), value: OrderFilterEnum.LAST_LOGIN},
  ]
}

type CapitalizeLabel = (label: string) => string;

const capitalizeLabel: CapitalizeLabel = (label) => {
  return `${label.charAt(0).toUpperCase()}${label.substr(1, label.length - 1)}`;
}

