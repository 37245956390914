import * as React from "react";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {MappedSubOrgsForList} from "../Store/types";
import {selectCustomer} from "../Store/routines";
import {CustomerRow} from "./styled";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {checkPermissionsForCustomer} from "../../Policy/rightsUtil";
import { AppState } from "@Safemate/Store/types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { IFullCustomerWithPolicy } from "@Safemate/Model/Customer";
import { useIntl } from "react-intl";


interface CustomerListProps{
  customerTree: MappedSubOrgsForList;
  selectCustomer:  Routine<ActionFunctionAny<Action<any>>>;
}

const CustomerList = ({ customerTree }: CustomerListProps) =>{

  if(!customerTree[0]) return null;

  return <CustomerItem customers={customerTree[0]} level={1} customerData={customerTree}/>;

}

const mapStateToProps = ({ subUnits: {customer: { customerTree, selectedCustomer }}}: AppState) => ({
  customerTree,
  selectedCustomer
})

const mapDispatchToProps = {
  selectCustomer: selectCustomer
}

interface CustomerItemProps{
  customers: IFullCustomerWithPolicy[];
  customerData: MappedSubOrgsForList;
  selectedId: number;
  level: number;
  selectCustomer:  Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToCustomerItemProps = ({ subUnits: { customer: { selectedCustomer: { customerId } }} }: AppState) => {
  return {
    selectedId: customerId
  }
}

const CustomerItem = connect(mapStateToCustomerItemProps, mapDispatchToProps)(({ selectedId, selectCustomer, customers, level, customerData}: CustomerItemProps) => {

  const { formatMessage } = useIntl();

  const select = (customer: IFullCustomerWithPolicy) => {
    if(selectedId !== customer.customerId){
      selectCustomer(customer);
    }
  }

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <div>
      {customers.sort(customerSort).map((customer) => {

        const hasAccess = checkPermissionsForCustomer(permissions, PolicyNameEnum.SUB_ORGS, customer.customerId);

        return <React.Fragment>
          <CustomerRow 
            level={level} 
            hasAccess={checkPermissionsForCustomer(permissions, PolicyNameEnum.SUB_ORGS, customer.customerId)} 
            active={selectedId === customer.customerId} 
            onClick={() => hasAccess && select(customer)}
            onKeyDown={(event) => event.key === "Enter" && hasAccess && select(customer)}
            key={customer.customerId}>
            {customer.firm.lastName || formatMessage({id: "newCustomerName", defaultMessage: "Ny underavdeling"})} ({customer.numberOfDevices})
          </CustomerRow>
          {customerData[customer.customerId] && customer.customerId !== 0 && (<CustomerItem customers={customerData[customer.customerId]} level={level+1} customerData={customerData}/>)}
        </React.Fragment>
      })}
    </div>
  );
});

const customerSort = (a: IFullCustomerWithPolicy, b: IFullCustomerWithPolicy) => {
  return a.firm.lastName.localeCompare(b.firm.lastName);
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);