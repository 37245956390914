import * as React from "react";
import Deha1 from "../../../Elements/Icon/Icons/DeviceIcons/Deha1";
import Deha2 from "../../../Elements/Icon/Icons/DeviceIcons/Deha2";
import Deha3 from "../../../Elements/Icon/Icons/DeviceIcons/Deha3";
import Deha4 from "../../../Elements/Icon/Icons/DeviceIcons/Deha4";
import Deha5 from "../../../Elements/Icon/Icons/DeviceIcons/Deha5";
import Deha8 from "../../../Elements/Icon/Icons/DeviceIcons/Deha8";
import Deha10 from "../../../Elements/Icon/Icons/DeviceIcons/Deha10";
import Deha11 from "../../../Elements/Icon/Icons/DeviceIcons/Deha11";
import Deha13 from "../../../Elements/Icon/Icons/DeviceIcons/Deha13";
import Deha14 from "../../../Elements/Icon/Icons/DeviceIcons/Deha14";
import Deha15 from "../../../Elements/Icon/Icons/DeviceIcons/Deha15";
import Deha16 from "../../../Elements/Icon/Icons/DeviceIcons/Deha16";
import Deha17 from "../../../Elements/Icon/Icons/DeviceIcons/Deha17";
import Deha20 from "../../../Elements/Icon/Icons/DeviceIcons/Deha20";
import Deha21 from "../../../Elements/Icon/Icons/DeviceIcons/Deha21";
import Deha22 from "../../../Elements/Icon/Icons/DeviceIcons/Deha22";
import Deha23 from "../../../Elements/Icon/Icons/DeviceIcons/Deha23";
import Deha24 from "../../../Elements/Icon/Icons/DeviceIcons/Deha24";
import Deha25 from "../../../Elements/Icon/Icons/DeviceIcons/Deha25";
import Deha28 from "../../../Elements/Icon/Icons/DeviceIcons/Deha28";
import Deha29 from "../../../Elements/Icon/Icons/DeviceIcons/Deha29";
import Deha30 from "../../../Elements/Icon/Icons/DeviceIcons/Deha30";
import Deha31 from "../../../Elements/Icon/Icons/DeviceIcons/Deha31";
import Deha32 from "../../../Elements/Icon/Icons/DeviceIcons/Deha32";
import Deha34 from "../../../Elements/Icon/Icons/DeviceIcons/Deha34";
import Unknown from "../../../Elements/Icon/Icons/DeviceIcons/unknown";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";



export const DeviceTypeToIcon = new Map<number, React.ReactNode>(
  [
    [0, <Unknown/>],
    [DeviceHardwareEnum.TRIGGER_ONE, <Deha1/>],
    [DeviceHardwareEnum.RIO_APP, <Deha2/>],
    [DeviceHardwareEnum.TRACK_ONE, <Deha3/>],
    [DeviceHardwareEnum.TRIGGER_TWO, <Deha4/>],
    [DeviceHardwareEnum.TRIGGER_THREE, <Deha5/>],
    [DeviceHardwareEnum.TRACK_THREE, <Deha8/>],
    [10, <Deha10/>],
    [DeviceHardwareEnum.WATCH_ONE, <Deha11/>],
    [DeviceHardwareEnum.SAFEMATE_HOME, <Deha13/>],
    [DeviceHardwareEnum.WATCH_TWO, <Deha14/>],
    [DeviceHardwareEnum.TRIGGER_FOUR, <Deha15/>],
    [DeviceHardwareEnum.TRIGGER_FIVE, <Deha16/>],
    [DeviceHardwareEnum.WATCH_THREE, <Deha17/>],
    [DeviceHardwareEnum.SAFEMATE_HELP, <Deha20/>],
    [DeviceHardwareEnum.TRIGGER_THREE_E, <Deha21/>],
    [DeviceHardwareEnum.SAFEMATE_HOME_DT35, <Deha22/>],
    [DeviceHardwareEnum.TRIGGER_TWO_4G, <Deha23/>],
    [DeviceHardwareEnum.WATCH_FOUR, <Deha24/>],
    [DeviceHardwareEnum.TRACK_TWO, <Deha25/>],
    [DeviceHardwareEnum.SAFEMATE_HOME_MAX, <Deha13/>],
    [DeviceHardwareEnum.GTX_SOLE, <Deha28/>],
    [DeviceHardwareEnum.WATCH_FIVE, <Deha29/>],
    [DeviceHardwareEnum.WATCH_SIX, <Deha30/>],
    [DeviceHardwareEnum.TRACK_FOUR, <Deha31/>],
    [DeviceHardwareEnum.SAFEMATE_HOME_STCB1, <Deha32/>],
    [DeviceHardwareEnum.WATCH_SEVEN, <Deha34/>]
  ]
)
