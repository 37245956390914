import * as React from "react";
import { useIntl } from "react-intl";
import {LabelEnum} from "../../LabelEnum";
import {setRestoreModal, SetRestoreModal} from "../../Store/actions";
import {connect} from "react-redux";
import { DeletedUser } from "@Safemate/UserAdmin/Models/User";
import { TrashUndo } from "@Elements/Icon/Icons/FontAwesome";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";

interface RestoreProps{
  setRestoreModal: SetRestoreModal;
  user: DeletedUser;
}

const mapDispatchToProps = {
  setRestoreModal
}

const Restore = connect(null, mapDispatchToProps)(({user, setRestoreModal}: RestoreProps) => {

  const { formatMessage } = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  if(!permissions.get(PolicyNameEnum.ADMIN)){
    return null;
  }
  
  return(
    <React.Fragment>
      <TrashUndo
        medium
        tabIndex="0"
        title={formatMessage({id: LabelEnum.RESTORE, defaultMessage: "Gjennopprett"})}
        onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          event.stopPropagation();
          setRestoreModal(user);
        }}
        onKeyDown={(event: React.KeyboardEvent<SVGSVGElement>) => {
          if(event.key === "Enter")
            setRestoreModal(user);
        }}
        inline
      />
    </React.Fragment>
  )
});

export default Restore;
