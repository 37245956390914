
import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Plus from "../../../Elements/Icon/Icons/Safemate/solid/PlusCircle";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import styled from 'styled-components';
import { connect } from "react-redux";
import { Option } from '../../../Safemate/DefaultSettings/Settings/FieldWrapper';
import { getAlarmFilter, translateDeviceTypes, getOrderFilter } from './utils';
import { AlarmFilterEnum } from '../AlarmFilterEnum';
import { 
  filterAlarm, 
  filterCustomer, 
  filterDeviceType, 
  filterOrder,
  filterUserTags,
  FilterAlarm, 
  FilterCustomer, 
  FilterDeviceType,
  FilterOrder,
  FilterUserTags
} from '../Store/actions';
import { LabelEnum } from "../LabelEnum";
import { OrderFilterEnum } from "../OrderFilterEnum";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { IDevice } from "@Safemate/Model/Device";
import { useIntl } from "react-intl";
import {AllPermissions, PolicyContext} from "@Safemate/Policy/Provider";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import { UserTags } from "@Safemate/enums/UserTags";

interface HeaderProps{
  deviceTypes: Option[];
  customers: ICustomerWithPolicy[];
  selectedDeviceTypes: number[];
  selectedCustomers: number[];
  selectedAlarmFilters: AlarmFilterEnum[];
  selectedOrderFilter: OrderFilterEnum;
  selectedUserTags: UserTags[];
  filterAlarm: FilterAlarm,
  filterDeviceType: FilterDeviceType;
  filterCustomer: FilterCustomer;
  filterOrder: FilterOrder;
  filterUserTags: FilterUserTags;
  filteredDevices: IDevice[];
  devices: IDevice[];
  isPrivate: boolean;
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px -15px;


  > div:first-child{
    padding-right: 5px;
  }

  > div:last-child{
    padding-left: 5px;
  }

  > div:not(:first-child):not(:last-child) {
    padding: 0px 5px;
  }

`;

const StyledCol = styled.div`
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

  flex: 1;
  box-sizing: border-box; 
`

const AddRow = styled(Row)<{private: boolean}>`
  ${props => props.private && "justify-content: center;"}
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const AddButton = styled(Button)<{private: boolean}>`
  ${props => !props.private && "margin-left: auto;"}
`

const mapStateToProps = (
  {
    deviceList: {
      device: {deviceTypes, filteredDevices, devices},
      filter: {deviceTypes: selectedDeviceTypes, customers: selectedCustomers, alarmFilters: selectedAlarmFilters, orderFilter: selectedOrderFilter, userTags: selectedUserTags}
    },
    appData: {
      isPrivate,
      customersWithPolicy
    }
  }: AppState) => {
  return{
    deviceTypes,
    selectedDeviceTypes,
    selectedCustomers,
    selectedAlarmFilters,
    selectedOrderFilter,
    selectedUserTags,
    customers: customersWithPolicy,
    devices,
    filteredDevices,
    isPrivate
  }
}

const mapDispatchToProps = {
  filterDeviceType,
  filterAlarm,
  filterCustomer,
  filterOrder,
  filterUserTags
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ({
    filterAlarm, 
    filterDeviceType, 
    filterCustomer, 
    filteredDevices,
    filterOrder, 
    filterUserTags,
    customers, 
    devices, 
    deviceTypes, 
    selectedDeviceTypes,  
    selectedCustomers,
    selectedAlarmFilters,
    selectedOrderFilter,
    selectedUserTags,
    isPrivate
  }: HeaderProps) => {

  const { formatMessage } = useIntl();

  const mappedDeviceTypes = React.useMemo(() => {
    return translateDeviceTypes(deviceTypes, formatMessage);
  }, [deviceTypes])

  const noEmptyMappedCustomers: ICustomerWithPolicy[] = [];

  const [mappedCustomers, mappedTags] = React.useMemo(() => {

    const deviCountCustomer: {[index: number]: number} = {};
    const deviCountUserTag: {[index: string]: number} = {};
    let hasAnyTag = false;

    devices.forEach(device => {

      deviCountCustomer[device.ownedByCustId] ? (deviCountCustomer[device.ownedByCustId]++) : (deviCountCustomer[device.ownedByCustId] = 1)
      if(device.userTag && device.userTag !== UserTags.NONE){
        hasAnyTag = true;
        deviCountUserTag[device.userTag] ? (deviCountUserTag[device.userTag]++) : (deviCountUserTag[device.userTag] = 1)
      }
      else if(!device.userTag || device.userTag === UserTags.NONE){
        deviCountUserTag[UserTags.NONE] ? (deviCountUserTag[UserTags.NONE]++) : (deviCountUserTag[UserTags.NONE] = 1)
      }
    })
    customers.forEach(customer =>{
      if(deviCountCustomer[customer.customerId] > 0){
        noEmptyMappedCustomers.push(customer);
      }
    })

    noEmptyMappedCustomers.sort(function(a,b){
      if(a.customerName < b.customerName) {return -1; }
      if(a.customerName > b.customerName) { return 1; }
      return 0;
    })

    const mappedTags = [];

    if(hasAnyTag){
      for (const key in UserTags) {
        if(deviCountUserTag[key]){
          mappedTags.push(
            {text: `${formatMessage({id: key, defaultMessage: key})} (${deviCountUserTag[key]})`, value: key}
          )
        }
      }
    }

    if(mappedTags.length === 0 && devices.length > 0){
      filterUserTags([]);
    }

    const mappedCustomers = noEmptyMappedCustomers.map((customer: ICustomerWithPolicy) => {
        return{
          text: `${customer.customerName} (${deviCountCustomer[customer.customerId] || 0})`,
          value: customer.customerId
        }
    })
    
    return [mappedCustomers, mappedTags];
  }, [customers, devices])

  const twinCount = React.useMemo(() => {
    return filteredDevices.filter(d => d.deviceTwin).length;
  }, [filteredDevices])

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const addDeviceAccess = permissions.get(PolicyNameEnum.ADD_DEVICE_MENU).generalPermissions.all;

  return(
    <React.Fragment>
      <AddRow private={isPrivate}>
        {!isPrivate &&
        <h1>
          {`${formatMessage({id: LabelEnum.SAFEMATES, defaultMessage: "Antall Safemate"})}: ${filteredDevices.length + twinCount}`}
        </h1>
        }
        {addDeviceAccess &&
        <AddButton
          private={isPrivate}
          onClick={() => {
            location.hash = "add_safemate"
          }}
        >
          {formatMessage({id: "addSafemate", defaultMessage: "Legg til safemate"})}
        </AddButton>}
      </AddRow>
      {!isPrivate && devices.length > 1 && <HeaderRow>
        <StyledCol>
            <Dropdown
              multi
              enableReset
              initial={selectedCustomers}
              options={mappedCustomers}
              title={formatMessage({id: LabelEnum.SELECT_CUSTOMER, defaultMessage: "Alle underavdelinger"})}
              onChange={option => {
                const selected = (option as Option[]).map(item => item.value);
                filterCustomer(selected);
              }}
            />
        </StyledCol>
        <StyledCol>
            <Dropdown
              multi
              enableReset
              initial={selectedDeviceTypes}
              options={mappedDeviceTypes}
              title={formatMessage({id: LabelEnum.SELECT_DEVICE_TYPE, defaultMessage: "Alle enhetstyper"})}
              onChange={(option) => {
                const selected = (option as Option[]).map(item => item.value);
                filterDeviceType(selected);
              }}
            />
        </StyledCol>
        <StyledCol>
            <Dropdown
              multi
              enableReset
              initial={selectedAlarmFilters}
              options={getAlarmFilter(selectedDeviceTypes, formatMessage)}
              title={formatMessage({id: LabelEnum.SELECT_ALARM_FILTER, defaultMessage: "Alle tilstander"})}
              onChange={option => {
                const selected = (option as Option[]).map(item => item.value);
                filterAlarm(selected);
              }}
            />
        </StyledCol>
        {mappedTags.length > 0 && <StyledCol>
            <Dropdown
              multi
              enableReset
              initial={selectedUserTags}
              options={mappedTags}
              title={formatMessage({id: LabelEnum.SELECT_USER_TAG, defaultMessage: "Alle brukerkategorier"})}
              onChange={option => {
                const selected = (option as Option[]).map(item => item.value);
                filterUserTags(selected);
              }}
            />
        </StyledCol>}
        <StyledCol>
            <Dropdown
              enableReset
              initial={selectedOrderFilter}
              options={getOrderFilter(formatMessage)}
              title={formatMessage({id: LabelEnum.DEVICE_LIST_SORT_PRO, defaultMessage: "Standardsortering"})}
              onChange={option => {
                const selected = (option as Option).value;
                filterOrder(selected);
              }}
            />
        </StyledCol>
      </HeaderRow>}
    </React.Fragment>
  )
});
