import { MessageDescriptor } from "react-intl";
import { Option } from "@Elements/Dropdown/types";

export enum SensorTypeEnum{
    BASE_STATION = "BaseStation",
    WRIST_BAND = "Wristband",
    SAFEMATE_HOME = "SafemateHome"
}

export enum BaseStationConnectionStatus{
    CONNECT = "CONNECT",
    DISCONNECT = "DISCONNECT"
};

export enum DeviceType{
    DOOR_CONTACT = "4",
    UNIVERSAL_SENSOR = "33",
    UNIVERSAL_SENSOR_SPT_1 = "SPT-1",
    WATER_DEVICE = '5',
    PANIC_BUTTON = '7',
    PIR_SENSOR = '9',
    PIR_SENSOR_CAMERA = '27',
    SMOKE_DETECTOR = '67',
    THERMOSTAT = '20',
    PENDANT = '21',
    PULL_CORD = '32',
    DECT_MOBILE = '49',
    FALL_ALARM = '60',
    DECT_MOUNTED = '65',
    LIGHT_BULB = '78',
    WADC_1 = '109',
    WRIST_BAND = "Wristband",
    BASE_STATION = "BaseStation",
    SAFEMATE_HOME = "SafemateHome"
}

export const specialTypeForFunction = [
  DeviceType.PIR_SENSOR
]

export enum DeviceTypeLabel{
    DOOR_CONTACT_LABEL = 'sensorWanderer',
    UNIVERSAL_SENSOR_LABEL = 'universalSensor',
    WATER_DEVICE_LABEL = 'waterDevice',
    PANIC_BUTTON_LABEL = 'panicButton',
    PIR_SENSOR_LABEL = 'pirLabel',
    PIR_SENSOR_CAMERA_LABEL = "pirCameraLabel",
    SMOKE_DETECTOR_LABEL = 'smokeDetector',
    THERMOSTAT_LABEL = 'thermostatLabel',
    PENDANT_LABEL = 'pendantLabel',
    PULL_CORD_LABEL = 'pullCordDevice',
    DECT_MOBILE_LABEL = 'dectMobileLabel',
    FALL_ALARM_LABEL = 'fallAlarm',
    DECT_MOUNTED_LABEL = 'dectMountedLabel',
    LIGHT_BULB_LABEL = 'lightBulbLabel',
    WRIST_BAND_LABEL = "triggerFourWristband",
    BASE_STATION_LABEL = "triggerFourBaseStation",
    UNKNOWN_LABEL = 'unknownLabel',
    UNSUPPORTED_LABEL = 'unsupportedLabel'
}

export const DOOR_CONTACT = '4';
export const DOOR_CONTACT_LABEL = 'sensorWanderer';

export const UNIVERSAL_SENSOR = '33';
export const UNIVERSAL_SENSOR_LABEL = 'universalSensor';
export const UNIVERSAL_SENSOR_SPT_1 = 'SPT-1';

export const WATER_DEVICE = '5';
export const WATER_DEVICE_LABEL = 'waterDevice';

export const PANIC_BUTTON = '7';
export const PANIC_BUTTON_LABEL = 'panicButton';

export const PIR_SENSOR = '9';
export const PIR_SENSOR_LABEL = 'pirLabel';

export const SMOKE_DETECTOR = '67';
export const SMOKE_DETECTOR_LABEL = 'smokeDetector';

export const THERMOSTAT = '20';
export const THERMOSTAT_LABEL = 'thermostatLabel';

export const PENDANT = '21';
export const PENDANT_LABEL = 'pendantLabel';

export const PULL_CORD = '32';
export const PULL_CORD_LABEL = 'pullCordDevice';

export const DECT_MOBILE = '49';
export const DECT_MOBILE_LABEL = 'dectMobileLabel';

export const FALL_ALARM = '60';
export const FALL_ALARM_LABEL = 'fallAlarm';

export const DECT_MOUNTED = '65';
export const DECT_MOUNTED_LABEL = 'dectMountedLabel';

export const LIGHT_BULB = '78';
export const LIGHT_BULB_LABEL = 'lightBulbLabel';

export const UNKNOWN_LABEL = 'unknownLabel';

type IsSetTypeNeeded = (deviceType: string) => boolean;

export const isSetTypeNeeded: IsSetTypeNeeded = (deviceType) => {
  if (!deviceType) return false;

  switch (deviceType) {
    case DeviceType.DOOR_CONTACT:
    case DeviceType.UNIVERSAL_SENSOR_SPT_1:
      return true;

    default:
      return false;
  }
};

type GetAvailableTypesForType = (deviceType: string, intl: (msg: MessageDescriptor) => string) => Option[];

export const getAvailableTypesForType: GetAvailableTypesForType = (deviceType, intl) => {
  if (!deviceType) return [];

  switch (deviceType) {
    case DeviceType.DOOR_CONTACT:
    case DeviceType.UNIVERSAL_SENSOR_SPT_1:
      return [
        {
          text: intl
            ? intl({
                id: DeviceTypeLabel.DOOR_CONTACT_LABEL,
                defaultMessage: DeviceTypeLabel.DOOR_CONTACT_LABEL
              })
            : DeviceTypeLabel.DOOR_CONTACT_LABEL,
          value: DeviceType.DOOR_CONTACT
        },
        {
          text: intl
            ? intl({
                id: DeviceTypeLabel.UNIVERSAL_SENSOR_LABEL,
                defaultMessage: DeviceTypeLabel.UNIVERSAL_SENSOR_LABEL
              })
            : DeviceTypeLabel.UNIVERSAL_SENSOR_LABEL,
          value: DeviceType.UNIVERSAL_SENSOR_SPT_1
        }
      ];

    default:
      return [];
  }
};
