import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CancelButton } from "./styled";
import { cancelSettings} from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { LoaderWrap } from "./styled";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";
import { CustomerForm } from "./types";

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledRow = styled(Row)`
  display: flex;
  margin: 0px;
`;



interface FooterProps{
  processingSave: boolean;
}
const mapStateToProps = ( { subUnits: {initialization: { processingSave }}} : AppState) => {
  return{
    processingSave
  }
};
const mapDispatchFromState = {
  cancelSettings
}

export const Footer = connect(mapStateToProps, mapDispatchFromState)(
  withFormWrapper<CustomerForm, FooterProps>(
    ({formik: {dirty, initialValues, resetForm}, processingSave}) => {

    const { formatMessage } = useIntl();

    const handleCancel = () => {
      resetForm({
        values: initialValues
      });
    }

    return(
      <StyledRow>
        <ButtonContainer>
          <CancelButton disabled={!dirty } onClick={handleCancel}>
            {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
          </CancelButton>
          {processingSave 
            ? <LoaderWrap><Loader/></LoaderWrap>
            : <Button type="submit" disabled={!dirty}>
                {formatMessage({id: "save", defaultMessage: "Lagre"})}
              </Button>}
        </ButtonContainer>
      </StyledRow>
    )
  })
)

export default Footer;