import { DeviceFunctionEnum } from "../SensorFunctionEnum";
import { TypesForCustom } from "../SensorFunctionTypeEnum";
import { Option } from "@Elements/Dropdown/types";

export const mapFunction = (func: string, funcType: string) => {
  if(func === DeviceFunctionEnum.CUSTOM_SENSOR_FUNCTION){
    return funcType;
  }
  return func;
}

export const deconstructFunction = (option: Option, formatMessage: Function) => {
  let isCustom = false;

  TypesForCustom.forEach(type => {
    if(type.code === option.value){
      isCustom = true;
    }
  })

  if(isCustom){
    return {
      deviceFunction: DeviceFunctionEnum.CUSTOM_SENSOR_FUNCTION,
      funcType: option.value
    }
  }
  return{
    deviceFunction: option.value,
    funcType: ""
  }
}