import React, { Fragment } from 'react';
import Day from './day';
import {  useIntl } from 'react-intl';
import withFormWrapper from '@Elements/Form/formWrapper';
import { ScheduleWrap } from './styles';
import { EventConfigForm } from '../types';
import { FullWidthStyledInput } from '@Safemate/RoleEditor/FormStyles';

const DAYS = ["MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
]

interface ScheduleProps{
  from: boolean;
  to: boolean;
}

const Schedule = withFormWrapper<EventConfigForm, ScheduleProps>(({ from, to, formik: { values: { sensorId, eventConfig: { schedule } }, values, setFieldValue} }) => {

  console.log(values)

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (schedule.length === 0) {

      const newSchedule = [];
  
      for(const day of DAYS){
        newSchedule.push({
          sensorId,
          active: true,
          fromTime: "00:00",
          toTime: "23:59",
          day
        })
      }
  
      setFieldValue("eventConfig.schedule", newSchedule);
    }
  }, [])

  return (
    <ScheduleWrap>
      {schedule.map((day, index) => {
        return (
          <div style={{padding: "3px"}}>
            <Day
              field={`eventConfig.schedule.${index}.active`}
              day={day}
            />
            {from && (
              <Fragment>
                <p>{formatMessage({id: "universalFrom", defaultMessage: "Fra:"})}</p>
                <FullWidthStyledInput
                  small
                  type="time"
                  name={`eventConfig.schedule.${index}.fromTime`}
                  fieldValue={setFieldValue}
                  value={day.fromTime}
                />
              </Fragment>
            )}
            {to && (
              <Fragment>
                <p>{formatMessage({id: "universalTo", defaultMessage: "Til:"})}</p>
                <FullWidthStyledInput
                  small
                  type="time"
                  name={`eventConfig.schedule.${index}.toTime`}
                  fieldValue={setFieldValue}
                  value={day.toTime}
                />
              </Fragment>
            )}
          </div>
        );
      })}
    </ScheduleWrap>
  );
})

export default Schedule;
